export default {
	text: "你好",
	langs: {
		"zh-CN": "中文",
		en: "English",
	},
	footer: {
		a: '第二届世界竹藤大会秘书处',
		b: '国际竹藤组织',
		c: '北京市朝阳区望京阜通东大街8号',
		d: '网址：<span class="calibri">www.barc2022.inbar.int</span>',
		e: '联系方式：<span class="calibri">barc2022_info@inbar.int</span>'
	},
	navigation: [{
			title: '首页',
			value: 'home',
			child: []
		},
		{
			title: '关于大会',
			value: 'About',
			child: [{
					title: '大会简介',
					value: 'account',
				},
				{
					title: '嘉宾介绍',
					value: 'introduce',
				},
				{
					title: '合作伙伴',
					value: 'conference',
				},
			]
		},
		{
			title: '大会议程',
			value: 'conferenceAgenda',
			child: []
		},
		{
			title: '平行会议',
			value: 'parallelMeeting',
			child: [{
					title: '平行会议',
					value: 'parallelMeeting',
				},
				{
					title: '电子壁报',
					value: 'poster',
				},
			]
		},
		{
			title: '注册参会',
			value: 'regist',
			child: [{
					title: '注册',
					value: 'registerMeeting',
				},
				{
					title: '交通住宿',
					value: 'transportationAndAccommodation',
				},
			]
		},
		{
			title: '论文摘要',
			value: 'abstract',
			child: []
		},
		{
			title: '竹藤展览',
			value: 'exhibition',
			child: [{
					title: '竹藤展览',
					value: 'exhibition',
				},
				{
					title: '国际竹藤组织图片摄影大赛',
					value: 'imgPages1',
				},
				{
					title: '竹藤与可持续发展目标',
					value: 'imgPages2',
				},
				{
					title: '澜湄合作国际设计大赛',
					value: 'imgPages3',
				},
				{
					title: '国际竹藤组织成员国',
					value: 'imgPages4',
				},
			]
		},
		{
			title: '竹藤新闻',
			value: 'News',
			child: [{
					title: 'INBAR新闻',
					value: 'INBAR',
				},
				{
					title: '大会动态',
					value: 'News',
				},
			]
		}, {
			title: '联系我们',
			value: 'contactUs',
			child: []
		}
	],
	home_introduction: '大会简介',
	home_introduction_1: '为促进竹藤绿色经济发展、助力实现联合国可持续发展目标，由国家林业和草原局与国际竹藤组织联合主办的国际竹藤组织成立二十五周年志庆暨第二届世界竹藤大会（The Second Global Bamboo and Rattan Congress, BARC 2022, 以下简称“大会”）将于2022年11月7—8日在北京召开。大会将汇聚包括各国政府部门、科研院所、国际组织和非政府组织、私营部门、媒体等在内的各界代表，共商新形势下的竹藤发展与合作。鉴于新冠疫情防控需要，本届大会将采取线上线下相结合的形式举行，现场参会仅限目前居住在中国大陆的嘉宾。 第二届世界竹藤大会将延续首届世界竹藤大会（BARC 2018）的精彩与魅力，敬请期待！',
	newTitle: '竹藤新闻',
	more: '查看更多',
	personal_center: '个人中心',
	home_table: require(`@/assets/images/home/table.png`),
	/** 大会专题*/
	topics_title: '大会专题',
	topics_title_1: '专题一、迈向碳中和之路',
	topics_content_1: '专题一将在推进碳中和目标的大背景下，重点探讨竹子在助力全球减缓和适应气候变化、保护森林和恢复景观、转变低碳生活方式、实现碳达峰碳中和目标，以及保护生物多样性和提升生态系统服务等领域的作用与贡献。',
	topics_title_2: '专题二、助推绿色经济发展',
	topics_content_2: '专题二着眼于如何利用竹藤资源减少贫困、振兴乡村、促进可持续生活并推动新型绿色经济。专题内容包括竹藤资源可持续经营、基于竹藤的农林复合系统、小农户生计发展、竹藤中小型企业发展、竹藤推进可持续就业和促进绿色价值链的案例研究、生态旅游与竹藤文化产业等。',
	topics_title_3: '专题三、创新材料与市场开发',
	topics_content_3: '专题三重点探讨竹藤在不同领域的新突破、新发展，包括新型应用、创新产品、制造前沿、竹藤产业规模化、集群化及循环经济发展等，以满足全球日益增长的对生物基低碳产品的需求。专题内容包括以竹代塑、以竹代木、以竹代棉、以竹代钢材和混凝土建材等创新应用，竹藤产品相对于传统材料和能源的比较优势，竹藤食品研究前沿，竹藤生物物理化学利用进展，竹藤产品标准、认证及市场准入，竹藤产业发展未来趋势等。',
	topics_title_4: '专题四、共促产业和谐包容发展',
	topics_content_4: '专题四旨在展示并分享全球竹藤可持续发展的国际合作与交流经验。专题内容包括竹藤知识和最佳实践分享，能力建设与技术转让，妇女和社区在竹藤产业发展中的作用，“一带一路”、南南合作与多边合作案例研究，公私伙伴关系在竹藤产业发展中的作用，投资促进和伙伴关系发展，国际竹藤产业价值链和国际贸易便利化等。',
	participants_registration: '观众注册',
	abstract_submission: '论文摘要提交',
	/** 合作伙伴 */
	hosts: '主办方',
	organisers: '承办方',
	partner_1: '国际竹藤组织',
	partner_2: '国家林业和草原局',
	partner_3: '国家林业和草原局<br/>国际竹藤中心',
	partner_4: '国家林业和草原局国际竹藤中心',
	/** INBAR新闻 */
	INBAR_new_url: 'https://www.inbar.int/cn/zhufeng-tengyun-copy/zhufeng-tengyun/',
	INBAR_news: 'INBAR新闻',
	BARC_News: '大会动态',
	/** 注册参会 */
	registration: '注册指南',
	registrationOverview: '注册概述',
	Inpersonparticipation: '现场参会',
	Inp1: '由于受到新冠肺炎疫情影响，第二届世界竹藤大会（以下简称“大会”）现场参会仅对目前居住在中国大陆的人员开放，组委会将采取定向邀请的方式，有意参会者请联系<span class="g-color">barc2022_info@inbar.int</span>。现场参会注册截止时间为 <span class="g-color">2022年10月15日17:00</span>。',
	Inp2: '由于现场参会人数受限，将采取先到先得政策。一旦达到人数上限，现场参会注册即关闭。',
	virtualAttendance: '线上参会注册',
	vip1: '参会人员可免费注册线上参会。线上参会注册截止时间为',
	viptime: '2022年10月20日17:00',
	vip11: '有关线上参会的常见问题，请参阅',
	here: '此处',
	vip2: '所有报名截止时间均为北京时间（GMT+8）。',
	registrationFee: '注册费',
	rep1: '仅现场参会人员需缴纳注册费；',
	rep2: '注册费为统一标准，人民币1500元/人；',
	rep3: '网上注册仅支持微信支付，如您无法通过微信支付，请发送电子邮件至',
	rep4: '所有付款均应以人民币 (RMB) 支付',
	registrationBenefits: '参会权益',
	regp1: '注册参会，即表示您同意所有注册条款和条件。完成支付并收到确认邮件后，即代表您的注册已完成。',
	regp2: '※网上报名只开具电子增值税发票，并在大会结束后10个工作日内提供。',
	registrationCancellationPolicy: '注册取消政策',
	registp1: '如取消注册，将根据以下条款退还注册费。退款手续将于注册取消日期后30天内办理。您需承担由此可能产生的银行手续费。',
	registp2: '因个人原因取消',
	registp3: '如果您因个人原因想取消注册，请在<a class="a-color" href="https://barc2022.chinafuturelink.com/barc" target="_blank" >个人中心</a>处申请取消注册和退款。',

	registp4: '退费标准：',
	registp5: '因新冠肺炎疫情原因取消',
	registp6: '如您因防疫政策无法成行、被隔离或核酸检测结果呈阳性，或其他新冠疫情相关原因，而导致无法参加大会， 请在<a class="a-color" target="_blank" href="https://barc2022.chinafuturelink.com/barc">个人中心</a>处申请取消注册，并上传相关证明材料。工作人员审核无误后，将办理<span class="g-color">全额退款</span>（<span class="f-b">可能会扣除银行的手续费</span>）。',
	privacyPolicy: '关于隐私',
	prip1: '主办方非常重视并将竭力保护参会人员隐私。通过在线注册收集的个人信息将仅用于与注册人参会相关的身份识别、身份验证、确认和防止欺诈等目的。注册参会将视作注册人同意主办方出于上述目的收集、使用和处理其个人信息。会场将拍摄照片和视频，用于大会存档和新闻宣传。请参阅注册流程中的隐私政策并完成知情同意流程。',
	dataProtection: '数据保护',
	datap1: '参会人员完成注册流程，即被视为同意主办方出于大会需要处理其个人信息，并向其发送大会和INBAR相关信息。必填项不能为空，否则将无法完成注册。注册人可以访问和修改其个人数据，相关事宜请发送电子邮件至大会注册组（<span class="g-color">barc2022_info@inbar.int</span>）获取支持。',
	insurance: '保险',
	insp1: '建议参会人员自行购买旅行意外商业保险。',
	limitationofLiability: '责任范围',
	limip1: '1. 如发生自然灾害、战争、恐怖活动或不作为等主办方无法控制的不可抗力事件，主办方有权取消或推迟大会，或更改大会时间、地点或议程。如大会取消或推迟，对不可抗力事件造成的任何损害、费用或损失不承担任何责任。主办方将通过注册表上提供的电子邮件地址向参会人员发送大会取消或推迟的通知。',
	limip2: '2. 对参会人员或其同伴、看护人等发生的人身伤亡、损失以及支出的成本、费用等，特别是因酒店或交通服务而造成的损失，主办方不承担任何责任。',
	limip3: '3. 对参会人员或其同伴、看护人等发生的人身伤亡、损失以及支出的成本、费用等，特别是因酒店或交通服务而造成的损失，主办方不承担任何责任。',
	mediaRegistration: '媒体注册',
	mediap1: '大会诚挚欢迎相关领域的媒体机构参与报道，并诚邀在中国大陆居住的媒体朋友注册参会。 ',
	mediap2: '媒体人员参会不收取注册费。',
	mediap3: '有意注册参会的媒体朋友请于<span class="g-color">2022年10月20日</span>前，将',
	mediap33: '<span style="font-weight:700" class="g-color">在有效期内的记者证复印件或记者所在媒体的单位书面证明</span>',
	mediap333: '发送至<span class="g-color">media@inbar.int</span>进行审核批准，邮件主题请标明“BARC2022媒体注册”。大会组委会将根据需要，请注册参会的媒体朋友提交有关资质材料，并邀请通过审核的媒体朋友正式注册。',
	mediap4: '媒体注册需提交以下材料：',
	mediap5: '在有效期内的记者证或记者所在媒体的书面证明',
	mediap6: '身份证或护照复印件',
	mediap7: '一寸证件照一张',
	inquiries: '其他问题',
	inqup1: '如有其他注册相关问题，请咨询大会注册组：<span class="g-color">barc2022_info@inbar.int</span>',
	/** 注册参会  end*/
	/** 交通住宿 start */
	beijingCenterHotels: '北京国际会议中心周边酒店',
	address: '地址',
	tel: '饭店电话',
	website: '订房连接',
	distancetoBICC: '距离BICC',
	distancetoCNCC: '距离CNCC',
	pr: '邮编',
	fj: "订房表格见附件",
	lxr: '联系人',
	dh: ' 电话',
	mainAccommodation: '北京北辰五洲大酒店 4*',
	mainAccommodationAddress: '北京市朝阳区北辰东路8号',
	mainAccommodationpr: '100101',
	mainAccommodationTel: '+86 10 8498 0105',
	mainAccommodationWebsite: 'https://www.bcghotel.com.cn/order.asp?id=2531',
	mainAccommodationDistancetoBICC: '有内部连廊，步行5分钟',
	mainAccommodationInfos: '北京北辰五洲大酒店，即北京北辰实业股份公司北京北辰五洲大酒店，是一家四星级酒店，是2008年第29届奥运会和北京残奥会的签约酒店之一。酒店坐拥亚奥商圈，北眺奥运场馆，南临四环主路，环境优美。 距首都国际机场26公里，距大兴国际机场68公里，交通便利，与BICC有内部连廊，步行5分钟。',
	wuzhouHotel: '北京北辰五洲皇冠国际酒店5*',
	wuzhouHotelAddress: '中国北京市朝阳区北四环中路8号 ',
	wuzhouHotelPr: '100010',
	wuzhouHotelTel: '+86 10 8498 2288',
	wuzhouHotelToCNCC: '步行35分钟或开车8分钟',
	wuzhouHotelToBICC: '步行5分钟',
	wuzhouHotelInfos: '北京北辰实业股份有限公司五洲皇冠国际酒店为北京北辰酒店管理有限公司北京旗舰店，位于北四环亚奥商圈核心区，毗邻北京国际会议中心、国家会议中心, 与奥林匹克公园、鸟巢咫尺之遥。酒店位置优越，距首都国际机场仅25分钟车程，3个地铁站举步之遥，前往天安门广场、紫禁城、颐和园、秀水街等繁华商区和名胜景点十分便捷，是商务人士和休闲人士的理想居庭。',
	dinganglianjie: '预订房间请点击<a class="a-color" target="_blank" href="https://www.barc2022.inbar.int/barcfile/北京北辰五洲皇冠国际酒店预订单 中英文(2)(1)(1)(1).xlsx">【此处】</a>下载预订单，并发送邮件至 phoebe.xu@v-continent.com',
	xufang: '徐芳',
	xufd: '13811450011',
	huiyuanHotel: '汇园酒店公寓贵宾楼 5*',
	huiyuanHotelAddress: ' 北京市朝阳区安定门外北辰东路8号',
	huiyuanHotelPr: '100101',
	huiyuanHotelTel: '+86-10 6499 1818',
	huiyuanHotelWebsite: 'https://www.huiyuangongyu.com.cn/',
	huiyuanHotelToBICC: '步行15分钟（720m）',
	huiyuanHotelInfos: '汇园酒店公寓贵宾楼位于于亚运村地区，毗邻奥林匹克公园和北京国际会议中心，按照五星级酒店的设施水平和服务标准精心打造的高档酒店。距离BICC步行15分钟。',
	yayuncunHotel: '亚运村宾馆 3*',
	yayuncunHotelAddress: '北京市朝阳区北辰东路8号',
	yayuncunHotelTel: '+86-10 6499 1199',
	yayuncunHotelWebsite: 'https://www.huiyuangongyu.com.cn/e/order/morder.php?id=241',
	yayuncunHotelToBICC: '步行15分钟或开车5分钟（850 m）',
	yayuncunHotelInfos: '亚运村宾馆隶属于北京北辰实业股份有限公司（香港上市公司）。酒店位于著名的亚运村内，与奥运村一路之隔。亚运村宾馆拥有3栋公寓楼，拥有近特色各异的客房，10余种户型（面积30至150平方米），从一室到四室、从平层到复式、从普通到豪华种类齐全，式样各异。距离BICC步行15分钟。',
	provideTimely: '适时提供',


	/** 交通住宿  end */
	/** 论文摘要  start*/
	abstracts: '论文摘要',
	secondGlobalBamboo: '第二届世界竹藤大会',
	callForAbstracts: '论文摘要集',
	abstractsTime: '2022年11月7日-8日',
	absp1: '第二届世界竹藤大会主题为“竹藤——基于自然的可持续发展解决方案”，为了展现竹藤领域最新科研成果、科技进步和行业前沿，大会组委会公开邀请与征集论文摘要。论文摘要应紧扣大会主题，至少与以下其中一个大会专题领域相关。',
	absp2: '专题一、迈向碳中和之路',
	absp3: '专题一将在推进碳中和目标的大背景下，重点探讨竹子在助力全球减缓和适应气候变化、保护森林和恢复景观、转变低碳生活方式、实现碳达峰碳中和目标，以及保护生物多样性和提升生态系统服务等领域的作用与贡献。',
	absp4: '专题二、助推绿色经济发展',
	absp5: '专题二着眼于如何利用竹藤资源减少贫困、振兴乡村、促进可持续生活并推动新型绿色经济。专题内容包括竹藤资源可持续经营、基于竹藤的农林复合系统、小农户生计发展、竹藤中小型企业发展、竹藤推进可持续就业和促进绿色价值链的案例研究、生态旅游与竹藤文化产业等。',
	absp6: '专题三、创新材料与市场开发',
	absp7: '专题三重点探讨竹藤在不同领域的新突破、新发展，包括新型应用、创新产品、制造前沿、竹藤产业规模化、集群化及循环经济发展等，以满足全球日益增长的对生物基低碳产品的需求。专题内容包括以竹代塑、以竹代木、以竹代棉、以竹代钢材和混凝土建材等创新应用，竹藤产品相对于传统材料和能源的比较优势，竹藤食品研究前沿，竹藤生物物理化学利用进展，竹藤产品标准、认证及市场准入，竹藤产业发展未来趋势等。',
	absp8: '专题四、共促产业和谐包容发展',
	absp9: '专题四旨在展示并分享全球竹藤可持续发展的国际合作与交流经验。专题内容包括竹藤知识和最佳实践分享，能力建设与技术转让，妇女和社区在竹藤产业发展中的作用，“一带一路”、南南合作与多边合作案例研究，公私伙伴关系在竹藤产业发展中的作用，投资促进和伙伴关系发展，国际竹藤产业价值链和国际贸易便利化等。',
	absp10: '摘要作者将有机会获得以下权益',
	absp11: '1. 录取的论文摘要将收录在大会摘要集中。摘要集将在第二届世界竹藤大会公开发表，并在大会期间发放纸质版。',
	absp12: '2. 根据评审结果，大会组委会将择优提供其在大会期间做口头报告的机会。',
	absp13: '3. 根据评审结果，大会组委会将择优提供其在大会期间做墙报交流的机会。',
	absp14: '论文摘要提交时间表',
	absp15: '重要提示',
	absp16: '1. 大会组委会将对提交的论文摘要组织专家评审，并通知摘要第一提交作者相关评审结果。',
	absp17: '2. 提交论文摘要视为论文作者同意大会出版其摘要。',
	absp188: '如何提交',
	absp18: '请点击在<a class="a-color" target="_blank" href="https://barc2022.chinafuturelink.com/barc/link.html">此处</a>提交您的论文摘要。',
	absp199: `论文摘要已于2022年9月20日截止，若您已提交论文请点击<a class="a-color" target="_blank" href="https://barc2022.chinafuturelink.com/barc">个人中心</a>进行查看`,
	absp19: '所有论文摘要需严格按照<a class="a-color" href="https://www.barc2022.inbar.int/barcfile/BACR 2022 Abstract Template.docx">大会摘要模板</a>所示格式，并在<span class="g-color">2022年9月20日</span>之前在大会网站上在线提交。',
	absp20: '<span class="g-color">大会评审只接受英文摘要</span>（摘要的中文翻译件可自愿上传提交）。',
	adsp21: '有关第二届世界竹藤大会摘要提交说明、格式等请到大会官网<a class="a-color" target="_blank" href="http://www.barc2022.inbar.int">www.barc2022.inbar.int</a>浏览下载。',
	adsp22: '有关摘要提交问询，请联系：<span class="g-color">barc2022_abstracts@inbar.int</span>。',
	adsp23: '第二届世界竹藤大会组委会热诚欢迎您提交摘要和参会！',
	/** 论文摘要  end*/
	/** 常见问题  start*/
	faq: '常见问题',
	faq1: '1. 我如何在线参与大会？',
	faw1: '第二届世界竹藤大会将在官网开放大会直播功能，观众可以直接在官网观看直播，会议直播适配电脑与手机设备播放。',
	faq2: '2. 参加线上大会需要多少网速？',
	faw2: '如果您参与线上竹藤大会，例如观看会议直播等。我们建议网络至少达到2MB/s。您可以通过运行一个网速测试来检查你自己的连接的状态，您可以通过运行网速测试软件来检查你自己的连接状态，查看下载和上传速度的测量数据。如网络环境不佳，建议及时切换，以便您可以顺利参与线上竹藤大会。',
	faq3: '3. 如果平台不能加载怎么办？',
	faw3: '为了实现最佳的浏览效果，建议使用兼容性较强的浏览器，包括谷歌Chrome、火狐、Safari，Edge。如果您仍然不能打开该平台，请尝试刷新页面并清除缓存。	',
	faq4: '4. 大会有手机应用程序吗？',
	faw4: '没有。本次竹藤大会设置线上数字平台，无需安装任何软件，通过链接打开网页即可进入，适用于手机网页端和电脑端使用。',
	/** 常见问题  end*/
	/** 联系我们 start */
	contactUs: '联系我们',
	contactUsp1: '更多问题，敬请垂询：',
	contactUsp2: '大会注册及会议信息： <span class="g-color">barc2022_info@inbar.int</span>',
	contactUsp3: '平行会议征集：<span class="g-color">barc2022_sessions@inbar.int</span>',
	contactUsp4: '论文摘要提交：<span class="g-color">barc2022_abstracts@inbar.int</span>',
	contactUsp5: '大会注册信息：<span class="g-color">barc2022_info@inbar.int</span>',
	contactUsp6: '大会展览信息：<span class="g-color">tli@inbar.int</span>',
	contactUsp7: '媒体宣传：<span class="g-color">media@inbar.int</span>',
	contactUsp8: '交通、住宿信息：<span class="g-color">barc2022_services@inbar.int</span>',
	/** 联系我们 end */
	/** 竹藤展览 start*/
	bambooExhibition: '竹藤展览',
	introduction: '一、简介',
	bambooExhibitionP1: '第二届世界竹藤大会（以下简称“大会”）将于2022年11月7日至8日在北京召开。大会同期将举办竹藤展览，汇集竹藤领域的优质产品、先进技术和最新科研成果，包括竹藤家具、建材、工艺品、日用品、竹纤维制品、竹炭制品等相关产品和技术。展区还将现场展示竹藤工匠的精湛技艺，供广大竹藤爱好者观摩、体验。',
	bambooExhibitionP2: '二、展览时间与场地',
	bambooExhibitionP3: '三、展位安排',
	bambooExhibitionP4: '第二届世界竹藤大会竹藤展览设于北京国际会议中心一层第四会议厅。展览总面积为1500平方米，可容纳55个标准展位，展位平面图如下：',
	bambooExhibitionP5: '四、展位费用与配置',
	bambooExhibitionP6: '1. 国际竹藤组织为参展机构提供免费的标准展位。如需特装展位或大面积展位，请与大会展览组联系。',
	bambooExhibitionP7: '2. 参展机构需自付展位搭建费，每个标展搭建费（3*3m）为950元。如有其它特殊搭建需求，请与大会展览组联系。',
	bambooExhibitionP8: '标展配置如下：',
	bambooExhibitionP9: '外框搭建（背墙和侧墙均为3*2.5m）',
	bambooExhibitionP10: '楣板字（楣板字背景为纯色，一般为蓝色）',
	bambooExhibitionP11: '1个展台（长100cm*宽50cm*高75cm）',
	bambooExhibitionP12: '2把展椅',
	bambooExhibitionP13: '2个电源插口（5A 220V）',
	bambooExhibitionP14: '1个垃圾桶',
	bambooExhibitionP15: '3. 标展图示',
	bambooExhibitionP16: '4. 标展各部分画面需要参展机构自备，大会展览搭建商可代为制作，费用自理。如需制作，请于2022年9月30日前，将需要印刷的文件（AI格式、转曲）提交大会展览组。',
	bambooExhibitionP17: '5. 大会展览未租赁仓储，如有大型展品需要展示，请参展机构自行安排仓储和物流。',
	bambooExhibitionP18: '6. 请于2022年9月30日前，将展览相关款项一次性付清，具体请与大会展览组联系。',
	bambooExhibitionP19: '五、参展回执',
	bambooExhibitionP20: '请于2022年8月30日前，<a class="a-color" href="./receipt/2022竹藤展览参展回执_20220524_中.docx" download>发送参展回执</a>至大会展览组李婷女士（<span class="g-color">tli@inbar.int</span>）。',
	bambooExhibitionP21: '四、大会展览组联系方式',
	bambooExhibitionP22: '<span class="dot"></span>国际竹藤组织东道国部主任：傅金和 博士',
	bambooExhibitionP23: '电话：<span class="g-color">136 4123 4213</span>；',
	bambooExhibitionP24: '电子邮箱：<span class="g-color">jfu@inbar.int</span>',
	bambooExhibitionP25: '国际竹藤组织东道国合作发展经理：李婷 女士',
	bambooExhibitionP26: '电话：<span class="g-color">139 1002 3820</span>；',
	bambooExhibitionP27: '电子邮箱：<span class="g-color">tli@inbar.int</span>',
	/** 竹藤展览 end*/
	QRcode: '请扫码关注欧洽会官方微信公众号，了解更多欧洽资讯',
	poster: {
		b: '电子壁报',
		list: [
			"毛竹爆发性增长期碳和水的动力学分析",
			"丹霞山竹林资源调查与研究",
			"元宝竹竹秆形态特征研究",
			"离体培养的勃氏甜龙竹种子萌发、幼苗生长及秋水仙素的抑制机制",
			`12种竹子的叶表型变异及其与环境因子的关系`,
			`土层厚度对筇竹林下植被生物量分布格局的影响`,
			`京津冀城市群早园竹叶片C、N和P化学计量变异特征`,
			`水竹响应水淹胁迫的生理生化特征`,
			`自然风化作用下竹材的长期退化行为研究`,
			`慈竹养分需求规律与调控技术研究`,
			`竹叶萃取物对肥胖-糖尿病小鼠肝脏生物转化酶的影响`,
			`竹叶萃取物对乳腺癌的抑制作用`,
			`竹叶萃取物抑制肥胖和Ⅱ糖尿病MCP-1过度分泌`,
			`基于5个竹种全基因组的SSR分子标记鉴定与开发`,
			`通过形态学，生理学和转录表达谱揭示miRNA参与竹子杆色形成`,
			`碳水化合物代谢及其调控是影响采后竹笋品质的主要因素`,
			`竹林施氮促进竹笋生长并改变其木质化进程`,
			`开花与未开花翠竹笋芽形态特征与转录组对比分析`,
			`毛竹高效遗传转化及基于CRISPR / cas9的基因组编辑系统`,
			`N2和CO2环境下竹笋壳的热解特性及气态产物`,
			`热解升温速率对机制炭的燃料特性的影响：模仿工业热解过程`,
			`改善乐器用竹材声学振动性能的一种环保高效的方法：氮气保护热处理`,
			`竹子服务中心如何加速非洲生物能源转型`,
			`解读竹产业集群模式，支持区域可持续投融资`,
			`喀麦隆西南部竹藤促进气候变化和碳中和`,
			`竹子作为缓解气候变化的基于自然的解决方案：生物量、竹产品和碳汇信用`,
			`湘西传统竹家具再设计方法探究`,
			`浙江安吉竹工业机械设备发展现状及政策措施`,
			`毛竹采伐方法的研究`,
			`竹子-绿色黄金`,
			`人居竹景观的研究与应用`,
			`竹林文旅康养推广体系与模式研究`,
			`合肥城市竹林生态系统服务价值评估`,
			`中国竹子贸易在“一带一路”沿线国家国际市场上有增长空间`,
			`竹缠绕复合管材产品的可持续性和创新性`,
			`拓展竹户外产业应用，助力“以竹代塑”绿色发展--基于泰普森集团竹户外休闲产品研发生产的分析`,
			`生态文化引领“以竹代塑”的实现路径`,
			`来自阿根廷竹子的多种生物材料`,
			`基于真空辅助树脂传递模塑成型不同纤维形态竹纤维复合材料性能研究`,
			`化学成分分析揭示了人工遮荫降低竹笋苦味的作用`,
			`竹叶黄酮类化合物对FabG的抑制活性`,
			`气调包装和生物保鲜剂在甜龙竹笋保鲜上的应用`,
			`毛竹秆加压热水提取物化学成分及生物活性研究`,
			`不同生长阶段竹材原本木质素的高效分离和结构变化研究`,
			`毛竹薄壁细胞显微结构及木质素微区分布研究`,
			`褐腐菌与白腐菌降解毛竹过程中主要化学组分的变化`,
			`p-TsOH预处理过程中毛竹木质素的定量结构分析`,
			`通过超高效液相色谱-串联四极杆-飞行时间质谱技术同时测定粉单竹竹秆提取物中25种酚酸类化合物`,
			`藤条的局部化学和超微结构的分级变化`,
			`毛竹节维管束的三维表征及其对力学性能的影响`,
			`藤基炭包覆铁纳米颗粒的一步合成制备及高效吸附`,
			`竹基风力发电机叶片技术及其在竹材工业化中的应用前景`,
			`受竹子启发的应用于能源设备的细胞尺度组装`,
			`创新设计中的长竹纤维`,
			`高效阻燃及机械强度良好的壳聚糖基复合膜的简易制备`,
			`云南箭竹笋贮藏过程中的生化变化及冷藏机制`,
			`竹子秆基输导组织的三维可视化`,
			`可做胶粘剂预聚体的高活性有机溶剂木质素`,
			`真空自活化热解：一种将生物质废弃物转化为活性炭和可燃气体的环保方法`,
			`慈竹γ-戊内酯预处理产物酯化制备高取代度醋酸纤维素的研究`,
			`柱前衍生-UPLC法测定竹沥中的氨基酸含量`,
			`一种用于制造竹炭的新型机械窑炉`,
			`酸溶分级法制备竹子木质纤维素纳米膜的综合研究`,
			`竹材半纤维素提取及其解聚过程研究进展`,
			`适用于大规模生产的竹展平复合规格材的制备关键技术研究`,
			`毛竹材薄壁细胞次生壁微纤维取向的研究`,
			`高性能竹长纤维复合材料的生产和特征`,
			`弯曲对圆竹微密度、MFA和MOE径向分布的影响`,
			`通过展平密实一体化技术制备一种新型的竹质工程材料`,
			`竹髓环在胶合界面中的作用`,
			`热压温度对重组竹耐水性能的影响`,
			`兼具良好PM2.5捕获性能和热稳定性的纤维素纳米纤维/聚乙烯醇/竹基活性炭气凝胶片`,
			`淡竹叶极小碳点的制备及荧光机理与其在荧光防伪与细胞成像领域内的应用研究`,
			`矩形钢管框架-竹网格墙结构力学性能及施工工艺研究`,
			`大跨度圆竹拱形结构建造技术—2019年北京世界园艺博览会竹藤馆案例分析`,
			`“Bamboo&Garden&Art”——中国第十届花博会竹藤园实践`,
			`竹林风景线模式构建研究与实践`
		],
	},
	Dhsynopsis: { //大会简介
		title: '大会简介',
		h1: '第二届世界竹藤大会',
		p1: '竹藤是重要的可再生森林资源，自古以来与人类的生产生活息息相关。竹藤资源分布广泛，具有独特的生态、经济和社会价值，可助力解决一系列全球性挑战，在发展绿色经济、应对气候变化、建造抗灾建筑、消除贫困、振兴乡村、保护环境等方面发挥重要作用。在推进绿色低碳转型、构建人类命运共同体的时代潮流下，竹藤已逐渐成为联结国际社会共同愿景的纽带，成为推进南南合作、落实“一带一路”倡议、实现联合国2030年可持续发展目标的重要载体。',
		p2: '为促进竹藤绿色经济发展、助力实现联合国可持续发展目标，由国家林业和草原局与国际竹藤组织联合主办的国际竹藤组织成立二十五周年志庆暨第二届世界竹藤大会（The Second Global Bamboo and Rattan Congress, BARC 2022, 以下简称“大会”）将于2022年11月7—8日在北京召开。大会将汇聚包括各国政府部门、科研院所、国际组织和非政府组织、私营部门、媒体等在内的各界代表，共商新形势下的竹藤发展与合作。鉴于新冠疫情防控需要，本届大会将采取线上线下相结合的形式举行，现场参会仅限目前居住在中国大陆的嘉宾。',
		p3: '第二届世界竹藤大会将延续首届世界竹藤大会（BARC 2018）的精彩与魅力，敬请期待！',
		h2: '大会内容',
		h3: '大会主题和专题',
		h4: '第二届世界竹藤大会以“竹藤一一基于自然的可持续发展解决方案”为主题，为推动竹藤产业健康发展、助力实现碳中和目标，探索竹藤发展新机遇，打造竹藤对话新平台。大会将举办包括开闭幕式、特邀报告、大使对话、平行会议、学术墙报、竹藤产品展览等一系列重要活动，中国和国际竹藤组织还将在开幕式上共同发布“以竹代塑”倡议。',
		h5: '大会将围绕四个专题领域举行平行会议：',
		h6: '专题一、迈向碳中和之路',
		h7: '专题一将在推进碳中和目标的大背景下，重点探讨竹子在助力全球减缓和适应气候变化、保护森林和恢复景观、转变低碳生活方式、实现碳达峰碳中和目标，以及保护生物多样性和提升生态系统服务等领域的作用与贡献。',
		h8: '专题二、助推绿色经济发展',
		h9: '专题二着眼于如何利用竹藤资源减少贫困、振兴乡村、促进可持续生活并推动新型绿色经济。专题内容包括竹藤资源可持续经营、基于竹藤的农林复合系统、小农户生计发展、竹藤中小型企业发展、竹藤推进可持续就业和促进绿色价值链的案例研究、生态旅游与竹藤文化产业等。',
		h10: '专题三、创新材料与市场开发',
		h11: '专题三重点探讨竹藤在不同领域的新突破、新发展，包括新型应用、创新产品、制造前沿、竹藤产业规模化、集群化及循环经济发展等，以满足全球日益增长的对生物基低碳产品的需求。专题内容包括以竹代塑、以竹代木、以竹代棉、以竹代钢材和混凝土建材等创新应用，竹藤产品相对于传统材料和能源的比较优势，竹藤食品研究前沿，竹藤生物物理化学利用进展，竹藤产品标准、认证及市场准入，竹藤产业发展未来趋势等。',
		h12: '专题四、共促产业和谐包容发展',
		h13: '专题四旨在展示并分享全球竹藤可持续发展的国际合作与交流经验。专题内容包括竹藤知识和最佳实践分享，能力建设与技术转让，妇女和社区在竹藤产业发展中的作用，“一带一路”、南南合作与多边合作案例研究，公私伙伴关系在竹藤产业发展中的作用，投资促进和伙伴关系发展，国际竹藤产业价值链和国际贸易便利化等。',
		h14: '竹藤产品展览会',
		h15: '大会同期还将举办线上竹藤产品展览会，汇集竹藤领域的优质产品、先进技术和最新科研成果，包括竹藤家具、建材、工艺品、日用品、竹纤维制品、竹炭制品等相关产品和技术。展区还将现场展示竹藤工匠的精湛技艺，供广大竹藤爱好者观摩、体验。',
		zhuban: '主办方',
		z1: '国际竹藤组织',
		z2: '国际竹藤组织（INBAR）成立于1997年，是第一个总部设在中国的政府间国际组织，也是全球唯一一家专门针对竹藤可持续发展的国际机构。国际竹藤组织目前拥有48个成员国，广泛分布在非洲、亚洲、美洲和大洋洲，并在喀麦隆、厄瓜多尔、埃塞俄比亚、加纳和印度设有区域办事处。国际竹藤组织成立以来， 在保护生态环境、 减缓贫困和发展生计等不同领域积极行动且富有成效。 经过25年的发展， 国际竹藤组织已经成为促进南南合作与南北对话和实现联合国《 2030 年可持续发展议程》 目标的重要国际合作伙伴和发展平台。 ',
		z3: '更多信息，请访问国际竹藤组织官方网站',
		z4: 'www.inbar.int',
		z5: '国家林业和草原局',
		z6: '国家林业和草原局是主管林业和草原工作的政府机构。其主要职责是，监督管理森林、草原、湿地、荒漠和陆生野生动植物资源开发利用和保护，组织生态保护和修复，开展造林绿化工作，管理国家公园等各类自然保护地等。',
		z7: '更多信息，请访问国家林业和草原局官方网站',
		z8: 'www.forestry.gov.cn',
		cenban: '承办方',
		c1: '国际竹藤组织',
		c2: '国家林业和草原局',
		c3: '国际竹藤中心',
		c4: '国际竹藤中心（ICBR）是经科技部、财政部、中央编办批准成立的国家级非营利性科研事业单位，正式成立于2000年7月，隶属于国家林业和草原局。其成立的宗旨是通过建立一个国际性的竹藤科学研究平台，直接服务于第一个总部设在中国的政府间国际组织——国际竹藤组织，支持和配合国际竹藤组织履行其使命和宗旨，以使中国更好地履行《国际竹藤组织东道国协定》，推动国际竹藤事业可持续发展。',
		c5: '更多信息，请访问国际竹藤中心官方网站',
		c6: 'www.icbr.ac.cn',

	},
	introduce: { //嘉宾介绍
		title: '嘉宾介绍',
		t1: '为促进竹藤绿色经济发展、助力实现联合国可持续发展目标，由国际竹藤组织与国家林业和草原局联合主办的第二届世界竹藤大会将于2022年11月7日—8日在北京国际会议中心召开。大会将汇聚包括各国政府部门、科研院所、国际组织和非政府组织、私营部门、媒体等在内的各界代表，共商新形势下的竹藤发展与合作。',
		gj: '国籍：',
		zw: '职位：',
		r1: '江泽慧 教授 （联合主席兼代理主席）',
		r2: '中国',
		r3: '第九届至第十二届中国全国政协人口资源环境委员会副主任',
		r4: '刘世荣 教授',
		r5: '中国',
		r6: '中国林业科学院原院长',
		r7: '吕建雄 教授',
		r8: '中国',
		r9: '中国林业科学研究院木材工业研究所研究员',
		r10: '马丁·姆巴纳 大使',
		r11: '喀麦隆',
		r12: '喀麦隆驻华大使',
		r13: '穆秋姆 大使',
		r14: '坦桑尼亚',
		r15: '国际竹藤组织总干事',
		r16: '陆文明 教授',
		r17: '中国',
		r18: '国际竹藤组织副总干事',
		r19: '费本华',
		r20: '中国',
		r21: '国家林业和草原局国际竹藤中心主任',
		r22: '吴义强教授',
		r23: `中国`,
		r24: `中南林业科技大学校长、中国工程院院士、“长江学者奖励计划”特聘教授、国际木材科学院院士`,
		r25: `巴勃罗•范德卢特博士`,
		r26: `荷兰`,
		r27: `代尔夫特理工大学建筑工程师、世界竹子大使`,
		r28: `尹伟伦教授`,
		r29: `中国`,
		r30: `中国工程院院士、北京林业大学原校长`,
		r31: `克里斯托夫·贝萨西耶`,
		r32: ``,
		r33: `联合国粮农组织高级林业官员、森林和景观恢复机制（FLRM）协调员`,
		end: 'INBAR成员国地图',
		map: require(`@/assets/images/accunt/map.png`),

	},
	conference: { //会场信息
		title: '会场信息',
		title1: '合作伙伴',
		t1: '北京国际会议中心（BICC）',
		t2: '第二届世界竹藤大会将于北京国际会议中心举办。北京国际会议中心于1990年开业，自成立以来，每年为近1000场国际和国内会议以及展览提供服务。',
		t3: '2002年会议中心并入北辰集团，以更有效的管理创造了更强的声誉。该中心位于北京的繁华地段亚运村，集会议中心、商业、购物中心和娱乐场所于一体。位于北四环，距首都国际机场26公里，距大兴国际机场68公里，距市中心9公里。该中心也非常靠近奥运会中心区，包括鸟巢。',
		t4: '北京国际会议中心提供五星级服务，拥有48间不同的会议室。展厅面积5000平方米。北京北辰五洲大酒店是一家四星级酒店，拥有538间客房和5间供应不同类型美食的餐厅。是举办国际国内会议、展示文化活动和举办商务会议的理想场所。',
		t5: '场地更多信息请参考其官网：',
		t6: 'www.bicc.com.cn',
		changdi: '交通信息',
		c1: '场地：',
		c2: '北京国际会议中心',
		c3: '地址：',
		c4: '北京市朝阳区北辰东路8号院3号楼',
		jianjie: '北京简介',
		j1: '北京是中华人民共和国的首都，是全国的政治、经济、文化、教育和国际贸易与通讯中心。它有3000多年的历史，是中国历史不可分割的一部分。850多年来，它一直是六大古都之一，在其漫长的历史中，它已成为政治和社会的核心和灵魂。因此，在游客探索这座城市的古代历史和令人兴奋的现代发展时，有着无与伦比的体验和发现，让他们感到愉悦和好奇。北京成功举办了2008年第29届奥运会和2022年2月第24届冬季奥运会，现在是世界上第一个同时举办过夏季和冬季奥运会的城市。',
		j2: '面积：16800平方公里（6552平方英里）',
		j3: '人口：2170万',
		j4: '国家：中华人民共和国',
		j5: '人口：95%为汉族',
		j6: '主要语言：普通话',
		j7: '时区：GMT/UTC +8小时',
		j8: '电话区号：010',
		j10: '作为一座古城，北京的历史可以追溯到3000年前。春秋战国时期（公元前770年至公元前221年），燕国在北京建都，称为“冀”。在秦汉三国时期，北京地区是中国北方的中心。西汉末年，燕国王莽在北京建立，故称北京“燕京”。南宋时，辽国在北京建都，称为裴国，金朝正式在北京建都。自元朝、明朝和清朝在北京建都以来，共有34位皇帝在北京统治过中国。因历史悠久，北京有着大量的文物古迹和丰富多彩的人文景观，从而成为非常丰富的旅游资源。长城和紫禁城是世界著名的旅游景点。颐和园，北海，香山和天坛都是不容错过的美景。',
		j11: '新中国成立后，北京的社会事业和城市基础设施建设取得了长足的进步。特别是1978年后的20多年，随着改革开放的实施，北京发展变化迅速。如今，它已成为一座现代化城市，高楼大厦、购物中心和大型国际酒店通过纵横交错的复杂高速公路系统连接在一起。然而，现代建筑隐藏着传统的胡同、公园、众多的建筑瑰宝和精美的黄瓦寺庙…',
		tianqi: '11月份天气',
		q1: '北京的天气在11月开始逐渐进入冬季，届时气候相当干燥，昼夜温差变大。由于气温可能降至零度以下，供暖通常从11月15日开始。11月里偶尔也会有强风和冷风，但少有雨雪。',
	},
	programme: '大会议程',
	participation: '注册参会',
	regist: '注 册',
	logistics: '交通住宿',
	exhibition: '竹藤展览',
	registerTable: {
		a: '注册资料包',
		b: '平行会议',
		c: '欢迎晚宴',
		d: '大会展览',
		e: '指定酒店至会场的交通',
		f: '闭幕晚餐',
		v: '√'
	},
	registerTable2: {
		a: '2022年9月30日（含当日）之前提交取消申请',
		aa: '全额退款',
		b: '2022年9月30日之后提交取消申请',
		bb: '不予退款'
	},


	newAdd: {
		t1: '国际竹藤组织创新发展十大看点-SUPPORTING THE SUSTAINABLE DEVELOPMENT GOALS',
	},
	abstractTable: {
		a: '论文摘要提交截止日期',
		b: '2022年9月20日',
		c: '论文摘要录取通知日期',
		d: '2022年9月30日前',
		e: '论文摘要集印刷出版',
		f: '2022年10月28日前'
	},
	exhibitionTable: {
		h1: '事 项',
		h2: '日 期',
		h3: '时 间',
		h4: '备 注',
		a1: '参展申请',
		a2: '2022年9月28日-10月20日',
		a3: '',
		a4: '10月20日为提交参展申请截止时间',
		b1: '搭建布展',
		b2: '2022年11月6日',
		b3: '12:00 - 18:00',
		b4: '布展时间，以现场时间为准',
		c1: '展览开放',
		c2: '2022年11月7日<br>2022年11月8日',
		c3: '14:00-18:30<br>08:30-18:30',
		c4: '开放时间，以现场时间为准',
		d1: '撤展',
		d2: '2022年11月8日',
		d3: '18:30 - 20:30',
		d4: '20:30为最迟撤展时间'
	},
	conferenceAgendaTable: {
		h1: '日  期',
		h2: '时  间',
		h3: '地  点',
		h4: '会议内容',
		day1: {
			val: '11月6日',
			time: '全 天',
			address: '北 京',
			cont: '会前考察/代表注册',
			cont1: '嘉宾注册'
		},
		day2: {
			val: '11月7日',
			time: {
				t: '上 午',
				tc: 'BARC 2022开幕式',
				tc1: '签约仪式',
				tc2: '大使对话',
				a: '下 午',
				ac: '平行会议',
				ac1: '特邀报告',
				b: '晚 上',
				bc: '欢迎晚宴'
			},
			address: '北 京',
			cont: '会前考察、嘉宾注册'
		},
		day3: {
			val: '11月8日',
			time: {
				t: '全 天',
				tc: '平行会议',
				a: '傍 晚',
				ac: 'BARC 2022闭幕式',
				b: '晚 上',
				bc: '闭幕晚餐'
			},
			address: '北 京',
			cont: '会前考察、嘉宾注册'
		},
		day4: {
			val: '11月9日-11日',
			time: '',
			address: '',
			cont: '嘉宾离开',
			cont1: '会后竹区考察'
		}
	},
	preliminaryProgramme: '日程',
	parallelMeeting: {
		zcdw: '支持单位',
		zzs: '赞助商',
		j1: '会议简介',
		j2: '会议目标',
		j3: '主办单位',
		j4: '专题领域',
		j5: '会议flyer',
		j6: '合办单位',
		details: ' 详情',
		t1: '日期',
		t2: 'Zoom 会议号',
		d1: '11月07日',
		d2: '11月08日',
		time1: '16:20 - 18:20',
		time2: '08:30 - 10:10',
		time3: '10:30 - 12:10',
		time4: '13:30 - 15:10',
		time5: '15:30 - 17:10',
		room: '',
		room1: '201',
		r1: '933 6665 4754',
		r2: '989 2086 5684',
		r3: '928 1506 4455',
		r4: '940 4441 4598',
		r5: '950 0242 3197',
		r6: '986 4781 4002',
		r7: '939 0210 1729',
		r8: '961 7631 0060',
		r9: "945 9685 5046",
		row1_1: '2022 International Conference - Bamboo: A Very Sustainable Construction Material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures',
		row1_2: '2022 International Conference - Bamboo: A Very Sustainable Construction Material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures',
		row1_3: 'Equipment R&D and Intelligent Manufacture',
		row1_4: 'International Seminar on Bamboo and Rattan Clusters for Regional Development and Green Transition',
		row1_5: 'International Seminar on Bamboo and Rattan Clusters for Regional Development and Green Transition',
		row2_1: 'The Ecological and Socio-Economic Contribution of Rattan to People within the Context of Global Deforestation and Climate Change',
		row2_2: 'Ecosystem Services and Value Accounting',
		row2_3: 'Replacing Plastics with Bamboo: Policy, roadmap and actions',
		row2_4: 'Advances  in Chemical Utilization and Application of Bamboo',
		row2_5: 'Development and Internationalization of Bamboo and Rattan Standards',
		row3_1: 'Bamboo Forests Management for Decreasing Carbon Emission and Increasing Carbon Sequestration',
		row3_2: 'Bamboo: A Resource for Generating Partnerships and Address Climate Change',
		row3_3: 'Bamboo for Carbon Neutrality in Rural Areas: New Pathways for Carbon Reduction and Low Carbon Development in World Heritage Sites',
		row3_4: 'Key Challenges and Solutions of Sustainable Bamboo Forest Management',
		row3_5: 'Ecological Culture Leads the Process of Replacing Plastic with Bamboo ',
		row4_1: 'Advances in Bamboo Shoot Based Functional Foods Development and its Health Benefits',
		row4_2: 'Promoting the Global Initiative and Action Plan to Replace Plastic with Bamboo',
		row4_3: 'Development of Bamboo Energy to Help Carbon Neutrality',
		row4_4: 'Promotion the Development of Trade Facilitation of Bamboo and Rattan Commodities',
		row4_5: 'Bamboo for Carbon Neutrality and Green Growth',
		row5_1: 'Assessment of Bamboo Resources and Ecosystems Services',
		row5_2: 'Strengthening Resource Mobilization for Sustainable Forest Management and Forest Restoration: Opportunities for Collaboration between the United Nations Forum on Forests Secretariat and the International Bamboo and Rattan Organization ',
		row5_3: 'International Seminar on Nature Based Solutions for Rural Revitalization',
		row5_4: 'Bamboo Developmental Biology and its Sustainable Management Techniques for Bamboo Plantation ',
		row5_5: 'RE-Think Buildings',
		row6_1: 'China Bamboo Industry Innovation and High Quality Development Forum',
		row6_2: 'China Medium and Small Diameter Bamboo Shoots Industry Development Forum ',
		row6_3: 'China Bamboo Under-Forest Economy and Forest Recreation Industry Development Forum ',
		row6_4: 'Green and Sustainable Development of Bamboo Fiber Industry',
		row6_5: 'Bamboo Value Chains for Inclusive and Sustainable Industrial Development in Africa ',
		list: [
			[{}, {
					"conveningOrganizations": "中国林学会",
					"conveningOrganizations1": '中国林学会竹子分会<br>中国林业科学研究院亚热带林业研究所<br>国际竹藤中心<br>南京林业大学<br>浙江省竹产业协会<br>安吉县竹产业协会<br>竹子培育与利用浙江国际科技合作基地<br>永安市竹产业研究院',
					"date": "11月8日",
					"description": "为深入贯彻习近平生态文明思想，落实习近平同志在四川等竹产区考察时的讲话精神，在新冠肺炎疫情常态化防控形势和“碳中和碳达峰”国家战略背景下，紧紧围绕“凝聚·思变·向未来”的竹产业发展趋势，以“竹产业创新发展”为主题，开展推动竹产业创新研发、深挖竹产业潜力价值，促进企业转型升级等方面的研讨与交流，提出新时代中国竹产业创新发展之路，为巩固脱贫攻坚成果和促进乡村振兴、实现共同富裕做出新贡献。",
					"email": "13868141030@139.com",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630729cd05efce09c305da05",
					"objectives": "在双碳背景下，积极开展竹产业科技创新驱动工程，以新技术、新材料、新产品研发为抓手，大力发展“以竹代塑”、“以竹代木”和装配式竹建筑等节能低碳绿色产业，促进我国竹产业的高质量发展，为全面助推竹乡振兴提供强大的产业支撑。",
					"preferredFormat": "线上",
					"roomAllocation": "933 6665 4754",
					"sessionTitle": "S4.1 竹产业创新与高质量发展论坛",
					"thematicArea": " 专题四、共促产业和谐包容发展",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.1 Agenda.pdf',
						title: 'S4.1 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "中国竹产业协会竹产业装备分会",
					"conveningOrganizations1": '国际竹藤中心<br>国际竹藤中心竹藤生物质新材料研究所<br>中国林业科学研究院木材工业研究所<br>国家林草装备科技创新园<br>浙江省林业科学研究院<br>福建省林业科学研究院<br>东莞市嘉航实业有限公司',
					"date": "11月8日",
					"description": "先进机械装备是现代竹产业发展的重要标志，是提高劳动生产率和解放生产力的根本途径，加快推进竹产品初加工和精深加工装备的研发推广，提高竹产品生产连续化、自动化、智能化水平，对现代竹产业创新发展具有重要意义。<br>本次会议邀请国际竹藤中心、 中国林业科学研究院、 北京林业大学、 南京林业大学、 东北林业大学、 浙江农林大学等国内外竹产业装备与智能制造领域的知名专家， 就（ 1） 国内外竹产业机械装备的发展、（ 2） 浙江省竹产业与国家林草装备科技创新园的建设情况、（ 3） 竹产业机械装备制造企业的创新发展等3个议题深入讨论， 以期能够搭建合作平台、 凝聚行业发展共识、 明确行业发展重点、 推动装备研发更大突破。 ",
					"email": "zwei611@hotmail.com",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307227bfde6f913b92cec3a",
					"objectives": "1.研判国内外竹产业机械装备的发展现状与发展趋势<br>2. 深入探讨智能化、 信息化等新兴技术与现代竹产业全产业链的深度融合 <br>3. 深入探讨国内外不同规模的竹产业机械装备制造企业创新发展模式",
					"preferredFormat": "线上",
					"roomAllocation": "933 6665 4754",
					"sessionTitle": " S2.4  装备研发与智能制造",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.4 Agenda.pdf',
						title: 'S2.4 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "竹藤产业国家创新联盟<br>中国竹藤品牌集群",
					"conveningOrganizations1": '国际竹藤中心<br>国际竹藤组织',
					"date": "11月8日",
					"description": "国际竹藤组织系列线上研讨会中，演讲嘉宾、专家和代表呼吁竹藤产业链各环节利益相关方的战略合作与协调一致行动，以促进竹藤在各区域绿色转型中有效发挥作用。专家们提出集群式发展的理念来推动区域合作和区域发展。<p></p>本次会议邀请全球各区域竹藤生产国有关部委代表、竹藤产区地方政府代表、企业家代表、科研人员代表、绿色循环经济专家、绿色金融和贸易界代表、有关国际组织和非政府组织代表，围绕（1）竹藤产业链关键环节各利益相关方战略合作，促进竹藤产业、贸易创新，（2）链接技术、人才、市场和贸易的关键断层，（3）能力建设-培养竹藤产业规划和产业发展专业人才等3个议题进行探讨。",
					"email": "wjin@inbar.int wangheng@icbr.ac.cn xiayu@icbr.ac.cn zhangguopeng@icbr.ac.cn",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630721a8fde6f913b92ceb22",
					"objectives": "本次会议旨在链接技术、人才、市场和贸易的关键断层，促进竹企业家与绿色金融、贸易和碳中和机构的对话，分享和探索竹藤产业、市场和贸易合作的最佳实践。",
					"preferredFormat": "线上",
					"roomAllocation": "933 6665 4754",
					"sessionTitle": "S4.6 竹藤产业集群促进区域发展与绿色转型国际研讨会",
					"thematicArea": "专题四、共促产业和谐包容发展",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.6 Agenda.pdf',
						title: 'S4.6 Agenda.pdf',
					}, ],
					"zcdw": "西南财经大学"
				},
				{
					"conveningOrganizations": "竹藤产业国家创新联盟<br>中国竹藤品牌集群",
					"conveningOrganizations1": '国际竹藤中心<br>国际竹藤组织',
					"date": "11月8日",
					"description": "国际竹藤组织系列线上研讨会中，演讲嘉宾、专家和代表呼吁竹藤产业链各环节利益相关方的战略合作与协调一致行动，以促进竹藤在各区域绿色转型中有效发挥作用。专家们提出集群式发展的理念来推动区域合作和区域发展。<p></p>本次会议邀请全球各区域竹藤生产国有关部委代表、竹藤产区地方政府代表、企业家代表、科研人员代表、绿色循环经济专家、绿色金融和贸易界代表、有关国际组织和非政府组织代表，围绕（1）竹藤产业链关键环节各利益相关方战略合作，促进竹藤产业、贸易创新，（2）链接技术、人才、市场和贸易的关键断层，（3）能力建设-培养竹藤产业规划和产业发展专业人才等3个议题进行探讨。",
					"email": "wjin@inbar.int wangheng@icbr.ac.cn xiayu@icbr.ac.cn zhangguopeng@icbr.ac.cn",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630721a8fde6f913b92ceb22",
					"objectives": "本次会议旨在链接技术、人才、市场和贸易的关键断层，促进竹企业家与绿色金融、贸易和碳中和机构的对话，分享和探索竹藤产业、市场和贸易合作的最佳实践。",
					"preferredFormat": "线上",
					"roomAllocation": "933 6665 4754",
					"sessionTitle": "S4.6  竹藤产业集群促进区域发展与绿色转型国际研讨会",
					"thematicArea": "专题四、共促产业和谐包容发展",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.6 Agenda.pdf',
						title: 'S4.6 Agenda.pdf',
					}, ],
					"zcdw": "西南财经大学"
				},
			],
			[{
					"conveningOrganizations": "国际竹藤组织",
					"conveningOrganizations1": '加拿大不列颠哥伦比亚大学<br>加纳林业研究所',
					"date": "11月7日",
					"description": "藤是具有攀缘特征的棕榈科植物，包含13个属、大约650个种，集中分布在亚洲和非洲的热带地区。仅藤条原材料和藤编产品的国际贸易额就高达3.8亿美元。据估计，全世界有7亿多人由于多种需求从事藤的加工、贸易或使用等相关工作。每年，藤及相关产品的全球贸易（国内贸易和国际贸易）及藤维持生计的价值总和超过70亿美元。<p></p>据估计，大约20%的藤种被用于制作家具、席子和篮子。在20世纪70年代，印度尼西亚满足了全世界约90%的藤条需求。在全球范围内，家具和手工艺品行业主要使用从原始森林和次生森林产出的藤作为原材料。森林减退、过度开发和原始生境的改变导致了用于家具行业的原藤供应锐减，大多数藤产业都陷于低迷。越来越明显的是，由于不可持续的森林滥砍滥伐行为，世界上大多数的藤资源已经受到威胁，导致种质资源流失，甚至一些藤种濒临灭绝。<p></p>为了实现可持续发展，必须对藤资源进行科学管理，采用合理的采伐强度，以实现藤资源的有续繁殖和利用。",
					"email": "rkaam@inbar.int<p></p>terry.sunderland@ubc.ca<p></p>bdobiri@csir-forig.org.gh",
					"language": "英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630736cfa9ea220a1d494c66",
					"objectives": "1.提高对天然藤资源的理解，特别是藤的分类学和繁殖、以及藤资源的开发等方面的认识，并就全球藤资源的可持续管理提供建议。<p></p>2.评估并量化藤对当地居民生计和全球藤贸易的潜在和实际贡献。<p></p>3.了解高端藤产品加工技术的进步和藤条的创新应用。",
					"preferredFormat": "线上",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S2.1 全球森林减退和气候变化背景下，藤对生态、社会和经济的贡献",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "16:20-18:20 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.1Agenda.pdf',
						title: 'S2.1Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "南京林业大学",
					"conveningOrganizations1": '江苏省生态学会<br>江苏省系统工程学会<br>江苏省农业绿色发展研究会',
					"date": "11月8日",
					"description": "面对日益严峻的环境危机，绿水青山就是金山银山，建立生态系统价值实现机制，把看不见、摸不着的生态效益转化为经济效益、社会效益，既是践行绿水青山就是金山银山理念的重要举措，更是完善生态文明制度体系的有益探索。<p></p>本次会议邀请南京林业大学、北京林业大学、浙江农林大学、浙江大学、重庆大学、加拿大阿尔伯塔大学、美国波多黎各大学等机构的专家学者，围绕中国林业碳汇现状和发展趋势、生态系统服务价值的核算、碳中和背景下的生物多样性保护与生态保护等3个议题深入讨论，期望能够就生态系统服务价值的核算方法和核算指标达成进一步共识，以生态系统价值核算助力生态产品价值实现，明晰“双碳”背景下中国林业经济管理政策与发展方向，推动生态系统服务价值核算的应用研究，促进碳中和背景下的生物多样性保护和生态保护。",
					"email": "nerrynor@163.com",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072f15659d73189c43fc13",
					"objectives": "明晰中国林业碳汇现状和发展趋势，揭示生态系统服务价值核算的意义，探讨生态系统服务价值的核算方法和核算指标，促进生态系统服务价值核算的应用研究，提升碳中和背景下的生物多样性保护与生态保护。",
					"preferredFormat": "线上",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S1.3 生态系统服务和价值核算",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.3 Agenda.pdf',
						title: 'S1.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤组织",
					"conveningOrganizations1": '',
					"date": "11月8日",
					"description": "气候变化已经影响到世界各地，极端天气事件频发：干旱、洪水、热浪、暴雨以及其他灾害。110多个国家已承诺到本世纪中叶（2050年）实现碳净零排放或碳中和。少数大国例如中国和美国承诺在2060年前实现碳中和，印度宣布将在2070年前实现碳中和。<p></p>为了实现碳中和，温室气体的排放需要通过碳吸收来平衡。竹子是世界上生长最快的植物之一。大量的竹制品可以替代木材、塑料、钢材、PVC制品和其他高耗能材料。多项研究表明，每公顷竹林生态系统的碳储量在94 ~ 392 吨碳之间，而且竹林具有较高的固碳速率，每年每公顷可达25吨碳。生命周期评价研究表明，当竹产品用后作为生物质能源来处置时，许多竹制品是低碳甚至是负碳的。<p></p>总体而言，竹子在减缓和适应气候变化方面具有显著作用。全球竹林面积超过3500万公顷，分布在热带和亚热带地区，通过竹林生态系统中吸收碳、在竹子产品中储存碳、减少对森林的压力并替代高碳排放产品，从而帮助实现碳中和。",
					"email": "jdurai@inbar.int",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630737a97261210069491dcf",
					"objectives": "阐释竹子在实现碳中和与绿色发展方面提供基于自然的解决方案。特别地，<p></p>1．展示竹子在景观恢复和可持续经营方面具有的固碳潜力。<p></p>2. 展现竹子可通过耐用竹产品形式进行碳储存，以及分享产品生命周期评价相关研究成果。",
					"preferredFormat": "线上",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S4.5 竹子助力碳中和与绿色发展",
					"thematicArea": "专题二、助推绿色经济发展<br>专题四、 共促产业和谐包容发展 ",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.5 Agenda.pdf',
						title: 'S4.5 Agenda.pdf',
					}, ]
				},

				{
					"conveningOrganizations": "国际竹藤中心",
					"conveningOrganizations1": '国际竹藤中心竹藤资源化学利用研究所<br>中国竹产业协会竹食品与日用品分会<br>深圳金色盆地科技有限公司',
					"date": "11月8日",
					"description": "竹子是一类重要的药用植物原料，在中国、东亚和东南亚等地区具有悠久的药食两用历史。近年来，植物化学研究方法和先进的分析、检测仪器在竹子化学研究中得到广泛应用，现代提取、加工设备和先进工艺在生产上得到广泛采用。竹子次生代谢成分的分离、鉴定及其分布规律，重要次生代谢成分的结构修饰与生物转化，竹提取物的生物活性评价及开发利用等基础与应用基础研究均取得重要进展，有力促进了竹资源的高值化利用与竹产业的转型升级。<p></p>目前，竹源保健食品、竹饮料、食品添加剂、日化用品等在中国已形成一定的市场规模和消费群体。然而，产品开发仍相对薄弱，市场占有率有限，标准化、规范化生产等方面仍有很大提升空间。<p></p>在大健康产业蓬勃发展，聚焦“双碳”目标，关注“以竹代塑” 的今天，竹资源化学利用正成为竹产业最具发展潜力的领域之一。本次会议重点围绕竹资源化学利用进行深入讨论，交流竹资源化学利用研究最新进展，探讨新发展领域，明确重点研究与开发方向，推动全竹利用体系的构建。",
					"email": "fengtang@icbr.ac.cn  <p></p>yaoxi@icbr.ac.cn",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072a48a163db23c53a8a71",
					"objectives": "搭建世界范围内的竹资源化学利用领域专家、学者，以及社会企业、私营部门等单位的交流平台，共商竹资源化学利用发展路径，促进竹资源在大健康领域的开发利用。",
					"preferredFormat": "线上",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S3.5 竹资源化学利用研究及其应用进展",
					"thematicArea": "专题三、创新材料及市场开发",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.5Agenda.pdf',
						title: 'S3.5Agenda.pdf',
					}, ],
				},
				{
					"conveningOrganizations": "国际竹藤中心",
					"conveningOrganizations1": '国际标准化组织竹藤技术委员会秘书处<br>国际林联竹藤工作组',
					"date": "11月8日",
					"description": "2019年，竹藤产品的国际贸易总额超过34.17亿美元。然而，与竹藤产品国际贸易的增长趋势相对应的是，现有的竹藤相关国际标准的数量和范围非常有限，远远不能涵盖竹藤产品的各个细分类别，无法满足市场需求。这就导致了竹藤产品质量的良莠不齐，进而容易引发贸易纠纷，极大限制了竹藤产业和竹藤产品国际贸易的健康发展。为了更好地规范竹藤产品的质量，促进竹藤产品的全球贸易，有必要建立全球公认的国际竹藤标准。<br>本次会议邀请国际标准化组织及相关国家的专家讨论亚洲、非洲等不同国家竹藤标准的发展现状、存在的问题、发展趋势及国际化情况，以期进一步推动竹藤相关国际标准的发展。",
					"email": "cfang@icbr.ac.cn",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63071ff0ef35d85bee3fdebe",
					"objectives": "1.促进不同国家和地区的竹藤相关标准的制定者、管理者、国际贸易从业者之间的充分交流和讨论竹藤标准的发展和国际化的现状。<br>2.提升利益相关者对于国际标准化组织框架下的竹藤国际标准的发展的了解。",
					"preferredFormat": "线上",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S3.8 竹藤标准国际化进展",
					"thematicArea": "专题三、创新材料及市场开发",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.8 Agenda.pdf',
						title: 'S3.8 Agenda.pdf',
					}, ],
				},
			],
			[{},
				{
					"conveningOrganizations": "西班牙国际发展合作署",
					"conveningOrganizations1": '厄瓜多尔城市发展和住房部<br>秘鲁国家森林和野生动物管理局<br>国际竹藤组织拉丁美洲和加勒比办事处<br>Bambusa工作室（西班牙）<br>西班牙/葡萄牙--伊比利亚-美洲竹子协会',
					"date": "11月8日",
					"description": "竹子是一种生长迅速的巨型草本植物，全世界共有1642种竹子。在拉丁美洲和加勒比地区使用竹子历史悠久，距今已有一万年。竹子的生态系统服务可以应对气候变化等棘手的全球问题，是重要的基于自然的解决方案。<p></p>通过公众、私人、学术和民间等社会行为体之间的协调配合，竹子资源可被视为国家政策和行动计划以及国际举措中的战略性资源，直接有助于减少气候变化对数百万农村社区的负面影响。将竹子资源纳入到这些农村社区人口的生计中，可以通过利用这种随处可见的资源来改善人们的生计，然而竹资源却通常由于人们缺乏知识而没有得到完全利用。",
					"email": "rafael.garcia@aecid.es<p></p>pablo.ferreiro@aecid.es",
					"language": "中-英-西语 （同传）",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630737a8701b8b21691aceac",
					"objectives": "分享西班牙国际发展合作署在拉丁美洲和加勒比地区利用竹子开发的经验和教训。",
					"preferredFormat": "线上",
					"roomAllocation": "928 1506 4455",
					"sessionTitle": "S4.3 竹子--一种有助于建立伙伴关系和应对气候变化的资源",
					"thematicArea": " 专题四、共促产业和谐包容发展",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.3 Agenda.pdf',
						title: 'S4.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤组织<br>中国科学院西双版纳热带植物园<br>中国科学院东南亚生物多样性研究中心<br>国际山地综合发展中心<br>山地未来研究中心",
					"conveningOrganizations1": '',
					"date": "11月8日",
					"description": "2022年2月，由国际竹藤组织等多家机构共同举办的“生物多样性保护和可持续发展区域合作研讨会”提出基于自然的解决方案的三个关键词“系统化”、“创新”和“伙伴关系”，并建议把深度生物多样性评估、最佳实践研究和对青年学者的能力建设作为共同行动的重点。<p></p>本次研讨会拟邀请生物多样性保护、生态、环境保护、农村发展、区域合作和发展等相关领域的科学家和专业人士，特别是青年研究人员和学生。研讨会还将邀请绿色金融、贸易和碳中和机构和项目代表，地方权威机构、国土资源规划机构、投资机构、国际援助机构、企业家和绿色科技转化机构、非政府组织和有关社区代表参加。会议主要围绕（1）深度生物多样性评估；（2）人与自然最佳互动模式与实践；（3）开展跨国界生物多样性保护和可持续发展行动，促进能力建设和公众意识提高等3个议题展开。",
					"email": "wjin@inbar.int<p></p>xiaxue@xtbg.org.cn <p></p>zouhuang@xtbg.ac.cn <p></p>wangyue@mail.kib.ac.cn",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630720e3009adc6b093a4661",
					"objectives": "研讨会旨在支持以系统、综合和多方合作的模式开展生物多样性保护和可持续发展项目。促进科学家与绿色金融、绿色贸易和碳中和机构的深度沟通交流，分享可供相关机构参考、用于项目开发的生物多样性评价主要指标，分享基于自然解决方案的最佳实践和模式、分析其转化机制，促进战略合作伙伴关系和共同行动方案的形成。",
					"preferredFormat": "线上",
					"roomAllocation": "928 1506 4455",
					"sessionTitle": "S2.2 基于自然的解决方案与乡村振兴国际研讨会",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"zcdw": "西南财经大学",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.2Agenda.pdf',
						title: 'S2.2Agenda.pdf',
					}, ]
				},

				{
					"conveningOrganizations": "国际竹藤组织",
					"conveningOrganizations1": '联合国环境规划署世界保护监测中心 (UNEP-WCMC)',
					"date": "11月8日",
					"description": "联合国环境规划署指出，自20世纪50年代初以来，全球塑料产量超过90亿公吨。然而仅有不到10%的塑料被回收，大约80%的塑料最终进入垃圾填埋场、自然环境和海洋，对陆地和水生生态系统造成污染。此外，大多数塑料是由化石燃料中提取的化学物质生产的，不仅无法可持续利用，还会导致气候变化。为了应对塑料和塑料污染的负面影响，140多个国家制定了相关的塑料禁限令，还有许多国际公约，如巴塞尔公约、鹿特丹公约和斯德哥尔摩公约等，以期控制塑料污染。<p></p>事实证明，竹子可作为塑料的替代品。通过技术革新，以竹子为原材料的现代创新工业产品层出不穷，包括一次性竹纤维餐具制品（盘、碗、杯）、一次性塑料制品替代产品（吸管）、其他日常用品、包装材料、冷却塔用竹格填料、竹缠绕管等。竹子具有可生物降解、生长快、可再生、低碳等诸多优点，且广泛分布于世界上大多数热带和亚热带地区，适合发展循环经济。在惠益生计的同时，竹子还可提供许多无形的生态系统服务和效益。",
					"email": "jdurai@inbar.int",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63073742f386dc2231260112",
					"objectives": "讨论如何将以竹代塑的产品主流化，以促进竹子作为塑料的可行替代品<p></p>竹子如何能纳入全球塑料污染治理议程",
					"preferredFormat": "线上",
					"roomAllocation": "928 1506 4455",
					"sessionTitle": "S3.6 以竹代塑：政策、规划与行动",
					"thematicArea": "专题三、创新材料及市场开发<br>专题四、 共促产业和谐包容发展 ",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.6 Agenda.pdf',
						title: 'S3.6 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "中国生态文化协会",
					"conveningOrganizations1": '中国花卉协会<br>中国竹产业协会<br>国际竹藤中心',
					"date": "11月8日",
					"description": "竹子集经济、生态和文化于一体，培育竹文化、发展竹产业已成为贯彻落实习近平生态文明思想、乡村振兴战略和绿色低碳发展的重要途径，其相关生态文化在以竹代塑中势必起到重要作用。<p></p>本次会议重点交流生态文化和以竹代塑领域的重要学术进展，探讨其发展所面临的问题、机遇与挑战，为建设生态文明和美丽中国做出积极贡献。",
					"email": "ceca_beijing@126.com",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307291f50139a37584547a1",
					"objectives": "为交流生态文化和以竹代塑领域的重要学术进展，探讨生态文化助力以竹代塑中的新成果、新理论和新经验，中国生态文化协会、中国花卉协会和中国竹产业协会拟联合举办“生态文化引领以竹代塑”边会。",
					"preferredFormat": "线上",
					"roomAllocation": "928 1506 4455",
					"sessionTitle": "S2.7 生态文化引领以竹代塑进程",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.7 Agenda.pdf',
						title: 'S2.7 Agenda.pdf',
					}, ]
				},
			],
			[{
					"conveningOrganizations": "国家林草局竹子研究开发中心<br>中国竹产业协会竹食品和日用品分会<br>福建省协同创新院竹产业（建瓯）分院",
					"conveningOrganizations1": '福建明良食品有限公司<br>重庆特珍食品有限公司<br>浙江圣氏生物科技有限公司',
					"date": "11月7日",
					"description": "竹笋是由竹鞭或基部的侧芽发育而成的可食用的嫩芽。竹笋营养完全，富含膳食纤维、蛋白质、多糖、矿物质等，且低脂肪，是亚非拉地区传统使用的优良天然绿色蔬菜和食品原料。随着现代技术的发展, 竹笋功能食品开发越来越受到重视。基于全球鲜竹笋年产量超过5500万吨，以竹笋为基础的食品工业正在全球蓬勃发展，将为人类提供更健康的营养供给。<p></p>会议重点围绕竹笋食用历史、竹笋的营养和生物活性成分及药理基础、竹笋源食品的研究与开发和竹笋食品与人类健康等4个议题，分享有关竹笋营养成分和生物活性化合物及其健康益处的最新研究成果，交流近十年来竹笋类产品和新用途的创新和进展，探讨竹笋作为未来食物所面临的挑战与机遇。",
					"email": "boteatree@163.com",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307288bd1b679258114287a",
					"objectives": "会议旨在通过分享最新研究成果，交流产品创新和进展，充分挖掘竹笋作为未来食物的潜力。此外，会议还将提高全球公众（尤其是发达国家）对竹笋消费的认识，促进竹笋加工和利用方面的全球合作研究和技术开发。",
					"preferredFormat": "线上",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S3.2 功能性竹笋食品发展前沿及其健康价值",
					"thematicArea": "专题三、创新材料及市场开发",
					"timeAllocation": "16:20-18:20 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.2 Agenda.pdf',
						title: 'S3.2 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤组织",
					"conveningOrganizations1": '国际竹藤中心<br>中国竹产业协会',
					"date": "11月8日",
					"description": "在全球治理塑料污染的大趋势下，竹子作为速生、绿色、可再生、低碳和可降解的生物质材料。从一次性餐具，到家居耐用品，包装类、建材类和汽车零部件等，竹制品在很多领域都可以替代塑料产品，为减塑降碳提供了一种基于自然的可持续发展解决方案。为此国际竹藤组织积极倡导“以竹代塑”，以应对全球限塑、禁塑、低碳、绿色的发展需求。<p></p>会议拟邀请与禁塑和以竹代塑相关的政府官员、国际组织代表、研究机构和企业等，围绕“以竹代塑全球倡议及行动计划”、“以竹代塑研发及设备”、“一次性竹餐具生产和市场分析”等3个议题深入探讨。",
					"email": "jfu@inbar.int",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072999702fa623ef5d31bd",
					"objectives": "推动“以竹代塑全球倡议”，促进以竹代塑的行动计划。",
					"preferredFormat": "线上",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S3.3 推进全球以竹代塑发展倡议及行动计划",
					"thematicArea": "专题三、创新材料及市场开发",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.3 Agenda.pdf',
						title: 'S3.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "中国林学会竹藤资源利用分会<br>中国竹产业协会竹炭分会<br>国际竹藤中心绿色经济研究所",
					"conveningOrganizations1": '国际竹藤中心<br>中国竹产业协会<br>中国林学会',
					"date": "11月8日",
					"description": "竹子具有生长快、木质化程度高、加工剩余物多等特点，是开发生物质能源的潜力资源，也是中国实施“一带一路”、“精准扶贫”、“乡村振兴”等国家发展方案的有力抓手。发展竹能源对中国政府提出的2030年实现碳达峰、2060年实现碳中和意义重大。<p></p>本次研讨会将着重讨论竹基固体能源材料的技术创新与应用、竹炭清洁生产与副产物高值化利用、竹材气化多联产、竹活性炭等4个议题，以期能够推进竹材能源化利用的研究、开发与产业化。",
					"email": "472491002@qq.com",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630720531863e8675f73361f",
					"objectives": "本会议旨在交流研讨竹子能源的创新技术、标准体系、应用市场和发展潜力，推进竹材能源化利用的研究、开发与产业化，促进提升竹子能源在助力“双碳”实现过程中的贡献、价值潜力和市场前景。",
					"preferredFormat": "线上",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S1.5 发展竹能源   助力碳中和",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.5 Agenda.pdf',
						title: 'S1.5 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤组织<br>国际竹藤中心绿色经济研究所",
					"conveningOrganizations1": '海关总署关税征管司<br>中国常驻世界贸易代表团<br>龙竹科技集团股份有限公司<br>中国林学会竹藤资源利用分会<br>中国竹产业协会',
					"date": "11月8日",
					"description": "在国际竹藤组织的推动下，竹藤作为战略性自然资源逐渐得到国际社会的关注和重视，绿色、低碳、丰富的竹藤商品也逐渐得到全球消费者的认可和青睐。据估计，全球竹藤产业总产值逾700亿美元，竹藤商品国际贸易总额超30亿美元，全球100多个国家和地区参与竹藤商品国际贸易。竹藤产业发展和商品贸易在促进区域经济合作、推动发展中国家可持续发展进程发挥着重要作用。在竹藤商品贸易便利化进程中，仍存在编码、关税政策、程序、技术等方面的问题，需要进一步完善。<p></p>本次会议拟邀请国内外相关的国际组织、科研院所及竹制品进出口企业代表，交流竹藤商品国际贸易的现状和问题，探讨全球竹藤国际市场的机遇和挑战，解读相关贸易政策，并就促进竹藤商品国际贸易便利化发展提出可行性政策建议和措施。",
					"email": "cjj@icbr.ac.cn",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630727b9519d4f3deb4d0bc7",
					"objectives": "会议将围绕全球竹藤商品贸易现状、潜力和问题，探讨不同层面关于竹藤国际贸易发展的机遇、挑战、政策、措施，达成共识，提出开拓竹藤国际市场、促进竹藤国际贸易便利化发展的政策建议。",
					"preferredFormat": "线上",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S4.7  促进竹藤商品的贸易便利化 ",
					"thematicArea": "专题四、共促产业和谐包容发展",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.7 Agenda.pdf',
						title: 'S4.7 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "世界自然基金会（瑞士）北京代表处",
					"conveningOrganizations1": '国际竹藤组织',
					"date": "11月8日",
					"description": "竹林经营关乎中国农村经济发展、产业供应链的稳定和绿水青山的长久。随着经济社会的发展，农村与农民城镇化进程的持续以及在新冠疫情持续影响的新情势下，传统竹林作业方式与分散性竹林经营面临着经营主体老龄化、经营条件困顿、经营效益减弱等挑战，进而影响竹林经营质量及产业发展。如何有效提高竹林可持续经营水平是竹产业可持续发展面临的重要一课。<p></p>本次会议拟邀请竹林经营与产业发展相关企业、营林单位、林业技术部门、科研机构、高校等利益方代表，就竹林可持续经营中的关键问题进行讨论，总结现状，寻找创新的解决途径。",
					"email": "yjli@wwfchina.org",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072c0e9934a0152e2b59f1",
					"objectives": "旨在搭建交流平台和沟通机制，促进中国竹林经营和竹产业的可持续发展。",
					"preferredFormat": "线上",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S2.8 竹林可持续经营中的关键问题及其对策",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.8Agenda.pdf',
						title: 'S2.8Agenda.pdf',
					}, ]
				},
			],
			[{},
				{
					"conveningOrganizations": "浙江农林大学",
					"conveningOrganizations1": '国家林草局竹林碳汇工程技术研究中心<br>省部共建亚热带森林培育国家重点实验室<br>浙江农林大学竹子研究院',
					"date": "11月8日",
					"description": "竹子是世界上最高产的植物种类之一，是缓解气候变化的一种很有希望的方式。竹子这种高产生长特性可能与地下鞭根的结构有关，地下鞭根促进了不同龄期竹子茎间水分和养分的传递。然而，导致竹子爆炸性增长的机制仍不清楚。<p></p>    竹林的高固碳特性，除了竹子生长快的因素外，还主要依赖于土壤碳储量的稳定性。然而，森林管理和气候变化对土壤碳储量的干扰机制仍存在很多未知因素。<p></p>    碳固定项目对于加强竹林在减缓气候变化方面的作用至关重要，应加强向利益相关者、森林社区管理人员、林业从业人员等推广宣传。<p></p>    因此，我们计划举办本次平行会议，从专业和公众两个角度，扩大我们对竹子固碳的相关认知。",
					"email": "ttmei@zafu.edu.cn",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072be04d5bc90fac2bf279",
					"objectives": "1.从生态生理、机制、技术等方面介绍竹林土壤碳储量稳定性的最新研究进展，并提出竹林经营建议。<p></p>    2.总结未来的研究方向和科学家之间可能的合作，特别是资深和年轻科学家。<p></p>    3.向公众推广管理竹林碳汇项目，并介绍竹林碳汇项目在减缓气候变化方面的作用。",
					"preferredFormat": "线上",
					"roomAllocation": "950 0242 3197",
					"sessionTitle": "S1.1 竹林经营与增汇减排",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.1 Agenda.pdf',
						title: 'S1.1 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "浙江农林大学",
					"conveningOrganizations1": '国家林草局竹林碳汇工程技术研究中心<br>省部共建亚热带森林培育国家重点实验室<br>浙江农林大学竹子研究院',
					"date": "11月8日",
					"description": "竹子是世界上最高产的植物种类之一，是缓解气候变化的一种很有希望的方式。竹子这种高产生长特性可能与地下鞭根的结构有关，地下鞭根促进了不同龄期竹子茎间水分和养分的传递。然而，导致竹子爆炸性增长的机制仍不清楚。<p></p>    竹林的高固碳特性，除了竹子生长快的因素外，还主要依赖于土壤碳储量的稳定性。然而，森林管理和气候变化对土壤碳储量的干扰机制仍存在很多未知因素。<p></p>    碳固定项目对于加强竹林在减缓气候变化方面的作用至关重要，应加强向利益相关者、森林社区管理人员、林业从业人员等推广宣传。<p></p>    因此，我们计划举办本次平行会议，从专业和公众两个角度，扩大我们对竹子固碳的相关认知。",
					"email": "ttmei@zafu.edu.cn",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072be04d5bc90fac2bf279",
					"objectives": "1.从生态生理、机制、技术等方面介绍竹林土壤碳储量稳定性的最新研究进展，并提出竹林经营建议。<p></p>    2.总结未来的研究方向和科学家之间可能的合作，特别是资深和年轻科学家。<p></p>    3.向公众推广管理竹林碳汇项目，并介绍竹林碳汇项目在减缓气候变化方面的作用。",
					"preferredFormat": "线上",
					"roomAllocation": "950 0242 3197",
					"sessionTitle": "S1.1 竹林经营与增汇减排",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.1 Agenda.pdf',
						title: 'S1.1 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "南京林业大学",
					"conveningOrganizations1": '国际竹藤中心<br>西南林业大学<br>江西农业大学',
					"date": "11月8日",
					"description": "竹类植物是世界上生长最快的植物，竹林可通过科学的经营达到可持续的利用。会议邀请竹类植物生长发育和竹林培育相关领域的专家教授，围绕“竹类植物生长发育相关的分子机制及理论基础”这个核心议题展开讨论。",
					"email": "ghliu@njfu.edu.cn",
					"language": " 英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072b18a3a6654f964f3500",
					"objectives": "本次会议主要是为了促进全球竹子科研工作者的交流，探讨竹子培育和可持续经营相关议题的技术瓶颈，助力推动全球竹产业的发展。",
					"preferredFormat": "线上",
					"roomAllocation": "950 0242 3197",
					"sessionTitle": "S1.7 竹类植物发育生物学及其竹林可持续经营技术",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.7 Agenda.pdf',
						title: 'S1.7 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "联合国人类住区规划署",
					"conveningOrganizations1": '',
					"date": "11月8日",
					"description": "本次会议将介绍主办方和合作伙伴在建筑环境循环和资源效率领域的最新项目和突破进展，以及住房部门涉及的社会经济可持续性。通过分享相关成果和新工具，增强各方能力，更好地实现建筑和住房部门的可持续发展。会议的议题将包括循环、资源和能源效率、创新和负担能力。本次会议由联合国人类住区规划署提议，一个地球网络可持续建筑和施工计划、联合国环境规划署和联合国项目事务厅、喜利得基金会等合作伙伴参与。上述机构已经开发了一套工具，以提高国家利益相关方对建筑行业可持续消费和生产的认识。制定了布基纳法索和斯里兰卡两个国家建筑行业发展路线图。路线图围绕建筑材料和住房计划，强调低碳、弹性和可持续性。此外还创新制定了一套住房价值评估方法，以加强国家利益相关方在评估和改善其住房项目方面的能力，提高资源利用的效率、循环和可持续性。这套工具已在斯里兰卡和布基纳法索试行，将会在会议期间介绍该工具试行的经验和不足。",
					"email": "jesus.salcedo@un.org",
					"language": "英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630738bc012176606a497a50",
					"objectives": "本次边会的主要目标是：<p></p>1. 加强与竹藤相关行业、智库和整体利益相关方等合作伙伴的项目合作。<p></p>2. 提高对可持续建筑建设行业的认识，关注材料利用效率，找出差距并提供解决方案，以加强利益相关者的能力。<p></p>3. 通过政策支持，更有针对性地推动各国建设建筑、住房行业以更加高效、低碳、环保的方式转变。<p></p><p></p>4. 通过驻联合国的国家代表，发展和加强建筑部门利益相关者之间的生产性联系，从循环经济的角度高效利用资源。<p></p>5. 举例阐述建筑物和建筑行业中物质价值链的重要性，以及住房如何成为变革的催化剂。<p></p>6. 分享在布基纳法索和斯里兰卡落实联合国第12个可持续发展目标的最佳实践做法、经验教训和机会。",
					"preferredFormat": "线上",
					"roomAllocation": "950 0242 3197",
					"sessionTitle": "S1.8  建筑的再思考",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"noe":true
				},


			],
			[{
					"conveningOrganizations": "中国林学会",
					"conveningOrganizations1": '中国林学会竹子分会<br>中国林业科学研究院亚热带林业研究所<br>国际竹藤中心<br>南京林业大学<br>浙江省竹产业协会<br>安吉县竹产业协会<br>竹子培育与利用浙江国际科技合作基地<br>永安市竹产业研究院',
					"date": "11月7日",
					"description": "为深入贯彻习近平生态文明思想，落实习近平同志在四川等竹产区考察时的讲话精神，在新冠肺炎疫情常态化防控形势和“碳中和碳达峰”国家战略背景下，紧紧围绕“凝聚·思变·向未来”的竹产业发展趋势，以“竹产业创新发展”为主题，开展推动竹产业创新研发、深挖竹产业潜力价值，促进企业转型升级等方面的研讨与交流，提出新时代中国竹产业创新发展之路，为巩固脱贫攻坚成果和促进乡村振兴、实现共同富裕做出新贡献。",
					"email": "13868141030@139.com",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630729cd05efce09c305da05",
					"objectives": "在双碳背景下，积极开展竹产业科技创新驱动工程，以新技术、新材料、新产品研发为抓手，大力发展“以竹代塑”、“以竹代木”和装配式竹建筑等节能低碳绿色产业，促进我国竹产业的高质量发展，为全面助推竹乡振兴提供强大的产业支撑。",
					"preferredFormat": "线上",
					"roomAllocation": "986 4781 4002",
					"sessionTitle": "S4.1 竹产业创新与高质量发展论坛",
					"thematicArea": " 专题四、共促产业和谐包容发展",
					"timeAllocation": "16:20-18:20 （北京时间）"
				},

				{
					"conveningOrganizations": "国际竹藤中心",
					"conveningOrganizations1": '国际竹藤中心竹藤生物质新材料研究所<br>天竹联盟<br>中国林业科学研究院林产化学工业研究所<br>赣南师范大学江西竹基新材料工程中心',
					"date": "11月8日",
					"description": "随着人们对环保意识的重视和提高，竹纤维制品的研发和产业化进程得到快速发展。竹纤维按照制取工艺和化学成分可分为天然竹纤维和竹粘胶纤维，按照纤维型态可分为竹短纤维、竹长纤维和连续竹纤维，按照用途可分为纺织用竹纤维和非纺织用竹纤维。目前，竹纤维在纺织行业和制浆造纸及家居用品、汽车交通、建筑板材等复合材料领域得到开发和应用。通过此次会议，希望对竹纤维的资源丰富、性能优良、健康可持续等特性进行细化解读。",
					"email": "htcheng@icbr.ac.cn",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072c8d6e825d59973d272b",
					"objectives": "充分发挥竹纤维自身特性，不断推动技术创新，提高竹纤维及其制品的科技含量与附加值，走科技支撑产业、产业支撑科技的循环经济发展道路，向“国际化、绿色化、高档化”方向健康快速发展。",
					"preferredFormat": "线上",
					"roomAllocation": "986 4781 4002",
					"sessionTitle": "S3.7 竹纤维产业的绿色可持续发展",
					"thematicArea": "专题二、助推绿色经济发展<br>专题三、 创新材料及市场开发 ",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"zcdw": `竹纤维产业网<br>重庆瑞竹植物纤维制品有限公司<br>广东裕丰竹纤维有限公司<br>广东竹康材料科技有限公司<br>河北吉藁化纤有限责任公司<br>奇竹（北京）科技发展有限公司`,
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.7 Aganda.pdf',
						title: 'S3.7 Aganda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "中国林学会竹子分会",
					"conveningOrganizations1": '四川农业大学<br>中国林业科学研究院亚热带林业研究所<br>国际竹藤中心<br>南京林业大学<br>西南林业大学<br>浙江农林大学<br>竹子培育与利用浙江国际科技合作基地',
					"date": "11月8日",
					"description": "为贯彻落实习近平同志在四川等竹产区考察时提出的因地制宜发展竹产业的讲话精神，在新冠肺炎疫情常态化防控形势和“碳中和碳达峰”国家战略背景下，围绕竹子林下经济与竹林康养产业发展趋势，以“竹子林下经济与竹林康养产业创新发展”为主题，开展高品质竹林下经济资源发掘与高效培育模式创新、竹林下经济产品高值化多元化利用与技术创新、竹林康养基地功能评价与多功能康养基地构建技术、林下经济产品市场开发等方面的研讨与交流，提出中国竹子林下经济与竹林康养产业高质量健康发展的路径，为乡村振兴和“共同富裕”提供技术支撑。",
					"email": "310023939@qq.com",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072bfca3a6654f964f3535",
					"objectives": "针对中国竹子林下经济与竹林康养产业发展中存在的问题和发展趋势，开展高品质竹林下经济资源发掘与高效培育模式创新、竹林下经济产品高值化多元化利用与技术创新、竹林康养基地功能评价与多功能康养基地构建技术、林下经济产品市场开发等方面的研讨与交流，提出竹子林下经济与竹林康养产业高质量健康发展的中国经验和中国模式，为全面助推竹乡振兴提供强大的产业支撑。",
					"preferredFormat": "线上",
					"roomAllocation": "986 4781 4002",
					"sessionTitle": "S2.5 竹子林下经济与竹林康养产业发展论坛",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.5 Agenda.pdf',
						title: 'S2.5 Agenda.pdf',
					}, ]
				},

				{
					"conveningOrganizations": "中国林学会竹子分会",
					"conveningOrganizations1": '杭州市余杭区竹业协会（全国中小径竹笋产业服务站）<br>中国林业科学研究院亚热带林业研究所<br>南京林业大学<br>国际竹藤中心<br>浙江省林业科学研究院（笋竹产业国家创新联盟）<br>国家林业和草原局竹子研究开发中心<br>竹子培育与利用浙江国际科技合作基地',
					"date": "11月8日",
					"description": "为贯彻落实习近平同志在四川等竹产区考察时提出的因地制宜发展竹产业的讲话精神，在新冠肺炎疫情常态化防控形势和“碳中和碳达峰”国家战略背景下，围绕世界竹笋产业发展趋势，以“笋产业创新发展”为主题，开展高品质笋竹资源发掘与高效培育、竹笋高值化多元化利用技术创新，以及产业链、供应链构建等方面的研讨与交流，提出中国中小径竹笋产业高质量健康发展的路径，为乡村振兴和“共同富裕”提供技术支撑。",
					"email": "2602519333@qq.com",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072abc92ee48582b4c8f95",
					"objectives": "针对中国中小径竹笋产业发展中存在的问题和发展趋势，开展乡土笋竹资源开发、优良笋竹资源高效培育技术、笋竹资源的高值化多元化利用以及产业链、供应链构建等方面的研讨与交流，提出中小径竹笋产业高质量健康发展的中国经验，为竹产区乡村振兴和“共同富裕”提供发展模式。",
					"preferredFormat": "线上",
					"roomAllocation": "986 4781 4002",
					"sessionTitle": "S2.3 中小径竹笋产业发展论坛",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.3 Agenda.pdf',
						title: 'S2.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "联合国工业发展组织",
					"conveningOrganizations1": '非洲联盟委员会/非洲联盟发展机构-非洲发展新伙伴计划',
					"date": "11月8日",
					"description": "利用可再生资源的生物经济产业，可以给非洲带来相当大的工业发展机会。竹子生长速度快、弹性高、用途广泛，其产品应用范围涉及建筑材料和家具，食品、纤维和能源等多个领域。会议将重点介绍竹子价值链发展的实例，探讨产业和谐包容发展。会议还将讨论存在的挑战和关键的成功因素，总结不同地区、不同社会制度下的发展经验，为在全球范围内向循环生物经济转变战略提供经验借鉴。",
					"email": "n.schulz@unido.org",
					"language": "中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630737e78ed6e8390b50733d",
					"objectives": "1.展示竹子价值链发展的成功范例。<p></p>2.分享资源供应方面、价值增值、市场发展、特定背景下的挑战和经验教训。<p></p>3.鉴别典型案例在其他情况下可复制的程度。<p></p>4.强调对包容性和可持续性产业发展的贡献，以及潜在的合作机遇。",
					"preferredFormat": "线上",
					"roomAllocation": "986 4781 4002",
					"sessionTitle": "S4.8 竹材价值链促进非洲工业包容可持续发展",
					"thematicArea": "专题二、助推绿色经济发展<p></p>专题四、共促产业和谐包容发展",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.8 Agenda.pdf',
						title: 'S4.8 Agenda.pdf',
					}, ]
				},

			],
			[{
					"conveningOrganizations": "国际竹藤组织",
					"conveningOrganizations1": '宜宾市人民政府<br>眉山市人民政府<br>永安市人民政府<br>资溪县人民政府<br>庆元县人民政府<br>武隆区人民政府<br>邛崃市人民政府<br>桃江县人民政府<br>安吉县人民政府<br>国际金融公司',
					"date": "11月7日",
					"description": "竹产业已成为竹产区农民增收致富、经济社会发展的重要支柱产业助力实施乡村振兴战略。竹子龙头企业在竹产业发展中对同行业的其他企业具有很深的影响、号召力和一定的示范、引导作用，并对当地、竹产业做出突出贡献。会议将邀请竹产区地方政府官员、竹子龙头企业及绿色金融方面专家等，就竹子在乡村发展中的作用、竹子龙头企业引领创新发展、绿色金融服务于竹产业发展等主要议题展开讨论。",
					"email": "Jfu@inbar.int",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=631988328070fa3dfa7a2659",
					"objectives": "通过龙头企业引领竹产业发展，实行竹子一二三产融合发展，创新绿色金融服务于竹产业，实行竹子乡村振兴。",
					"preferredFormat": "线上",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S4.2 地方政府及龙头企业竹产业发展论坛",
					"thematicArea": " 专题四、共促产业和谐包容发展",
					"timeAllocation": "16:20-18:20 （北京时间）"
				},
				{
					"conveningOrganizations": "国际竹藤中心<br>加拿大不列颠哥伦比亚大学",
					"conveningOrganizations1": '国际竹藤中心三亚研究基地<br>国际竹藤中心竹藤资源与环境研究所<br>国际竹藤中心园林花卉与景观研究所<br>国际竹藤中心三亚竹藤伴生林生态系统国家定位观测研究站<br>中加竹藤科学与技术联合实验室',
					"date": "11月8日",
					"description": "竹子作为木材、塑料、钢筋等材料替代品得到较大程度开发利用，同时竹林具有巨大的碳汇减排潜力，在推进全球绿色可持续发展进程中具有积极作用，因此，竹产业是一个非常有潜力的绿色低碳可持续产业。",
					"email": "junweiluan@icbr.ac.cn",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fc7c132209125335af2a",
					"objectives": "会议拟针对竹林生态系统如何在缓解贫困及可持续发展中发挥作用，如何在面对未来气候变化践行碳中和等问题，联合UBC大学，邀请国际上开展竹林生态、生物多样性保育管理及应用、探索城市可持续创新实践模式等相关领域专家探讨当前国际研究现状、不足、发展趋势。",
					"preferredFormat": "线上",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S1.4  碳中和背景下竹林生物多样性保护生态系统适应性管理与可持续发展",
					"thematicArea": " 专题一、迈向碳中和之路",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.4 Agenda.pdf',
						title: 'S1.4 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤中心",
					"conveningOrganizations1": '国际竹藤中心竹藤生物质新材料研究所<br>中国竹产业协会<br>浙江省林业科学研究院',
					"date": "11月8日",
					"description": "2021年，国家林业和草原局、国家发展改革委等10部门联合印发《关于加快推进竹产业创新发展的意见》（林改发〔2021〕104号），明确表示：全面推进竹材建材化，推动竹纤维复合材料、竹纤维异型材料、定向重组竹集成材、竹缠绕复合材料、竹展平材等新型竹质材料研发生产。<br>竹材在家具、 建筑、 桥梁等领域的应用已获得广泛认可。 为进一步推动竹材的发展与应用， 专家建议从材料创新及高质量发展等途径进行有效探索与推动。 <br>本次会议将围绕竹质材料的技术创新与应用进行深入讨论， 交流竹质工程材料的最新进展， 明晰当前技术研发及产业规格存在的问题， 推动竹质工程材料体系的构建。",
					"email": "maxx@icbr.ac.cn",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fcf8bb066c67a9058641",
					"objectives": "本次会议旨在探讨竹质工程材料在生产及应用中的技术难点及现实应用问题，分享交流最新的竹质工程产品及应用前景，为构建竹产业现代化工程体系提供坚实基础。",
					"preferredFormat": "线上",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S3.4  以竹代塑背景下的竹质工程材料科技创新",
					"thematicArea": " 专题三、创新材料及市场开发",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.4 Agenda.pdf',
						title: 'S3.4 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤中心",
					"conveningOrganizations1": '国际竹藤中心竹藤资源与环境研究所<br>竹类种质资源保护与利用国家创新联盟<br>国际竹藤中心竹类与花卉国家林木种质资源保存库<br>福建永安竹林生态系统国家定位观测研究站',
					"date": "11月8日",
					"description": "21世纪以来，世界林业的发展由以木材为中心的传统林业向以生态建设为中心的现代林业转变，竹藤资源培育也由单纯的“经济效益”向“生态效益与经济效益兼顾”方向发展。经过多年的系列研究，世界范围内竹藤资源利用与培育技术已经取得了长足进步，但仍面临着优质种质少、培育技术不符合社会经济发展需求等一系列挑战，如何通过技术创新进一步推动竹藤培育事业的可持续发展，成为亟需解决的关键问题。本次会议旨在加强竹藤资源保育领域专家学者的沟通交流，推动竹藤资源在种质资源开发利用、苗木繁育、高效经营等多个方面的创新变革，促进竹藤事业健康可持续发展。",
					"email": "fansh@icbr.ac.cn",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fd5c1b908026ea3b362f",
					"objectives": "本次会议旨在搭建世界范围内竹藤资源开发、培育专家学者的交流平台，围绕种质资源开发、苗木繁育、高效经营等多个角度研讨未来竹藤资源高效培育与可持续经营的创新策略。",
					"preferredFormat": "线上",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S2.6 竹藤资源高效培育与可持续发展",
					"thematicArea": " 专题二、助推绿色经济发展",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.6Agenda.pdf',
						title: 'S2.6Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤中心",
					"conveningOrganizations1": '国际竹藤中心竹藤资源基因科学与基因产业化研究所<br>南京林业大学<br>浙江农林大学<br>中国林业科学研究院亚热带林业研究所',
					"date": "11月8日",
					"description": "竹藤是重要的森林植物资源，是最具开发潜力的生物质资源，在保障我国木材安全方面发挥着重要作用。随着工业化利用对竹藤专用品种的需要，竹藤育种工作亟待推进。然而，由于竹藤长期处于营养生长及其特殊的生殖生物学特性，严重阻碍了其常规育种工作进程。现代生物技术给竹藤育种带来了良好的契机，挖掘竹藤遗传信息，解析其重要经济性状的遗传调控机制，获取关键遗传因子，验证其功能，不仅具有重要的科学价值，而且将为竹藤分子育种提供基因资源。同时，利用现代生物技术，突破竹藤遗传转化技术瓶颈，建立适宜竹藤的遗传育种体系，对于加速竹藤育种进程具有重要的现实意义。",
					"email": "liying@icbr.ac.cn",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fe8b6d94cc1b3456d0a7",
					"objectives": "交流竹藤遗传育种相关研究最新进展，提升竹藤遗传育种能力，加速育种进程。",
					"preferredFormat": "线上",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S1.9 竹藤遗传育种学研究进展",
					"thematicArea": " 专题一、迈向碳中和之路",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.9 Agenda.pdf',
						title: 'S1.9 Agenda.pdf',
					}, ]
				},
			],
			[{},
				{
					"conveningOrganizations": "国际竹藤组织<br>国际竹藤组织全球竹建筑专家组",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/1.jpg',
						title: 'S3.1_Flyer_中文-01-组办单位',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/2.jpg',
						title: 'S3.1_Flyer_中文-02-会议简介',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/3.jpg',
						title: 'S3.1_Flyer_中文-03-学术委员会',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Opening Ceremony.pdf',
						title: 'S3.1 Agenda_Opening Ceremony.pdf',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
						title: 'S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
						title: 'S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
					}, ],
					"conveningOrganizations1": '清华大学土木水利学院<br>清华大学建筑学院<br>清华大学建筑设计研究院有限公司<br>南京林业大学<br>南京林业大学生物质材料国家工程研究中心<br>北京林业大学园林学院<br>国际竹藤中心',
					"date": "11月8日",
					"description": "竹子自古以来一直被当作建筑材料来使用。基于过去几十年的技术发展和创新，竹材及其他生物质建材可成为基于自然的解决方案，帮助解决全球住房短缺、减缓气候变化、助力实现碳中和。此外，竹材已证明其对民生、经济和环境发展方面的积极贡献。<p></p>    很多不同利益相关方都为推动竹材及其他生物质材料成为常用建筑材料做出了相当大的努力，尤其是工程师、建筑师、景观建筑设计师、研究人员，以及其他来自标准机构、政府和非政府组织以及私营部门的相关人员等。“2022竹建筑国际会议暨第三届生物质复合建筑材料与结构国际会议”包括七场分会议，在2022世界竹藤大会期间将举办其中的两场分会，讨论竹材及其他生物质材作为常用建筑材料的潜在用途和适用性。",
					"email": "kwliu@inbar.int",
					"language": " 中-英-西语 （同传）",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307282701366064107ba40a",
					"objectives": "1.讨论和展示竹子和其他生物质材作为适用建筑材料的最新研究、创新和应用。<p></p>    2.为研究人员和从业人员提供一个平台，分享全球生物质建材及其结构在建筑领域的发展和未来前景。<p></p>    3.加强该领域不同利益相关者之间的交流。",
					"preferredFormat": "线上",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S3.1 2022竹建筑国际会议暨第三届生物质复合建筑材料与结构国际会议 ",
					"thematicArea": "专题三、创新材料及市场开发",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"zcdw": `阿肯顿·阿皮亚·门卡大学<br>
亚的斯亚贝巴大学<br>
浙江大学建筑工程学院<br>
南京工业大学土木工程学院<br>
中国建筑西南设计研究院有限公司<br>
考文垂大学<br>
迪肯大学<br>
苏黎世联邦理工学院<br>
香港大学建筑学院<br>
万隆理工学院<br>
《建筑结构》杂志<br>
《风景园林》杂志<br>
《可持续结构》杂志<br>
《世界竹藤通讯》杂志<br>
中国林业科学研究院木材工业研究所<br>
重庆大学建筑城规学院<br>
东南大学土木工程学院<br>
弗吉尼亚理工大学设计学院<br>
上海市建筑科学研究院<br>
香港建筑师学会<br>
同济大学<br>
匹兹堡大学<br>
西英格兰大学<br>
浙江大学-伊利诺伊大学厄巴纳香槟校区联合学院生物质材料与碳中和联合研究中心`,
					"zzs": `铂金赞助商:<br>
洪雅竹元科技有限公司<br>
<br>
赞助商 (排列不分先后):<br>
安吉竹境竹业科技有限公司<br>
赣州森泰竹木有限公司<br>
杭州邦博科技有限公司<br>
湖南桃花江竹材科技有限公司<br>
江苏见竹绿建竹材科技股份有限公司`
				},
				{
					"conveningOrganizations": "国际竹藤组织<br>国际竹藤组织全球竹建筑专家组",
					"filt": [{
							url: 'https://www.barc2022.inbar.int/barcfile/1.jpg',
							title: 'S3.1_Flyer_中文-01-组办单位',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/2.jpg',
							title: 'S3.1_Flyer_中文-02-会议简介',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/3.jpg',
							title: 'S3.1_Flyer_中文-03-学术委员会',
						},
						{
							url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Opening Ceremony.pdf',
							title: 'S3.1 Agenda_Opening Ceremony.pdf',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
							title: 'S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
							title: 'S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
						},
					],
					"conveningOrganizations1": '清华大学土木水利学院<br>清华大学建筑学院<br>清华大学建筑设计研究院有限公司<br>南京林业大学<br>南京林业大学生物质材料国家工程研究中心<br>北京林业大学园林学院<br>国际竹藤中心',
					"date": "11月8日",
					"description": "竹子自古以来一直被当作建筑材料来使用。基于过去几十年的技术发展和创新，竹材及其他生物质建材可成为基于自然的解决方案，帮助解决全球住房短缺、减缓气候变化、助力实现碳中和。此外，竹材已证明其对民生、经济和环境发展方面的积极贡献。<p></p>    很多不同利益相关方都为推动竹材及其他生物质材料成为常用建筑材料做出了相当大的努力，尤其是工程师、建筑师、景观建筑设计师、研究人员，以及其他来自标准机构、政府和非政府组织以及私营部门的相关人员等。“2022竹建筑国际会议暨第三届生物质复合建筑材料与结构国际会议”包括七场分会议，在2022世界竹藤大会期间将举办其中的两场分会，讨论竹材及其他生物质材作为常用建筑材料的潜在用途和适用性。",
					"email": "kwliu@inbar.int",
					"language": " 中-英-西语 （同传）",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307282701366064107ba40a",
					"objectives": "1.讨论和展示竹子和其他生物质材作为适用建筑材料的最新研究、创新和应用。<p></p>    2.为研究人员和从业人员提供一个平台，分享全球生物质建材及其结构在建筑领域的发展和未来前景。<p></p>    3.加强该领域不同利益相关者之间的交流。",
					"preferredFormat": "线上",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S3.1 2022竹建筑国际会议暨第三届生物质复合建筑材料与结构国际会议 ",
					"thematicArea": "专题三、创新材料及市场开发",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"zcdw": `阿肯顿·阿皮亚·门卡大学<br>
亚的斯亚贝巴大学<br>
浙江大学建筑工程学院<br>
南京工业大学土木工程学院<br>
中国建筑西南设计研究院有限公司<br>
考文垂大学<br>
迪肯大学<br>
苏黎世联邦理工学院<br>
香港大学建筑学院<br>
万隆理工学院<br>
《建筑结构》杂志<br>
《风景园林》杂志<br>
《可持续结构》杂志<br>
《世界竹藤通讯》杂志<br>
中国林业科学研究院木材工业研究所<br>
重庆大学建筑城规学院<br>
东南大学土木工程学院<br>
弗吉尼亚理工大学设计学院<br>
上海市建筑科学研究院<br>
香港建筑师学会<br>
同济大学<br>
匹兹堡大学<br>
西英格兰大学<br>
浙江大学-伊利诺伊大学厄巴纳香槟校区联合学院生物质材料与碳中和联合研究中心`,
					"zzs": `铂金赞助商:<br>
洪雅竹元科技有限公司<br>
<br>
赞助商 (排列不分先后):<br>
安吉竹境竹业科技有限公司<br>
赣州森泰竹木有限公司<br>
杭州邦博科技有限公司<br>
湖南桃花江竹材科技有限公司<br>
江苏见竹绿建竹材科技股份有限公司`
				},
				{
					"conveningOrganizations": "国际竹藤组织",
					"conveningOrganizations1": '国际竹藤中心<br>国际金融公司<br>四川省眉山市人民政府<br>四川省邛崃市人民政府<br>湖南省桃江县人民政府<br>浙江省宁波士林工艺品有限公司',
					"date": "11月8日",
					"description": "竹产业已成为竹产区农民增收致富、经济社会发展的重要支柱产业助力实施乡村振兴战略。竹子龙头企业在竹产业发展中对同行业的其他企业具有很深的影响、号召力和一定的示范、引导作用，并对当地、竹产业做出突出贡献。会议将邀请竹产区地方政府官员、竹子龙头企业及绿色金融方面专家等，就竹子在乡村发展中的作用、竹子龙头企业引领创新发展、绿色金融服务于竹产业发展等主要议题展开讨论。",
					"email": "Jfu@inbar.int",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=631988328070fa3dfa7a2659",
					"objectives": "通过龙头企业引领竹产业发展，实行竹子一二三产融合发展，创新绿色金融服务于竹产业，实行竹子乡村振兴。",
					"preferredFormat": "线上",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S4.2 地方政府及龙头企业竹产业发展论坛",
					"thematicArea": " 专题四、共促产业和谐包容发展",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.2 Agenda.pdf',
						title: 'S4.2 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国家林草局竹子研究开发中心<br>中国竹产业协会竹食品和日用品分会<br>福建省协同创新院竹产业（建瓯）分院",
					"conveningOrganizations1": '福建明良食品有限公司<br>重庆特珍食品有限公司<br>浙江圣氏生物科技有限公司',
					"date": "11月8日",
					"description": "竹笋是由竹鞭或基部的侧芽发育而成的可食用的嫩芽。竹笋营养完全，富含膳食纤维、蛋白质、多糖、矿物质等，且低脂肪，是亚非拉地区传统使用的优良天然绿色蔬菜和食品原料。随着现代技术的发展, 竹笋功能食品开发越来越受到重视。基于全球鲜竹笋年产量超过5500万吨，以竹笋为基础的食品工业正在全球蓬勃发展，将为人类提供更健康的营养供给。<p></p>会议重点围绕竹笋食用历史、竹笋的营养和生物活性成分及药理基础、竹笋源食品的研究与开发和竹笋食品与人类健康等4个议题，分享有关竹笋营养成分和生物活性化合物及其健康益处的最新研究成果，交流近十年来竹笋类产品和新用途的创新和进展，探讨竹笋作为未来食物所面临的挑战与机遇。",
					"email": "boteatree@163.com",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307288bd1b679258114287a",
					"objectives": "会议旨在通过分享最新研究成果，交流产品创新和进展，充分挖掘竹笋作为未来食物的潜力。此外，会议还将提高全球公众（尤其是发达国家）对竹笋消费的认识，促进竹笋加工和利用方面的全球合作研究和技术开发。",
					"preferredFormat": "线上",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S3.2 功能性竹笋食品发展前沿及其健康价值",
					"thematicArea": "专题三、创新材料及市场开发",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.2 Agenda.pdf',
						title: 'S3.2 Agenda.pdf',
					}, ]
				},
			],
			[{},
				{
					"conveningOrganizations": "联合国森林论坛秘书处",
					"conveningOrganizations1": '',
					"date": "11月8日",
					"description": `《联合国森林战略计划(2017-2030年)》指出，“国际森林安排”的目标之一是促进在所有层面上的国际合作，包括南北合作、南南合作、北北合作和三角合作，以及公私伙伴关系和跨部门合作，“联合国森林论坛成员的个体和集体的行动以及承诺对成功执行全球森林目标相关战略规划和成就具有决定性作用。”论坛的合作伙伴也同样重要。国际竹藤组织(INBAR)是联合国森林论坛及其秘书处的宝贵合作伙伴。这种伙伴关系就如同森林和竹藤生长之间的共生关系，是互利共赢的，可以共同推动经济的绿色转型、保护自然和促进动物、地球和人类的健康。第二届世界竹藤大会的主题是“竹藤——基于自然的可持续发展解决方案”，此次大会的专题四“共促产业和谐包容发展”，重点关注可持续发展国际合作交流。
国际森林安排的主要目标之一是实现对所有类型的森林和森林以外的树木的可持续管理。人们从两年前的疫情中得到了教训——保护地球刻不容缓。保护地球，就是守护人类的未来。这直接关系到人与动物的生命健康，而森林的可持续管理以及竹藤种植将有助于改善环境，保护地球。因此，实现可持续发展目标和全球森林目标是重中之重。
<br> 《联合国森林文书》（ UNFI） 和《 联合国森林战略计划（ 2017 - 2030 年）》（ UNSPF） 一直致力于提高人们对可持续森林管理重要性的意识， 从而改善人类生活质量， 提高身体健康素质。 为了促进具体行动的落实并为此调动资金， 联合国森林论坛于2015年建立了全球森林融资促进网络（ GFFFN）， 以协助各国为森林可持续经营管理调动资金， 并为推动联合国森林战略规划的实施作出贡献。 自设立以来， 全球森林融资促进网络已协助34个国家和2个区域设计国家 / 区域森林融资战略或为联合国森林战略计划筹集资金。 全球约有1000名林业专业人员受益于全球森林融资促进网络资助的森林融资相关培训。 森林基金的票据交换所还提供关于森林融资机会、 学习材料和良好做法的最新信息。 然而， 森林融资方面的缺口仍然很大。 为此， 必须采取更多行动， 凝聚更多力量。 `,
					"email": "Tavora-Jainchill@un.org",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63073728a9ea220a1d494ef7",
					"objectives": `（1）促进资源筹措，探讨支持各国实施联合国森林战略规划的具体措施。<br>（2）分享全球森林融资促进网络帮助成员国调动资源，促进可持续森林经营管理的工作经验。<br>（3）寻求与国际竹藤组织等国际伙伴的协同合作，为可持续森林管理调动资源。`,
					"preferredFormat": "线上",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S4.4 加强资源调动以促进森林可持续经营管理和森林恢复",
					"thematicArea": "专题四、共促产业和谐包容发展",
					"timeAllocation": "08:30-10:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.4 Agenda.pdf',
						title: 'S4.4 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "联合国教科文组织驻华代表处",
					"conveningOrganizations1": '国际竹藤组织',
					"date": "11月8日",
					"description": "时值《保护世界文化和自然遗产公约》通过50周年纪念，联合国教科文组织启动了“下一个50年”系列活动，与国际社会共同思考如何使遗产更好地成为韧性、人类与创新的源泉。气候变化已成为对世界遗产最紧迫的威胁之一，而与此同时，世界遗产通过其生态系统为社会应对并适应气候变化也提供了多种解决办法。本会议为联合国教科文组织“遗产保护的新视野”系列线上讲座之一，与会嘉宾将共商“竹乡碳计”，探讨在世界遗产地通过竹资源促进减碳和低碳发展的优秀实践。",
					"email": "y.lu@unesco.org<p></p>yxli@inbar.int",
					"language": " 中文，英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072b8064e9620155454011",
					"objectives": "1.庆祝联合国教科文组织《保护世界文化和自然遗产公约》通过50周年，提升社会公众对文化和遗产助力乡村振兴、应对气候变化和促进可持续发展的意识。<p></p>    2.为各方提供知识共享平台，探索在世界遗产地通过竹资源促进减碳和低碳发展的在地经验。",
					"preferredFormat": "线上",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S1.6 竹乡碳计: 世界遗产地减碳与低碳发展的新路径\n",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "10:30-12:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.6Agenda.pdf',
						title: 'S1.6Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "联合国粮食及农业组织",
					"conveningOrganizations1": '国际竹藤组织',
					"date": "11月8日",
					"description": "尽管竹子能带来的效益及其潜力得到了公认，但在许多国家，竹子往往没有得到可持续的管理和有效的利用。主要是因为缺乏相关的国家竹资源信息，对竹子用途的认知不足，以及对其生态系统服务价值，特别是在气候变化方面的价值认知不足。<p></p>    竹子大约有3500万公顷，记录的物种超过1600种。然而，粮农组织2020年森林资源评估显示，尽管有评估竹林覆盖、储量和碳储量的方法和技术工具，但只有23个国家报告了有关竹子资源的信息。<p></p>    了解竹子资源的现状和分布范围对竹林的可持续经营管理和采伐以及适当利用其潜力至关重要。",
					"email": "Marco.piazza@fao.org",
					"language": "英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072218fde6f913b92cebc6",
					"objectives": "本次会议将探讨在全球和国家（地方）层面评估竹资源和相关生态系统服务的现状和机会。",
					"preferredFormat": "线上",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S1.2 竹资源和生态系统服务评估",
					"thematicArea": "专题一、迈向碳中和之路",
					"timeAllocation": "13:30-15:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.2 Agenda.pdf',
						title: 'S1.2 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "国际竹藤组织",
					"conveningOrganizations1": '加拿大不列颠哥伦比亚大学<br>加纳林业研究所',
					"date": "11月8日",
					"description": "藤是具有攀缘特征的棕榈科植物，包含13个属、大约650个种，集中分布在亚洲和非洲的热带地区。仅藤条原材料和藤编产品的国际贸易额就高达3.8亿美元。据估计，全世界有7亿多人由于多种需求从事藤的加工、贸易或使用等相关工作。每年，藤及相关产品的全球贸易（国内贸易和国际贸易）及藤维持生计的价值总和超过70亿美元。<p></p>据估计，大约20%的藤种被用于制作家具、席子和篮子。在20世纪70年代，印度尼西亚满足了全世界约90%的藤条需求。在全球范围内，家具和手工艺品行业主要使用从原始森林和次生森林产出的藤作为原材料。森林减退、过度开发和原始生境的改变导致了用于家具行业的原藤供应锐减，大多数藤产业都陷于低迷。越来越明显的是，由于不可持续的森林滥砍滥伐行为，世界上大多数的藤资源已经受到威胁，导致种质资源流失，甚至一些藤种濒临灭绝。<p></p>为了实现可持续发展，必须对藤资源进行科学管理，采用合理的采伐强度，以实现藤资源的有续繁殖和利用。",
					"email": "rkaam@inbar.int<p></p>terry.sunderland@ubc.ca<p></p>bdobiri@csir-forig.org.gh",
					"language": "英文",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630736cfa9ea220a1d494c66",
					"objectives": "1.提高对天然藤资源的理解，特别是藤的分类学和繁殖、以及藤资源的开发等方面的认识，并就全球藤资源的可持续管理提供建议。<p></p>2.评估并量化藤对当地居民生计和全球藤贸易的潜在和实际贡献。<p></p>3.了解高端藤产品加工技术的进步和藤条的创新应用。",
					"preferredFormat": "线上",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S2.1 全球森林减退和气候变化背景下，藤对生态、社会和经济的贡献",
					"thematicArea": "专题二、助推绿色经济发展",
					"timeAllocation": "15:30-17:10 （北京时间）",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.1Agenda.pdf',
						title: 'S2.1Agenda.pdf',
					}, ]
				},
			]
		],
	},
	// cA: [{
	// 	date: '2022年11月7日',
	// 	time: ['14:30-15:30', '15:30-16:00', '16:00-17:30', '17:30-18:30', '18:30-18:40', '19:00-20:30'],
	// 	title: ['国际竹藤组织成立二十五周年志庆<br>暨第二届世界竹藤大会开幕式（二层第一会议厅）', '休会', '特邀报告（一层第五会议厅）', '大使对话（一层第五会议厅）', '签约仪式', '招待会（二层第一会议厅）']
	// }, {
	// 	date: '2022年11月8日',
	// 	time: ['08:30-10:10', '10:10-10:30', '10:30-12:10', '12:10-13:30', '13:30-15:10', '15:10-15:30', '15:30-17:10', '17:30-18:15', '18:30-19:30'],
	// 	title: ['平行会议 (9个)', '茶歇', '平行会议 (9个)', '自助午餐', '平行会议 (9个)', '茶歇', '平行会议 (9个)', '闭幕式（一层第三会议厅）', '自助晚餐']
	// }],

	cA: [{
		date: '2022年11月7日',
		time: ['09:00 -17:00', '16:15-17:15', '17:15-18:45', ],
		title: ['开幕式', '大使对话', '特邀报告']
	}, {
		date: '2022年11月8日',
		time: ['08:30-17:10', '17:30-18:00'],
		title: ['平行会议', '闭幕式']
	}],


	click: `您可点击下方活动名称观看相应直播`,
	exh: {
		zuzi: {
			title: "国际竹藤组织",
			img: [
				require("../../assets/images/exhibition/国际竹藤组织/1-5.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-1.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-2.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-3.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-4.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-6.png"),
			],
		},
		list: [{
				title: "国际竹藤组织",
				gs: [{
						title: "<span style='opacity: 0;' class='yidong'>竹竹</span>全竹展厅<span style='opacity: 0;' class='yidong'>竹竹</span>",
						img: [
							require("../../assets/images/exhibition/0/1-5.png"),
							require("../../assets/images/exhibition/0/1-1.png"),
							require("../../assets/images/exhibition/0/1-2.png"),
							require("../../assets/images/exhibition/0/1-3.png"),
							require("../../assets/images/exhibition/0/1-4.png"),
							require("../../assets/images/exhibition/0/1-6.png"),
						],
					},
					{
						title: "国际竹藤组织简介",
						img: [
							require("../../assets/images/exhibition/0/2-1.jpg"),
							require("../../assets/images/exhibition/0/2-2.jpg"),
						],
					},
					{
						title: "<span style='opacity: 0;' class='yidong'>竹</span>“以竹代塑”倡议<span style='opacity: 0;' class='yidong'>竹</span>",
						img: [
							require("../../assets/images/exhibition/0/3-1.jpg"),
							require("../../assets/images/exhibition/0/3-2.jpg"),
						],
					},
				],
			},
			{
				title: "竹藤科技",
				gs: [{
						title: `<span style='opacity: 0;' class='yidong'>竹</span>竹藤科技展厅<span style='opacity: 0;' class='yidong'>竹</span>`,
						img: [
							require("../../assets/images/exhibition/1/0-1.jpg"),
							require("../../assets/images/exhibition/1/0-2.jpg"),
							require("../../assets/images/exhibition/1/0-3.jpg"),
							require("../../assets/images/exhibition/1/0-4.jpg"),
							require("../../assets/images/exhibition/1/0-5.jpg"),
							// require("../../assets/images/exhibition/1/0-6.jpg"),
							// require("../../assets/images/exhibition/1/0-7.jpg"),
							// require("../../assets/images/exhibition/1/0-8.jpg"),
							// require("../../assets/images/exhibition/1/0-9.jpg"),
							// require("../../assets/images/exhibition/1/0-10.jpg"),
							// require("../../assets/images/exhibition/1/0-11.jpg"),
							// require("../../assets/images/exhibition/1/0-12.jpg"),
							// require("../../assets/images/exhibition/1/0-13.jpg"),
							// require("../../assets/images/exhibition/1/0-14.jpg"),
							// require("../../assets/images/exhibition/1/0-15.jpg"),
							// require("../../assets/images/exhibition/1/0-16.jpg"),
							// require("../../assets/images/exhibition/1/0-17.jpg"),
							// require("../../assets/images/exhibition/1/0-18.jpg"),
							// require("../../assets/images/exhibition/1/0-19.jpg"),
							// require("../../assets/images/exhibition/1/0-20.jpg"),
							// require("../../assets/images/exhibition/1/0-21.jpg"),
						]
					},
					{
						title: "<span style='opacity: 0;' class='yidong'>竹竹</span>参展单位<span style='opacity: 0;' class='yidong'>竹竹</span>",
						img: [
							require("../../assets/images/exhibition/1/2.jpg"),
							require("../../assets/images/exhibition/1/1.jpg"),
						]
					},
					// {
					// 	title: "国际竹藤中心",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/1-1.png"),
					// 	],
					// },
					{
						title: "竹藤产业宣传视频",
						video: 1,
						img: [

						],
					},
					{
						title: "<span style='opacity: 0;' class='yidong'>竹</span>竹藤科技产品<span style='opacity: 0;' class='yidong'>竹</span>",
						video: 2,
						img: [

						],
					},
					{
						title: "<span style='opacity: 0;' class='yidong'>竹</span>竹藤科技成果<span style='opacity: 0;' class='yidong'>竹</span>",
						img: [
							require("../../assets/images/exhibition/1/c1.jpg"),
							require("../../assets/images/exhibition/1/c2.jpg"),
						],
					},
					// {
					// 	title: "中国竹产业协会",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/2-1.png"),
					// 	],
					// },
					// {
					// 	title: "中国生态文化协会",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/3-1.png"),
					// 	],
					// },
					// {
					// 	title: "中国花卉协会",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/4-1.png"),
					// 	],
					// },
					// {
					// 	title: "中国林学会竹藤资源利用分会",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/5-1.png"),
					// 	],
					// },
				],
			}, {
				title: "福建永安",
				title1: "福建省永安市人民政府",
				gs: [{
						title: "福建省永安市人民政府",
						img: [
							require("../../assets/images/exhibition/2/1-1.jpg"),
							require("../../assets/images/exhibition/2/1-2.jpg"),
						],
					},
					{
						title: "福建和其昌竹业股份有限公司",
						img: [
							require("../../assets/images/exhibition/2/2-1.jpg"),
						],
					},
					{
						title: "福建省八一村永庆竹木业开发有限责任公司",
						img: [
							require("../../assets/images/exhibition/2/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "四川眉山",
				title1: "四川省眉山市人民政府",
				gs: [{
					title: "四川省眉山市人民政府",
					img: [
						require("../../assets/images/exhibition/3/1-1.jpg"),
					],
				}, {
					title: "青神国际竹艺城发展投资有限公司",
					img: [
						require("../../assets/images/exhibition/3/2-1.jpg"),
					],
				}, ],
			},
			{
				title: "四川宜宾",
				title1: "四川省宜宾市人民政府",
				gs: [{
						title: "四川省宜宾市人民政府",
						img: [
							require("../../assets/images/exhibition/4/1-1.jpg"),
						],
					},
					{
						title: "宜宾祥泰环保科技有限公司",
						img: [
							require("../../assets/images/exhibition/4/2-1.jpg"),
						],
					},
					{
						title: "四川蜀南文化旅游健康产业投资集团有限公司",
						img: [
							require("../../assets/images/exhibition/4/3-1.jpg"),
						],
					},
					{
						title: "宜宾纸业股份有限公司  ",
						img: [
							require("../../assets/images/exhibition/4/4-1.jpg"),
						],
					},
				],
			},
			{
				title: "江西资溪",
				title1: "江西省资溪县人民政府",
				gs: [{
						title: "江西省资溪县人民政府",
						img: [
							require("../../assets/images/exhibition/5/1-1.jpg"),
							require("../../assets/images/exhibition/5/1-2.jpg"),
						],
					},
					{
						title: "江西庄驰家居科技有限公司  ",
						img: [
							require("../../assets/images/exhibition/5/2-1.jpg"),
						],
					},
					{
						title: "双枪科技股份有限公司  ",
						img: [
							require("../../assets/images/exhibition/5/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "浙江庆元",
				title1: "浙江省庆元县人民政府",
				gs: [{
						title: "浙江省庆元县人民政府",
						img: [
							require("../../assets/images/exhibition/6/1-1.jpg"),
						],
					},
					{
						title: "浙江九川竹木股份有限公司  ",
						img: [
							require("../../assets/images/exhibition/6/2-1.jpg"),
							require("../../assets/images/exhibition/6/2-2.jpg"),
						],
					},
					{
						title: "庆元县竹产业行业协会",
						img: [
							require("../../assets/images/exhibition/6/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "四川邛崃",
				title1: "四川省邛崃市人民政府",
				gs: [{
						title: "四川省邛崃市人民政府",
						img: [
							require("../../assets/images/exhibition/7/1-1.jpg"),

						],
					}, {
						title: "邛崃市平乐双溪河竹编工艺品厂",
						img: [
							require("../../assets/images/exhibition/7/2-1.jpg"),
						],
					},
					{
						title: "四川省旺德润农业科技有限责任公司",
						img: [
							require("../../assets/images/exhibition/7/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "浙江安吉",
				title1: "浙江省安吉县人民政府",
				gs: [{
						title: "浙江省安吉县人民政府",
						img: [
							require("../../assets/images/exhibition/8/1-2.jpg"),
							require("../../assets/images/exhibition/8/1-1.jpg"),
						],
					},
					{
						title: "浙江峰晖竹木制品有限公司",
						img: [
							require("../../assets/images/exhibition/8/2-1.jpg"),
						],
					},
					{
						title: "浙江森林生物科技有限公司",
						img: [
							require("../../assets/images/exhibition/8/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "湖南桃江",
				title1: "湖南省桃江县人民政府",
				gs: [{
						title: "湖南省桃江县人民政府",
						img: [
							require("../../assets/images/exhibition/9/1-1.jpg"),
							require("../../assets/images/exhibition/9/1-2.jpg"),
						],
					},
					{
						title: "桃江县竹缘林科开发有限公司 ",
						img: [
							require("../../assets/images/exhibition/9/2-1.jpg"),
						],
					},
					{
						title: "桃江县益玩游乐设备有限公司",
						img: [
							require("../../assets/images/exhibition/9/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "湖南竹协",
				title1: "湖南省竹产业协会",
				gs: [{
						title: "湖南省竹产业协会",
						img: [
							require("../../assets/images/exhibition/10/1-1.jpg"),
							require("../../assets/images/exhibition/10/1-2.jpg"),
						],
					},
					{
						title: "湖南大为竹业股份有限公司",
						img: [
							require("../../assets/images/exhibition/10/2-1.jpg"),
						],
					},
					// {
					// 	title: "湖南银山竹业有限公司",
					// 	row: true,
					// 	img: [
					// 		require("../../assets/images/exhibition/10/3-1.jpg"),
					// 	],
					// },
					{
						title: "湖南竹海炭生源生物科技有限公司<br>湖南九通竹基复合材料制造有限公司<br>湖南银山竹业有限公司",
						padding: 1,
						img: [
							require("../../assets/images/exhibition/10/4-1.jpg"),
						],
					},
					// {
					// 	title: "湖南竹海炭生源生物科技有限公司",
					// 	row: true,
					// 	img: [
					// 		require("../../assets/images/exhibition/10/4-1.jpg"),
					// 	],
					// },
				],
			},
			{
				title: "广西桂林",
				title1: "广西壮族自治区桂林市人民政府",
				gs: [{
						title: "广西壮族自治区桂林市人民政府",
						img: [
							require("../../assets/images/exhibition/11/1-1.jpg"),
						],
					}, {
						title: "桂林蓝海贸易有限责任公司<br>桂林集扇斋文化创意有限公司",
						padding: 1,
						img: [
							require("../../assets/images/exhibition/11/2-1.jpg"),
						],
					},
					//  {
					// 	title: "桂林集扇斋文化创意有限公司",
					// 	img: [
					// 		require("../../assets/images/exhibition/11/2-1.jpg"),
					// 	],
					// },
					{
						title: "广西亿福农林环保科技有限公司<br>资源县桂族竹业有限公司",
						padding: 1,
						img: [
							require("../../assets/images/exhibition/11/4-1.jpg"),
						],
					},
					// {
					// 	title: "资源县桂族竹业有限公司",
					// 	img: [
					// 		require("../../assets/images/exhibition/11/4-1.jpg"),
					// 	],
					// },
				],
			},
			{
				title: "四川环龙",
				gs: [{
					title: "四川环龙新材料有限公司",
					img: [
						require("../../assets/images/exhibition/12/1-3.jpg"),
						require("../../assets/images/exhibition/12/1-1.jpg"),
						require("../../assets/images/exhibition/12/1-2.jpg"),
					],
				}, ],
			},
			{
				title: "天竹联盟",
				gs: [{
					title: "天竹联盟",
					img: [
						require("../../assets/images/exhibition/13/1-3.jpg"),
						require("../../assets/images/exhibition/13/1-1.jpg"),
						require("../../assets/images/exhibition/13/1-2.jpg"),
					],
				}, ],
			},
			{
				title: "宁波士林",
				gs: [{
					title: "宁波士林工艺品有限公司",
					img: [
						require("../../assets/images/exhibition/14/1-1.jpg"),
						require("../../assets/images/exhibition/14/1-3.jpg"),
						require("../../assets/images/exhibition/14/1-2.jpg"),
					],
				}, ],
			},
			{
				title: "长沙正忠",
				gs: [{
					title: "长沙正忠科技发展有限公司",
					img: [
						require("../../assets/images/exhibition/15/1-1.jpg"),
						require("../../assets/images/exhibition/15/1-2.jpg"),
						require("../../assets/images/exhibition/15/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "福建智辰",
				gs: [{
					title: "福建智辰智能农业装备有限公司",
					img: [
						require("../../assets/images/exhibition/17/1-1.jpg"),
						// require("../../assets/images/exhibition/17/1-2.jpg"),
						// require("../../assets/images/exhibition/17/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "龙竹科技",
				gs: [{
					title: "龙竹科技集团股份有限公司",
					img: [
						require("../../assets/images/exhibition/18/1-1.jpg"),
						require("../../assets/images/exhibition/18/1-2.jpg"),
						require("../../assets/images/exhibition/18/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "湖南桃花江",
				gs: [{
					title: "湖南桃花江竹材科技股份有限公司",
					img: [
						require("../../assets/images/exhibition/19/1-3.jpg"),
						require("../../assets/images/exhibition/19/1-2.jpg"),
						require("../../assets/images/exhibition/19/1-1.jpg"),
					],
				}, ],
			},
			{
				title: "安徽长思",
				gs: [{
					title: "安徽长思机械设备科技有限公司",
					img: [
						require("../../assets/images/exhibition/20/1-1.jpg"),
						require("../../assets/images/exhibition/20/1-2.jpg"),
						require("../../assets/images/exhibition/20/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "云南珍竹",
				gs: [{
					title: "云南珍竹农业科技有限公司",
					img: [
						require("../../assets/images/exhibition/21/1-1.jpg"),
						require("../../assets/images/exhibition/21/1-2.jpg"),
						require("../../assets/images/exhibition/21/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "湖北药姑山",
				gs: [{
					title: "湖北药姑山生态产品有限公司",
					img: [
						require("../../assets/images/exhibition/22/1-1.jpg"),
						require("../../assets/images/exhibition/22/1-2.jpg"),
						require("../../assets/images/exhibition/22/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "浙江旺林",
				gs: [{
					title: "浙江旺林生物科技有限公司",
					img: [
						require("../../assets/images/exhibition/23/1-1.jpg"),
						require("../../assets/images/exhibition/23/1-2.jpg"),
						require("../../assets/images/exhibition/23/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "中国林学会竹子分会 ",
				gs: [{
					title: "中国林学会竹子分会",
					img: [
						require("../../assets/images/exhibition/24/1-2.jpg"),
						require("../../assets/images/exhibition/24/1-3.jpg"),
						require("../../assets/images/exhibition/24/1-1.jpg"),
					],
				}, ],
			},
			{
				title: "世界自然基金会 ",
				gs: [{
					title: "世界自然基金会",
					img: [
						require("../../assets/images/exhibition/25/1-1.jpg"),
						require("../../assets/images/exhibition/25/1-2.jpg"),
						require("../../assets/images/exhibition/25/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "世界竹藤通讯 ",
				gs: [{
					title: "世界竹藤通讯",
					img: [
						require("../../assets/images/exhibition/27/1-2.jpg"),
						require("../../assets/images/exhibition/27/1-3.jpg"),
						require("../../assets/images/exhibition/27/1-1.jpg"),
					],
				}, ],
			},
			{
				title: "深圳山峰智动 ",
				gs: [{
					title: "深圳市山峰智动科技有限公司",
					img: [
						require("../../assets/images/exhibition/28/1-1.jpg"),
						require("../../assets/images/exhibition/28/1-2.jpg"),
						require("../../assets/images/exhibition/28/1-3.jpg"),
					],
				}, ],
			},
			{
				title: '中国竹建筑企业联合展',
				gs: [{
						title: "洪雅竹元科技有限公司",
						row: true,
						img: [
							require("../../assets/images/exhibition/29/1-1.jpg"),
							require("../../assets/images/exhibition/29/1-2.jpg"),
						],
					},
					{
						title: "<span style='opacity: 0;' class='yidong'>竹竹</span>安吉竹境竹业科技有限公司<span style='opacity: 0;' class='yidong'>竹竹</span>",
						img: [
							require("../../assets/images/exhibition/30/1-1.jpg"),
						],
					},
					{
						title: "杭州邦博科技有限公司",
						img: [
							require("../../assets/images/exhibition/32/1-1.jpg"),
						],
					},

					// {
					// 	title: "赣州森泰竹木有限公司",
					// 	row: true,
					// 	img: [

					// 	],
					// },
					{
						title: "江苏见竹绿建竹材科技股份有限公司<br>赣州森泰竹木有限公司",
						padding: 1,
						img: [
							require("../../assets/images/exhibition/33/1-1.jpg"),
							require("../../assets/images/exhibition/33/1-2.jpg"),
						],
					},
				],
			},
			{
				title: "北京林业大学",
				gs: [{
					title: "北京林业大学",
					img: [
						require("../../assets/images/exhibition/16/1-1.jpg"),
						require("../../assets/images/exhibition/16/1-2.jpg"),
						require("../../assets/images/exhibition/16/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "联合国环境规划署世界保护监测中心",
				t1: '联合国环境规划署',
				t2: '世界保护监测中心',
				gs: [{
					title: "联合国环境规划署世界保护监测中心（UNEP-WCMC）",
					img: [
						require("../../assets/images/exhibition/26/1-1.jpg"),
						require("../../assets/images/exhibition/26/1-2.jpg"),
						require("../../assets/images/exhibition/26/1-3.jpg"),
					],
				}, ],
			},
			// {
			// 	title: "洪雅竹元 ",
			// 	gs: [{
			// 			title: "洪雅竹元科技有限公司",
			// 			img: [
			// 				require("../../assets/images/exhibition/29/1-1.jpg"),
			// 				require("../../assets/images/exhibition/29/1-2.jpg"),
			// 			],
			// 		},
			// 	],
			// },
			// {
			// 	title: "安吉竹境 ",
			// 	gs: [{
			// 		title: "安吉竹境竹业科技有限公司",
			// 		img: [
			// 			require("../../assets/images/exhibition/30/1-1.jpg"),
			// 		],
			// 	}, ],
			// },
			// {
			// 	title: "赣州森泰 ",
			// 	gs: [{
			// 		title: "赣州森泰竹木有限公司",
			// 		img: [

			// 		],
			// 	}, ],
			// },
			// {
			// 	title: "杭州邦博 ",
			// 	gs: [{
			// 		title: "杭州邦博科技有限公司",
			// 		img: [
			// 			require("../../assets/images/exhibition/32/1-1.jpg"),
			// 		],
			// 	}, ],
			// },
			// {
			// 	title: "江苏见竹 ",
			// 	gs: [{
			// 		title: "江苏见竹绿建竹材科技股份有限公司",
			// 		img: [
			// 			require("../../assets/images/exhibition/33/1-1.jpg"),
			// 			require("../../assets/images/exhibition/33/1-2.jpg"),
			// 		],
			// 	}, ],
			// },
		],
	},
	hzhb: {
		t1: `合作伙伴及参会机构`,
		t2: `中方合作伙伴`,
		t3: `国际合作伙伴`,
		list: [
			`国家林业和草原局`,
			`国际竹藤中心`,
			`海关总署关税征管司`,
			`中国常驻世界贸易组织代表团`,
			`国家林业和草原局竹林碳汇工程技术研究中心`,
			`国家林业和草原局竹子研究开发中心`,
			`国际竹藤中心绿色经济研究所`,
			`国际竹藤中心园林花卉与景观研究所`,
			`国际竹藤中心竹类与花卉国家林木种质资源保存库`,
			`国际竹藤中心竹藤生物质新材料研究所`,
			`国际竹藤中心竹藤资源化学利用研究所`,
			`国际竹藤中心竹藤资源基因科学与基因产业化研究所`,
			`国际竹藤中心竹藤资源与环境研究所`,
			`国际竹藤中心三亚研究基地`,
			`海南三亚竹藤伴生林生态系统国家定位观测研究站`,
			`中加竹藤科学与技术联合实验室`,
			`中国科学院东南亚生物多样性研究中心`,
			`中国科学院西双版纳热带植物园`,
			`中国林业科学研究院林产化学工业研究所`,
			`中国林业科学研究院木材工业研究所`,
			`中国林业科学研究院亚热带林业研究所`,
			`山地未来研究中心`,
			`福建省林业科学研究院`,
			`福建省协同创新院竹产业（建瓯）分院`,
			`河北省活性炭产业技术研究院`,
			`上海市建筑科学研究院`,
			`省部共建亚热带森林培育国家重点实验室`,
			`浙江省林业科学研究院（笋竹产业国家创新联盟）`,
			`竹子培育与利用浙江国际科技合作基地`,
			`永安市竹产业研究院`,
			`福建永安竹林生态系统国家定位观测研究站`,
			`中国竹产业协会`,
			`中国竹产业协会竹产业装备分会`,
			`中国竹产业协会竹食品和日用品分会`,
			`中国竹产业协会竹炭分会`,
			`中国生态文化协会`,
			`中国花卉协会`,
			`中国林学会`,
			`中国林学会竹藤资源利用分会`,
			`中国林学会竹子分会`,
			`中国竹藤品牌集群`,
			`竹类种质资源保护与利用国家创新联盟`,
			`竹藤产业国家创新联盟`,
			`香港建筑师学会`,
			`江苏省农业绿色发展研究会`,
			`江苏省生态学会`,
			`江苏省系统工程学会`,
			`浙江省竹产业协会`,
			`安吉县竹产业协会`,
			`杭州市余杭区竹业协会（全国中小径竹笋产业服务站）`,
			`北京林业大学`,
			`北京林业大学园林学院`,
			`南京林业大学`,
			`南京林业大学生物质材料国家工程研究中心`,
			`西南林业大学`,
			`浙江农林大学`,
			`浙江农林大学竹子研究院`,
			`同济大学`,
			`江西农业大学`,
			`四川农业大学`,
			`西南财经大学`,
			`清华大学建筑学院`,
			`清华大学土木水利学院`,
			`香港大学建筑学院`,
			`浙江大学建筑工程学院`,
			`浙江大学-伊利诺伊大学厄巴纳香槟校区联合学院生物质材料与碳中和联合研究中心`,
			`重庆大学建筑城规学院`,
			`东南大学土木工程学院`,
			`赣南师范大学江西竹基新材料工程中心`,
			`南京工业大学土木工程学院`,
			`福建省永安市人民政府`,
			`四川省眉山市人民政府`,
			`四川省邛崃市人民政府`,
			`重庆市武隆区人民政府`,
			`湖南省桃江县人民政府`,
			`江西省资溪县人民政府`,
			`浙江省安吉县人民政府`,
			`浙江省庆元县人民政府`,
			`中国对外书刊出版发行中心（国际传播发展中心）`,
			`《世界竹藤通讯》杂志`,
			`《建筑结构》杂志`,
			`《可持续结构》杂志`,
			`《风景园林》杂志`,
			`遂昌竹炭博物馆`,
			`清华大学建筑设计研究院有限公司`,
			`国家林草装备科技创新园`,
			`安徽炬能炭业有限公司`,
			`安徽长思机械设备科技有限公司`,
			`安吉竹境竹业科技有限公司`,
			`承德绿世界活性炭有限公司`,
			`滁州市昌春木炭专业合作社`,
			`东莞市嘉航实业有限公司`,
			`福建明良食品有限公司`,
			`赣州森泰竹木有限公司`,
			`广东裕丰竹纤维有限公司`,
			`广东竹康材料科技有限公司`,
			`杭州邦博科技有限公司`,
			`河北吉藁化纤有限责任公司`,
			`洪雅竹元科技有限公司`,
			`湖南桃花江竹材科技股份有限公司`,
			`江苏见竹绿建竹材科技股份有限公司`,
			`江苏金梧机械有限公司`,
			`奇竹（北京）科技发展有限公司`,
			`深圳金色盆地科技有限公司`,
			`天竹联盟`,
			`熊猫碳业科技有限公司`,
			`浙江富来森竹炭有限公司`,
			`浙江佶竹生物科技有限公司`,
			`浙江圣氏生物科技有限公司`,
			`中国建筑西南设计研究院有限公司`,
			`重庆瑞竹植物纤维制品有限公司`,
			`重庆特珍食品有限公司`,
			`竹纤维产业网
`,
		],
		list1: [
			`联合国工发组织`,
			`联合国教科文组织驻华代表处`,
			`联合国粮农组织`,
			`联合国人居署`,
			`联合国贸易和发展大会`,
			`联合国森林论坛秘书处`,
			`联合国环境规划署世界保护监测中心`,
			`世界自然基金会（瑞士）北京代表处`,
			`国际标准化组织竹藤技术委员会秘书处`,
			`国际林联竹藤工作组`,
			`国际山地综合发展中心`,
			`国际竹藤组织全球竹建筑专家组`,
			`非洲联盟委员会/非洲联盟发展机构-非洲发展新伙伴计划`,
			`西班牙国际发展合作署`,
			`厄瓜多尔城市发展和住房部`,
			`加纳林业研究所`,
			`秘鲁国家森林和野生动物管理局`,
			`西班牙/葡萄牙--伊比利亚-美洲竹子协会`,
			`阿肯顿·阿皮亚·门卡大学`,
			`迪肯大学`,
			`考文垂大学`,
			`匹兹堡大学`,
			`万隆理工学院`,
			`西英格兰大学`,
			`亚的斯亚贝巴大学`,
			`加拿大不列颠哥伦比亚大学`,
			`苏黎世联邦理工学院`,
			`弗吉尼亚理工大学设计学院`,
			`国际金融公司`,
			`Bambusa工作室（西班牙）
`,
		],
	},
	img1: '国际竹藤组织图片摄影大赛',
	img2: '竹藤与可持续发展目标',
	img3: '澜湄合作国际设计大赛',
	img4: '国际竹藤组织成员国',
};