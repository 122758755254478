export default {
	langs: {
		"zh-CN": "中文",
		en: "English",
	},
	footer: {
		a: 'The Second Global Bamboo and Rattan Congress (BARC 2022) Secretariat',
		b: 'International Bamboo and Rattan Organization (INBAR)',
		c: 'No. 8, Fu Tong Dong Da Jie, WangJing Area, Chaoyang District, Beijing 100102, China',
		d: 'Website: www.barc2022.inbar.int',
		e: 'Contact: barc2022_info@inbar.int'
	},
	// 下面的value是路由的名称
	navigation: [{
			title: 'Home',
			value: 'home',
			child: []
		},
		{
			title: 'About BARC',
			value: 'About',
			child: [{
					title: 'Introduction',
					value: 'account',
				},
				{
					title: 'VIP',
					value: 'introduce',
				},
				{
					title: 'Partners',
					value: 'conference',
				},
			]
		},
		{
			title: 'Programme',
			value: 'conferenceAgenda',
			child: []
		},
		{
			title: 'Parallel Sessions',
			value: 'parallelMeeting',
			child: [{
					title: 'Parallel Sessions',
					value: 'parallelMeeting',
				},
				{
					title: 'E-Poster',
					value: 'poster',
				},
			]
		},
		{
			title: 'Participation',
			value: 'regist',
			child: [{
					title: 'Registration',
					value: 'registerMeeting',
				},
				{
					title: 'Logistics',
					value: 'transportationAndAccommodation',
				},
			]
		},
		{
			title: 'Abstracts',
			value: 'abstract',
			child: []
		},
		{
			title: 'Exhibition',
			value: 'exhibition',
			child: [{
					title: 'Exhibition',
					value: 'exhibition',
				},
				{
					title: 'INBAR Photo Competition',
					value: 'imgPages1',
				}, {
					title: 'Bamboo and Rattan for the SDGs',
					value: 'imgPages2',
				}, {
					title: 'Lancang-Mekong Design Award',
					value: 'imgPages3',
				}, {
					title: 'INBAR Member States',
					value: 'imgPages4',
				},
			]
		},
		{
			title: 'News',
			value: 'News',
			child: [{
					title: 'INBAR News',
					value: 'INBAR',
				},
				{
					title: 'BARC News',
					value: 'News',
				},
				{
					title: 'Social Media Kit',
					value: 'Social',
				},
			]
		}, {
			title: 'Contact us',
			value: 'contactUs',
			child: []
		}
	],
	home_introduction: 'Introduction',
	home_introduction_1: 'In order to promote the green economic development using bamboo and rattan and contribute to achieving the United Nations Sustainable Development Goals, the Celebration of INBAR’s 25th Anniversary and the Second Global Bamboo and Rattan Congress (BARC 2022) will be held in Beijing between 7-8 November 2022. Co-hosted by the National Forestry and Grassland Administration of China (NFGA) and International Bamboo and Rattan Organization (INBAR), this event will bring together representatives from governments, research institutes, international and non-governmental organizations, the private sector and the media, to discuss the development of bamboo and rattan. In view of the requirements of the COVID-19 pandemic, this event will be held in a hybrid format, with sessions both online and in person, with in-person participation open only to guests currently residing in mainland China.  BARC 2022 will continue the important legacy of the first Global Bamboo and Rattan Congress that was held in 2018. ',
	newTitle: 'News',
	more: 'More',
	personal_center: 'Personal Center',
	home_table: require(`@/assets/images/home/table_en.png`),
	/** 大会专题*/
	topics_title: 'Theme',
	topics_title_1: 'Thematic Area 1. The Road to Carbon Neutrality',
	topics_content_1: 'Bamboo can contribute to mitigating climate change and reaching carbon neutrality targets by storing carbon and creating low-carbon products. This thematic area will focus on bamboo’s contributions to storing carbon - either through ambitious reforestation projects, preventing deforestation, or as a source of low-carbon products - and helping vulnerable communities adapt to climate change. This theme will also include bamboo and rattan’s role in ecosystem and biodiversity restoration and forest conservation.',
	topics_title_2: 'Thematic Area 2. Green Industry and Economic Recovery',
	topics_content_2: 'This thematic area will showcase bamboo and rattan’s potential for creating sustainable livelihoods, reducing poverty, revitalising rural areas and building a green economy. Topics may include the sustainable management of bamboo and rattan resources, bamboo and rattan in agroforestry, livelihoods for smallholder farmers, small- and medium-sized enterprise development, as well as case studies of successful job creation and green supply chains, eco-tourism and cultural sectoral development using bamboo and rattan.',
	topics_title_3: 'Thematic Area 3. Innovative Materials and Market Development',
	topics_content_3: 'With the increasing global demand for bio-based and low-carbon products, bamboo and rattan can play a central role in helping develop a circular economy. This thematic area will showcase bamboo and rattan’s more innovative applications, including bamboo alternatives to plastics, timber, steel, concrete, cotton and others, their performance compared to more emissions-intensive materials and sources of biomass energy, as well as advances in food products, bio-chemical applications, products standards and certification, market access, industrial upscaling and cluster development, and future trends.',
	topics_title_4: 'Thematic Area 4. Working Together for Inclusive and Green Development',
	topics_content_4: 'This thematic area looks at the significance of international cooperation and exchanges as a way to support sustainable development with bamboo and rattan. Topics may include best practices of bamboo and rattan research projects, successful cases of South-South and triangular cooperation, capacity building, technology transfer, women and community engagement, public-private partnerships, and other multilateral initiatives which share technologies, knowledge and lessons learnt, and which have demonstrably helped to replicate and scale up investment and engagement and facilitate international trade.',
	participants_registration: 'Participants Registration',
	thesis_submission: 'Thesis Submission',
	abstract_submission: 'Abstract Submission',

	/** 合作伙伴 */
	hosts: 'Hosts',
	organisers: 'Organisers',
	partner_1: 'International Bamboo and Rattan Organization',
	partner_2: 'National Forestry and Grassland Administration of China',
	partner_3: 'International Centre for <br/> Bamboo and Rattan',
	partner_4: 'International Centre for Bamboo and Rattan',
	/** INBAR新闻 */
	INBAR_new_url: 'https://www.inbar.int/news/',
	INBAR_news: 'INBAR News',
	BARC_News: 'BARC News',
	/** 注册参会 */
	registration: 'Registration',
	registrationOverview: 'Registration Overview',
	Inpersonparticipation: 'In-person participation',
	Inp1: 'Due to the concern of COVID-19 pandemic, in-person participation is open only to guests currently residing in mainland China. The Organizing Committee will adopt the method of directional invitation. If you are interested in participation, please contact us via <span class="g-color">barc2022_info@inbar.int</span>. The registration deadline for in-person participation is <span class="g-color">15 October 2022 at 17:00 (GMT+8)</span>. ',
	Inp2: 'Due to the ceiling number of in-person participation, a “first come, first served” policy will apply. The in-person registration will be closed once it reaches the ceiling number. ',
	virtualAttendance: 'Virtual Attendance Registration',
	// vip1: 'It is possible to join the Congress virtually and free of charge by registering online. The registration deadline for virtual attendance is <span class="g-color">8 October 2022 at 17:00 (GMT+8)</span>.For frequently asked questions on virtual attendance, please see <span class="a-color">here</span>.',	
	vip1: 'It is possible to join the Congress virtually and free of charge by registering online. The registration deadline for virtual attendance is ',
	viptime: '20 October 2022 at 17:00 (GMT+8)',
	vip11: 'For frequently asked questions on virtual attendance, please see ',
	here: 'Here',
	vip2: 'All registration deadlines are Beijing time (GMT+8).',
	registrationFee: 'Registration Fee',
	rep1: 'Registration fee only applies to in-person attendance.',
	rep2: 'Registration applies a fixed-price payment of RMB 1500 per person.',
	rep3: 'Online registration payments should be made via WeChat only. If you are unable to pay by WeChat, please email',
	rep4: 'All payments should be made in Chinese Yuan (RMB) and no VAT applies.',
	registrationBenefits: 'Registration Benefits',
	regp1: 'Upon registering for the Second Global Bamboo and Rattan Congress (BARC 2022), you agree to the terms and conditions of registration. Your registration will be complete when you pay the registration fee and receive the confirmation email.',
	regp2: '※ Only an electronic official receipt will be issued for online registration in 10 working days after the Congress.',
	registrationCancellationPolicy: 'Registration Cancellation Policy',
	registp1: 'If you cancel your registration, you will receive a refund in accordance with the criteria specified below. Refunds will be made no later than 30 days after the date of cancellation. You will be responsible for paying any bank-handling fees incurred.',
	registp2: 'Cancellation for personal reasons',
	registp3: 'If you wish to cancel your registration due to personal reasons, please apply for cancellation and refund at the <span class="a-color">Personal Center</span>.',
	registp4: 'The refund criteria are as follows:',
	registp5: 'Cancellation due to COVID-19',
	registp6: 'If you cannot attend BARC 2022 because you are denied departure/entry or isolated during the quarantine process, test positive for COVID-19, or are involved in related matters, please apply for cancellation and upload supporting documents at the <span class="a-color">Personal Center</span> .',
	privacyPolicy: 'Privacy Policy',
	prip1: 'The Hosts take privacy seriously and strive to protect it. Personal information collected through the online registration procedure is processed for such purposes as identification, authentication, confirmation, and fraud prevention in connection with the registrant’s access to BARC 2022.  Participants are deemed to agree to the collection, use, and processing of their personal information in order to participate in the event. Photos and videos will be taken at BARC 2022 venues for documentation and may be published on BARC 2022 website or official social media channels. Please refer to the privacy policy on the registration system and go through the consent process. ',
	dataProtection: 'Data Protection',
	datap1: 'Registrants are deemed to agree that the registration support team may process their personal information for registration and management purposes and send them information on BARC 2022 and INBAR. Registration is not completed if mandatory fields are not entered, and the registrants can access and correct their personal data. Please contact the registration support team via email (<span class="g-color">barc2022_info@inbar.int</span>) for such access and corrections.',
	insurance: 'Insurance',
	insp1: 'Participants are advised to take out a travel insurance policy individually.',
	limitationofLiability: 'Limitation of Liability',
	limip1: '1. The Hosts reserve the right to cancel or postpone BARC 2022, or change its programme or its time or place, in the event of force majeure events beyond the Hosts’ control such as the natural disasters, wars, terrorist acts, or omission. In the event of the cancellation or postponement of BARC 2022, the Hosts will not be liable for any other damage, expenses, or losses suffered by the participants. All registrants will be notified of any such cancellation or postponement via the email accounts specified on their registration forms.',
	limip2: '2. The Hosts will not take responsibility for the death, injuries, damage, losses, costs, or expenses of participants, their companions, their caregivers, etc., and in particular, losses suffered due to the provision, or lack thereof, of services by hotels or transportation companies.',
	limip3: '3. The Hosts will not take responsibility for the death, injuries, damage, losses, costs, or expenses of participants, their companions, their caregivers, etc., and in particular, losses suffered due to the provision, or lack thereof, of services by hotels or transportation companies.',
	mediaRegistration: 'Media Registration',
	mediap1: 'BARC 2022 welcomes the attendance of coverage by journalists and photographers from print and online media outlets, TV and radio stations, and news agencies. Media friends based in China mainland are warmly welcome to register.',
	mediap2: 'There is no registration fee for media personnel.',
	mediap3: 'For registration and participation in BARC 2022, media personnel are required to send ',
	mediap33: '<span style="font-weight:700"  class="g-color">a valid press card or an official written certification from the media</span>',
	mediap333: ' for approval to <span class="g-color">media@inbar.int</span> <span class="g-color">by the end of 20 October 2022</span> with ‘BARC2022 Media Registration’ in the subject line. Additional documentation may be requested for further clarification. You will be invited for offical registration after your application has been approved in due course by the BARC 2022 Organising Committee.',
	mediap4: 'Documents required for Media Registration',
	mediap5: 'A valid press card or an official written certification from the media',
	mediap6: 'One 1-inch ID photo',
	mediap7: 'One 1-inch ID photo',
	mediap8: 'Thanks for your interest and we look forward to seeing you in Beijing.',
	inquiries: 'Inquiries',
	inqup1: 'Requires on other registration-related matters, please contact <span class="g-color">barc2022_info@inbar.int</span>.',
	/** 注册参会 */
	/** 交通住宿 start */
	beijingCenterHotels: 'Hotels Near BICC (Beijing International Convention Center)',
	address: 'Address',
	tel: 'Hotel Tel',
	website: 'Reservation',
	distancetoBICC: 'Distance to BICC',
	distancetoCNCC: 'Distance to CNCC',
	pr: 'Zip Code',
	fj: "Reservation Form as attached",
	lxr: 'Contact person',
	dh: ' Tel',
	mainAccommodation: 'Beijing Continental Grand Hotel 4*',
	mainAccommodationAddress: 'No.8 Beichen Dong Road, Chaoyang District, Beijing ',
	mainAccommodationpr: '100101',
	mainAccommodationTel: '+86 10 8498 0105',
	mainAccommodationWebsite: 'https://www.bcghotel.com.cn/english/order.asp?id=2532',
	mainAccommodationDistancetoBICC: `Interconnected to BICC, 5 mins' walk `,
	mainAccommodationInfos: "The Beijing Continental Grand Hotel is located in the flourishing Yayuncun area along Beijing's North Fourth Ring Road, where the central axis of the city meets the Fourth Ring Road, and right next to national stadiums like the Bird's Nest and the Water Cube. It's a 25km trip east to the Beijing Capital International Airport, 68 kilometers from the Daxing International Airport, a 9km journey south to Tian'anmen Square, a 10km excursion west to the Summer Palace, and an 80km sojourn north to the Badaling section of the Great Wall. And with the Olympic Village only a stone's throw away, there is no better location in the city from which to base your business trip.",
	wuzhouHotel: 'V-Continent Beijing Parkview Wuzhou Hotel 5*',
	wuzhouHotelAddress: 'No. 8 North Si Huan Zhong Road, Chaoyang District, Beijing ',
	wuzhouHotelPr: '100101 ',
	wuzhouHotelTel: '+86- 10 8498 2288 ',
	wuzhouHotelToCNCC: '35 mins walk or 8 mins’ drive/2.1km ',
	wuzhouHotelToBICC: `ins' walk`,
	wuzhouHotelInfos: `V-Continent Beijing Parkview Wuzhou Hotel is the flagship hotel of Beijing North Star V-Continent Hotel Management Company. A deluxe hotel conveniently located in core area of Asian Olympic business district of the north Fourth Ring Road Beijing, adjacent to Beijing International Convention Center，China National Convention Center, and within walking distance to the city’s Olympic Green and much celebrated Bird’s Nest Stadium. The hotel is just 25-minutes from Beijing Capital International Airport. It's pretty convenient to go Tiananmen Square, Forbidden City, the Summer Palace, Silk Street and other high streets and scenic spots, an ideal place for business and leisure travelers. `,
	dinganglianjie: 'Please download and fill in the <a class="a-color" target="_blank" href="https://www.barc2022.inbar.int/barcfile/北京北辰五洲皇冠国际酒店预订单 中英文(2)(1)(1)(1).xlsx">Reservation Form</a>, then send the form to phoebe.xu@v-continent.com',
	xufang: 'Phoebe ',
	xufd: '+86- 13811450011',
	huiyuanHotel: 'Huiyuan Apartment Hotel (VIP building) 5*',
	huiyuanHotelAddress: 'No. 8 Beicheng East Road, Andingmenwai, Chaoyang District',
	huiyuanHotelPr: '100101',
	huiyuanHotelTel: '+86-10 6499 1818',
	huiyuanHotelWebsite: 'https://www.huiyuangongyu.com.cn/',
	huiyuanHotelToBICC: '15 mins walk or 4 mins’ drive /720m',
	huiyuanHotelInfos: "The Huiyuan Prime Hotel (Beijing Huiyuan Jiudian Gongyu) is located in Beijing's Asian Games Village, close to the Bird's Nest National Stadium and Zhongguan Village. From the hotel, Beijing Capital Airport is just a ten-minute drive away and Metro Lines 5 and 10 are both close by. Dining facilities in this Beijing hotel include a Chinese restaurant, a cafe, bar and a lobby bar. Those in the capital on business can take advantage of a number of variously sized meeting rooms.",
	yayuncunHotel: 'Yayuncun Hotel 3*',
	yayuncunHotelAddress: 'No. 8 Beicheng East Road, Chaoyang District, Beijing',
	yayuncunHotelTel: '+86-10 6499 1199',
	yayuncunHotelWebsite: 'https://www.huiyuangongyu.com.cn/e/order/en_morder.php?id=242',
	yayuncunHotelToBICC: `15 mins' walk or 5 mins’ drive/850 m`,
	yayuncunHotelInfos: 'Yayuncun Hotel is located in the Asian Games Village, one street away from the Olympic Village. At present, there are three apartment buildings, with different guest rooms, ranging from one room to four rooms, from flat to duplex, standard or luxury, able to meet the needs of guests. Rooms are equipped with sofas, desks, dining tables and chairs, telephones and broadband networks; kitchens in suites are equipped with kitchenware, refrigerators and washing machines. They have got television channels such as CNN, HBO, AXN.',
	provideTimely: 'To be provided in due course',
	/** 交通住宿  end */
	/** 论文摘要  start*/
	abstracts: 'Abstracts',
	secondGlobalBamboo: 'The Second Global Bamboo and Rattan Congress',
	callForAbstracts: 'Abstracts',
	abstractsTime: '“Bamboo and Rattan: Nature-Based Solutions for Sustainable Development”',
	absp1: 'One of the goals of organising the Second Global Bamboo and Rattan Congress (BARC 2022) is to present the state-of-the-art scientific research and technology and recent development related to bamboo and rattan. Therefore, the BARC 2022 Organising Committee now invites submission of abstracts that should be relevant to one of the following thematic areas: ',
	absp2: 'Thematic Area 1. The Road to Carbon Neutrality',
	absp3: 'Bamboo can contribute to mitigating climate change and reaching carbon neutrality targets by storing carbon and creating low-carbon products. This thematic area will focus on bamboo’s contributions to storing carbon - either through ambitious reforestation projects, preventing deforestation, or as a source of low-carbon products - and helping vulnerable communities adapt to climate change. This theme will also include bamboo and rattan’s role in ecosystem and biodiversity restoration and forest conservation.',
	absp4: 'Thematic Area 2.  Green Industry and Economic Recovery',
	absp5: 'This thematic area will showcase bamboo and rattan’s potential for creating sustainable livelihoods, reducing poverty, revitalising rural areas and building a green economy. Topics may include the sustainable management of bamboo and rattan resources, bamboo and rattan in agroforestry, livelihoods for smallholder farmers, small- and medium-sized enterprise development, as well as case studies of successful job creation and green supply chains, eco-tourism and cultural sectoral development using bamboo and rattan.',
	absp6: 'Thematic Area 3. Innovative Materials and Market Development',
	absp7: 'With the increasing global demand for bio-based and low-carbon products, bamboo and rattan can play a central role in helping develop a circular economy. This thematic area will showcase bamboo and rattan’s more innovative applications, including bamboo alternatives to plastics, timber, steel, concrete, cotton and others, their performance compared to more emissions-intensive materials and sources of biomass energy, as well as advances in food products, bio-chemical applications, products standards and certification, market access, industrial upscaling and cluster development, and future trends.',
	absp8: 'Thematic Area 4. Working Together for Inclusive and Green Development',
	absp9: 'This thematic area looks at the significance of international cooperation and exchanges as a way to support sustainable development with bamboo and rattan. Topics may include best practices of bamboo and rattan research projects, successful cases of South-South and triangular cooperation, capacity building, technology transfer, women and community engagement, public-private partnerships, and other multilateral initiatives which share technologies, knowledge and lessons learnt, and which have demonstrably helped to replicate and scale up investment and engagement and facilitate international trade.',
	absp10: 'Why participate',
	absp11: '1. Following review, accepted abstracts will be published online and a printed Compendium of Abstracts will be distributed at BARC 2022. ',
	absp12: '2. Selected abstracts / authors will be invited to deliver an oral presentation at BARC 2022.',
	absp13: '3. Selected authors will be invited to prepare a poster for poster presentation at BARC 2022.',
	absp14: 'Important Dates for Abstracts Submission',
	absp15: 'Note',
	absp16: '1. Submitted abstracts will be sent to the BARC 2022 Review Committee for peer review and blind scoring. Notification of abstracts acceptance will be circulated to authors, based on the review results.',
	absp17: '2. Submission of an abstract implies consent by the authors to have the abstract published by the Congress.',
	absp188: 'Submission',
	absp18: 'Please click <a class="a-color" target="_blank" href="https://barc2022.chinafuturelink.com/barc/link.html"> here </a> to submit abstract no later than 20 September 2022.',
	absp199: `The call for abstracts is closed on September 20, 2022. If you have submitted an abstract, please click on <a class="a-color" target="_blank" href="https://barc2022.chinafuturelink.com/barc">personal center</a> to view it.`,
	absp19: 'All abstracts should be submitted using the enclosed <a class="a-color" href="https://www.barc2022.inbar.int/barcfile/BACR 2022 Abstract Template.docx">Abstract Submission Form</a>.',
	absp20: '<span class="g-color">Only abstracts in English will be accepted</span> (Translation in Chinese can be submitted on voluntary basis).',
	adsp21: 'For comprehensive submission guidelines, formatting style and submission form please refer to the official BARC 2022 website at <a class="a-color" href="www.barc2022.inbar.int">www.barc2022.inbar.int</a>.  ',
	adsp22: 'Requires on abstracts submission matters, please contact <span class="g-color"> barc2022_abstracts@inbar.int</span>.',
	adsp23: 'We look forward to receiving your abstracts and welcome your contribution to BARC 2022!',
	/** 论文摘要  end*/
	/** 常见问题  start*/
	faq: 'FAQs',
	faq1: '1. How can I participate in the Congress online? ',
	faw1: 'A live streaming function will be made available on the official website of the Second Global Bamboo and Rattan Congress. You can watch the live stream via a computer or mobile phone. ',
	faq2: '2. What internet speed do I need to participate in the Congress online?',
	faw2: 'If you want to participate in the Congress online, including watching the live streams, we suggest a minimum internet speed of 2MB/s. You can check your connection by running an internet speed test, which will provide you with data about download and upload speeds. If the internet connection is poor, we recommend you switch your connection in good time in order to participate fully in the online Congress.',
	faq3: '3. What should I do if the platform cannot load? ',
	faw3: 'A modern and up-to-date browser is required for the best experience. Compatible browsers include Google Chrome, Firefox, Safari, and Edge. Try to refresh the page and clear the cache if you still cannot access the platform.',
	faq4: '4. Is there any mobile app for the Congress? ',
	faw4: 'No. An online digital platform is provided for this Congress. No software is required: you can access it by clicking a link to open the website, using your mobile phone or computer.',
	/** 常见问题  end*/
	/** 联系我们 start */
	contactUs: 'Contact Us',
	contactUsp1: 'For more questions, please contact: ',
	contactUsp2: '</>General Information: <span class="g-color">barc2022_info@inbar.int</span>',
	contactUsp3: 'Parallel Sessions Application: <span class="g-color">barc2022_sessions@inbar.int</span>',
	contactUsp4: 'Abstract Submissions: <span class="g-color">barc2022_abstracts@inbar.int</span>',
	contactUsp5: 'Registration: <span class="g-color">barc2022_info@inbar.int</span>',
	contactUsp6: 'Exhibitions: <span class="g-color">tli@inbar.int</span>',
	contactUsp7: 'Medias: <span class="g-color">media@inbar.int</span>',
	contactUsp8: 'Transportation and Accommodation: <span class="g-color">barc2022_services@inbar.int</span>',
	/** 联系我们 end */
	/** 竹藤展览 start*/
	bambooExhibition: 'Exhibition',
	introduction: '1. Introduction',
	bambooExhibitionP1: 'Co-hosted by the International Bamboo and Rattan Organization (INBAR) and the National Forestry and Grassland Administration of China (NFGA), the Second Global Bamboo and Rattan Congress (BARC 2022) will be held on 7-8 November 2022 in Beijing, China. During BARC 2022, a bamboo and rattan product exhibition will be held to display a wide range of high-quality products, advanced technologies and the latest scientific research achievements in the field of bamboo and rattan, including bamboo and rattan furniture, building materials, handicrafts, daily necessities, fibre, charcoal and other related products and technologies. The exhibition will also display the exquisite skills of bamboo and rattan craftsmen on site for guests to experience and enjoy the cultural charms of the magical plants.',
	bambooExhibitionP2: '2. Date and Venue',
	bambooExhibitionP3: '3. Booth Arrangement',
	bambooExhibitionP4: 'The BARC 2022 Exhibition will be held in the Fourth Conference Hall of the Beijing International Convention Center with an exhibition area of 1500 square meters, which can accommodate 55 standard booths . Please refer to the red-line circled area of the plan.',
	bambooExhibitionP5: '4. Exhibition Fees and Facilities',
	bambooExhibitionP6: '1）INBAR provides exhibitors with free standard booths. If you need special booths or large-area booths, please contact the Exhibition Team.',
	bambooExhibitionP7: '2）Exhibitors shall pay the booth setup fee, which is 950 yuan for each standard booth (3 x 3M). If you have any other special requirements, please contact the Exhibition Team.',
	bambooExhibitionP8: 'Standard booth facilities include:',
	bambooExhibitionP9: 'Setup of the booth with 9 square meters of floor space',
	bambooExhibitionP10: 'Booth Lintel',
	bambooExhibitionP11: '1 booth table ',
	bambooExhibitionP12: '2 chairs',
	bambooExhibitionP13: '2 power sockets (5a, 220V) ',
	bambooExhibitionP14: '1 trash can',
	bambooExhibitionP15: '3）Example',
	bambooExhibitionP16: '4）The pictures of the back and side walls of the booth need to be provided by the exhibitors. The service provider authorised by the Exhibition Team can print and make all the pictures on site, but the costs should be covered by the exhibitors. The material is removable glued board or KT board. If the service is needed, please submit the documents to be printed (AI format) to the Exhibition Team no later than 30 September 2022.',
	bambooExhibitionP17: '5）No storage is rented for the exhibition. If there are large exhibits to be displayed, the exhibitors are requested to arrange storage and logistics by themselves.',
	bambooExhibitionP18: '6）All the fees need to be paid no later than 30 September 2022. For details, please contact the Exhibition Team. ',
	bambooExhibitionP19: '5. Exhibition Request Form',
	bambooExhibitionP20: 'The completed <a class="a-color">Exhibition Request Form </a> shall be sent to Ms. Li Ting at <span class="g-color">tli@inbar.int</span> no later than 30 August 2022.',
	bambooExhibitionP21: '4. Contact ',
	bambooExhibitionP22: '<span class="dot"></span>Host Country Director: Dr. Fu Jinhe',
	bambooExhibitionP23: 'Mob.: <span class="g-color">136 4123 4213</span>；',
	bambooExhibitionP24: 'Email: <span class="g-color">jfu@inbar.int</span>',
	bambooExhibitionP25: 'Host Country Partnership Manager: Ms. Li Ting',
	bambooExhibitionP26: 'Mob.: <span class="g-color">139 1002 3820</span>；',
	bambooExhibitionP27: 'Email: <span class="g-color">tli@inbar.int</span>',
	poster: {
		b: 'E-Poster',
		list: [
			`Carbon and water dynamics of Moso bamboo during its explosive growth period`,
			`The investigation and research of bamboo resources in Danxia mountain`,
			`Investigation on culm form characteristics of Phyllostachys edulis ‘Yuanbao’`,
			`Seed germination and seedling growth of Dendrocalumus brandisii in vitro, and the inhibitory mechanism of colchicine`,
			`The leaf phenotypic variation of the 12 bamboo species and their relationship with environmental factors`,
			`The biomass of understory vegetation in Qiongzhuea tumidinoda forest showed the optimal allocation with the increase of soil layer thickness`,
			`Variation of Phyllostachys propinqua leaf C, N, and P stoichiometry in Beijing-Tianjin-Hebei urban agglomeration`,
			`Physiological and biochemical characteristics of Phyllostachys heteroclada in response to waterlogging stress `,
			`Study of long-term degradation behavior of bamboo scrimber under natural weathering`,
			`Study on the law of nutrient requirement and control technology of Neosinocalamus affinis`,
			`The effect of bamboo extract on hepatic biotransforming enzymes – findings from an obese–diabetic mouse model`,
			`Inhibitory effect of bamboo leaf extract on breast cancer`,
			`Bamboo leaf extract inhibited MCP-1 over secretion in obesity and Ⅱ diabetes mellitus`,
			`Comprehensive analyses and development of simple sequence repeats (SSRs) based on the whole-genome sequences of five bamboo species`,
			`Integrative analyses of morphology, physiology, and transcriptional expression profiling reveal miRNAs involved in culm color in bamboo`,
			`Carbohydrate metabolism and its regulation are the main factors affecting the quality of postharvest bamboo shoots`,
			`Nitrogen fertilization in bamboo forest accelerates the shoot growth and alters the lignification process in shoots`,
			`Morphological characteristics and transcriptome comparisons of the shoot buds from flowering and non-flowering Pleioblastus pygmaeus`,
			`An efficient genetic transformation and CRISPR/Cas9-based genome editing system for Moso Bamboo (Phyllostachys edulis)`,
			`Pyrolysis characteristics and gaseous products of bamboo shoot shells in N2 and CO2 atmospheres`,
			`Effect of pyrolysis heating rates on fuel properties of molded charcoal: Imitating industrial pyrolysis process`,
			`An environmentally friendly and efficient method to improve acoustic vibration performance of bamboo for musical instruments: Nitrogen-protected heat treatment`,
			`How bamboo service hubs could accelerate bioenergy transition in Africa`,
			`Understand bamboo sectoral cluster models to support regional sustainable investment-finance `,
			`Adaptation of bamboo and rattan to climate change (CC) and carbon neutrality in south western Cameroon (SWC)`,
			`Bamboo as a nature-based solution to climate change mitigation:biomass, products, and carbon credits`,
			`Research on the redesign method of traditional bamboo furniture in Western Hunan Province`,
			`Zhejiang Anji bamboo industry machinery and equipment development status and policy measures`,
			`Study on cutting methods of Moso bamboo`,
			`Research and Application of Habitat Bamboo Landscape`,
			`Bamboo green gold perspective`,
			`Study on the promotion system of bamboo forest therapy and cultural-tourism`,
			`Evaluation of bamboo forest ecosystem services in Feicheng City`,
			`Growth room for China bamboo trade in the international market of the BRI countries`,
			`Sustainability and innovation of bamboo winding composite pipe products`,
			`Extending the utilization of bamboo, promoting "the bamboo as a substitute for plastic initiative"-- an analysis of R&D and production of bamboo in outdoor leisure products of Zhejiang Hengfeng Top Leisure Co. Ltd.`,
			`Ecological culture leads the realization path of "substituting bamboo for plastic"`,
			`Diverse biomaterials from bamboo argentine`,
			`Bamboo fiber-reinforced epoxy resin composites prepared by vacuum-assisted resin transfer molding process: effect of fiber morphology`,
			`Chemical constituents analysis reveals the effect of artificial shading on reducing the bitter taste of bamboo shoots`,
			`Inhibitory activity of bamboo leaf flavonoids against FabG`,
			`Application of modified atmosphere packaging and biological preservatives in the preservation of TianLong bamboo (Dendrocalamus brandisii) shoots`,
			`Chemical composition and biological activity of pressurized hot water extracts from Moso bamboo (Phyllostachys pubescens)Culms`,
			`Efficient separation and structural changes of primary lignin in bamboo at different growth stages`,
			`The microstructure and lignin distribution of parenchyma cell wall in developmental bamboo culm`,
			`Determination of chemical changes in bamboo due to degradation by brown and white rot fungi`,
			`Quantitative structural analysis of bamboo lignin during p-TsOH Pretreatment`,
			`Simultaneous determination of 25 phenolic compounds in extracts of Bambusa Chungii bamboo culm by UPLC-Q-TOF-MS`,
			`Graded variations in topochemistry and ultrastructure of rattan cane (Calamus simplicifolius)`,
			`3D characterization of vascular bundle in Moso bamboo node and its effect on mechanical properties`,
			`In situ synthesis of mesoporous carbon-encapsulated iron nanoparticles derived from rattan for efficient adsorption`,
			`Bamboo-based wind turbine blade technology and its application prospect in bamboo industrialization`,
			`Bamboo-inspired cell-scale assembly for energy device applications`,
			`Long bamboo fibers in the innovative design`,
			`Facile preparation of chitosan-based composite film with good mechanical strength and flame retardancy`,
			`Biochemical changes of bamboo (Fargesia yunnanensis) shoots during storage and the related cold storage mechanisms`,
			`Three-dimensional visualization of the conducting tissue in a bamboo culm base`,
			`Engineering organosolv lignin into reactive prepolymers for adhesive purpose`,
			`Pyrolysis self-activation in vacuum: An environmentally friendly method to transform biowaste into activated carbon and combustible gas`,
			`Highly acetylated lignocellulose synthesized from Neosinocalamus affinis pretreated by γ- valerolactone/water.`,
			`Determination of amino acids in bamboo juice by UPLC coupled with pre-column derivatization`,
			`A novel mechanical kiln for bamboo molded charcoals manufacturing`,
			`A comprehensive study of lignocellulosic nanofilm from bamboo via aicd hydrotropic fractionation`,
			`Research progress on extraction and depolymerization of bamboo hemicellulose：A Review`,
			`Fabrication technologies of dimensional flattened bamboo lumber`,
			`Microfibril orientation of the secondary cell wall in parenchyma cells of Phyllostachys edulis culms`,
			`Production and characterization of long bamboo fibre composites for high performance applications`,
			`Effect of bending on radial distribution density, MFA and MOE of bent bamboo`,
			`A novel bamboo engineering material processed by flattening and densification`,
			`The role of bamboo pith ring in gluing interface`,
			`Effect of hot-pressing temperature on water resistance of bamboo scrimber from macro to micro`,
			`Cellulose nanofibril/PVA/bamboo activated charcoal aerogel sheet with excellent capture for PM2.5 and thermal stability`,
			`Preparation of ultra-small CDs from common lophatherum herb and its fluorescence mechanism and application in anti-counterfeiting and cell imaging`,
			`Study on mechanical performance and construction technology of the rectangular steel tube frame-bamboo grid shear wall structure`,
			`Construction technology of large-span round bamboo arched structure —case analysis of Bamboo and Rattan Pavilion of International Horticultural Beijing Exposition`,
			`“Bamboo&Garden&Art”——Practice of Bamboo and Rattan Garden in the 10th China Flower Expo`,
			`Research and practice on the modle construction of bamboo scenery line `,
		],
	},
	/** 竹藤展览 end*/
	Dhsynopsis: { //大会简介
		title: 'Introduction',
		h1: 'The Second Global Bamboo and Rattan Congress',
		p1: 'Bamboo, the fast-growing grass plant, and rattan, the spiky climbing palm, are both important forest resources that have been closely linked with human lives, livelihoods and material cultures since ancient times. Bamboo and rattan resources are widely distributed across the world, mainly in tropical and subtropical areas, and provide unique ecological, economic and cultural services. They can help solve a series of global challenges and play an important role in developing a green economy, addressing climate change, building disaster-resilient infrastructure, alleviating poverty, revitalising rural areas, and protecting the environment. In recent years, bamboo and rattan have become an important part of the international sustainable development conversation, as key tools for promoting South-South cooperation  and contributing to the United Nations 2030 Sustainable Development Goals.',
		p2: 'In order to promote the green economic development using bamboo and rattan and contribute to achieving the United Nations Sustainable Development Goals, the Celebration of INBAR’s 25th Anniversary and the Second Global Bamboo and Rattan Congress (BARC 2022) will be held in Beijing between 7-8 November 2022. Co-hosted by the National Forestry and Grassland Administration of China (NFGA) and International Bamboo and Rattan Organization (INBAR), this event will bring together representatives from governments, research institutes, international and non-governmental organizations, the private sector and the media, to discuss the development of bamboo and rattan. In view of the requirements of the COVID-19 pandemic, this event will be held in a hybrid format, with sessions both online and in person, with in-person participation open only to guests currently residing in mainland China.',
		p3: 'BARC 2022 will continue the important legacy of the first Global Bamboo and Rattan Congress that was held in 2018. ',
		h2: 'Contents',
		h3: 'Theme of BARC 2022',
		h4: `BARC 2022, with the theme of “Bamboo and Rattan — Nature-based Solutions for Sustainable Development”, will explore new opportunities and create new platforms for bamboo and rattan to support the sound development of the bamboo and rattan sectors and contribute to achieving carbon neutrality. BARC 2022 will include opening and closing ceremonies, Ambassadors’ Dialogues, keynote plenary session, parallel sessions, product exhibitions and more, and an important new initiative on Bamboo as a Substitute for Plastics will be jointly launched by China and INBAR at the opening ceremony.`,
		h5: 'BARC 2022 will host parallel sessions in four thematic areas:',
		h6: 'Thematic Area 1. The Road to Carbon Neutrality',
		h7: 'Bamboo can contribute to mitigating climate change and reaching carbon neutrality targets by storing carbon and creating low-carbon products. This thematic area will focus on bamboo’s contributions to storing carbon - either through ambitious reforestation projects, preventing deforestation, or as a source of low-carbon products - and helping vulnerable communities adapt to climate change. This theme will also include bamboo and rattan’s role in ecosystem and biodiversity restoration and forest conservation. ',
		h8: 'Thematic Area 2. Green Industry and Economic Recovery',
		h9: 'This thematic area will showcase bamboo and rattan’s potential for creating sustainable livelihoods, reducing poverty, revitalising rural areas and building a green economy. Topics may include the sustainable management of bamboo and rattan resources, bamboo and rattan in agroforestry, livelihoods for smallholder farmers, small- and medium-sized enterprise development, as well as case studies of successful job creation and green supply chains, eco-tourism and cultural sectoral development using bamboo and rattan.',
		h10: 'Thematic Area 3. Innovative Materials and Market Development',
		h11: 'With the increasing global demand for bio-based and low-carbon products, bamboo and rattan can play a central role in helping develop a circular economy. This thematic area will showcase bamboo and rattan’s more innovative applications, including bamboo alternatives to plastics, timber, steel, concrete, cotton and others, their performance compared to more emissions-intensive materials and sources of biomass energy, as well as advances in food products, bio-chemical applications, products standards and certification, market access, industrial upscaling and cluster development, and future trends. ',
		h12: 'Thematic Area 4. Working Together for Inclusive and Green Development',
		h13: 'This thematic area looks at the significance of international cooperation and exchanges as a way to support sustainable development with bamboo and rattan. Topics may include best practices of bamboo and rattan research projects, successful cases of South-South and triangular cooperation, capacity building, technology transfer, women and community engagement, public-private partnerships, and other multilateral initiatives which share technologies, knowledge and lessons learnt, and which have demonstrably helped to replicate and scale up investment and engagement and facilitate international trade. ',
		h14: 'Bamboo and Rattan Products Exhibition',
		h15: 'During BARC 2022, a bamboo and rattan products exhibition will be held online to display high-quality products, advanced technologies and the latest scientific research achievements in the field of bamboo and rattan, including bamboo and rattan furniture, building materials, handicrafts, daily necessities, fibre, charcoal and other related products and technologies. The exhibition will also display the superb skills of bamboo and rattan craftsmen for guests to enjoy and experience.',
		zhuban: 'Hosts',
		z1: 'International Bamboo and Rattan Organization ',
		z2: 'Established in 1997, the International Bamboo and Rattan Organization (INBAR) is an intergovernmental development organization that promotes environmentally sustainable development using bamboo and rattan. It is currently made up of 48 Member States. In addition to its Secretariat Headquarters in China, INBAR has five Regional Offices in Cameroon, Ecuador, Ethiopia, Ghana and India.Since its establishment, INBAR has been active and effective in different fields such as protecting the environment, alleviating poverty and developing a sustainable source of livelihoods. Over the past 25 years, INBAR has become a key development platform for South-South cooperation and South-North dialogue, and has made impressive contributions to the United Nations’ 2030 Agenda for Sustainable Development.',
		z3: 'For more information, please visit the INBAR website: ',
		z4: 'www.inbar.int',
		z5: 'National Forestry and Grassland Administration of China',
		z6: 'The National Forestry and Grassland Administration of China (NFGA) is the government department in charge of forestry and grassland work. Its main responsibilities are to supervise and manage the development, utilisation and protection of forests, grasslands, wetlands, deserts and terrestrial wildlife resources, organise ecological protection and restoration work, carry out afforestation and greening, and manage various natural reserves such as national parks.',
		z7: 'For more information, please visit the NFGA website:',
		z8: 'www.forestry.gov.cn',
		cenban: 'Organisers',
		c1: 'International Bamboo and Rattan Organization ',
		c2: 'International Centre for Bamboo and Rattan ',
		c3: '',
		c4: 'The International Centre for Bamboo and Rattan (ICBR) is a non-profit research institution affiliated to the NFGA. It was established in 2000 after joint approval from the State Commission Office for Public Sector Reform, the Ministry of Finance, and the Ministry of Science and Technology. Its mission is to build direct support for, and cooperate with, INBAR, which is the first intergovernmental development organization to be headquartered in China. ICBR helps to better fulfil the Host Country Agreement of INBAR, and to promote the sustainability of bamboo and rattan sector development.',
		c5: ' For more information, please visit the ICBR website: ',
		c6: 'www.icbr.ac.cn',

	},
	introduce: { //嘉宾介绍
		title: 'Guests Introduction',
		t1: 'In order to promote the green economic development using bamboo and rattan and contribute to achieving the United Nations Sustainable Development Goals, the Second Global Bamboo and Rattan Congress (BARC 2022) will be held at the Beijing International Convention Centre on 7-8 November 2022. Co-hosted by the International Bamboo and Rattan Organization (INBAR) and the National Forestry and Grassland Administration of China (NFGA), BARC 2022 will bring together representatives from governments, research institutes, international and non-governmental organizations, the private sector and the media, to discuss the development of bamboo and rattan.',
		gj: 'Nationality: ',
		zw: 'Profession: ',
		r1: 'Prof. Jiang Zehui (Co-Chair and Acting Chair)',
		r2: 'Chinese',
		r3: 'Vice-Chair, Committee of Population, Resources & Environment, the 9th to 12th CPPCC National Committee, China',
		r4: 'Prof. Liu Shirong',
		r5: 'Chinese',
		r6: 'Former President, Chinese Academy of Forestry',
		r7: 'Prof. Lyu Jianxiong',
		r8: 'Chinese',
		r9: 'Professor, Research Institute of Wood Industry, Chinese Academy of Forestry',
		r10: 'H.E. Mr. Martin Mpana',
		r11: 'Cameroonian',
		r12: 'Ambassador of Cameroon to China',
		r13: 'Mr. Ali Mchumo',
		r14: 'Tanzanian',
		r15: 'Director General, INBAR',
		r16: 'Prof. Lu Wenming',
		r17: 'Chinese',
		r18: 'Deputy Director General, INBAR',
		r19: 'Dr. Fei Benhua',
		r20: 'Chinese',
		r21: 'Director General, International Centre for Bamboo and Rattan (ICBR)',
		r22: 'Prof. Wu Yiqiang',
		r23: `Chinese`,
		r24: `President of Central South University of Forestry and Technology, Academician of Chinese Academy of Engineering, "Chang Jiang Scholars Program" distinguished professor, Academician of International Academy of Wood Science`,
		r25: `Dr. Pablo van der Lugt`,
		r26: `Dutch`,
		r27: `Architectural Engineer at Technical University Delft, World Bamboo Ambassador `,
		r28: `Prof. Yin Weilun`,
		r29: `Chinese`,
		r30: `Academician of Chinese Academy of Engineering, Former President of Beijing Forestry University, China`,
		r31: `Christophe Besacier`,
		r32: ``,
		r33: `Senior Forestry Officer, Senior Forestry Officer, Coordinator of the Forest and Landscape Restoration Mechanism, FAO`,
		end: 'INBAR Member States',
		map: require(`@/assets/images/accunt/map1.png`),
	},
	conference: { //会场信息
		title: 'Venue',
		title1: 'Partners',
		t1: 'Beijing International Convention Center (BICC)',
		t2: 'The Second Global Bamboo and Rattan Congress will be held at Beijing International Convention Center (BICC). BICC is a well-known venue in Beijing which has served almost 1000 various international and domestic conventions, exhibitions and meetings each year since its opening in 1990.',
		t3: 'BICC is situated on the site of the Asian Games Village, a flourishing area of Beijing with conference centers, office buildings, shopping centers and entertainment venues. It is located on the North Fourth Ring road, just 25 kilometers from the International Capital Airport, 68 kilometers from the Daxing International Airport and 9 kilometers from the city center. BICC is also very close to the Olympic Games central area where the famous Bird’s Nest is located.',
		t4: 'BICC, which comprises of 48 different conference rooms with an exhibition hall of 5000 square meters, provides 5 star services. Beijing Continental Grand Hotel adjacent to BICC is a 4 star hotel with 538 guest rooms and 5 restaurants serving different types of cuisine. It is an ideal place for international and domestic conferences, cultural events and business meetings.',
		t5: 'For more information about BICC, please refer to its official web: ',
		t6: 'www.bicc.com.cn',
		changdi: 'Venue',
		c1: 'Venue: ',
		c2: 'Beijing International Convention Center (BICC) ',
		c3: 'Address: ',
		c4: 'No.8 Beichen Dong Road, Chaoyang District, Beijing, China',
		jianjie: 'About Beijing',
		j1: `As the capital of the People's Republic of China, Beijing is the nation's political, economic, cultural, education and international trade and communications center. With a history of over 3,000 years, it has been an integral part of China's history. Being one of the six ancient capital cities for over 850 years, it has become the heart and soul of politics and society throughout its long history and consequently there is an unparalleled wealth of discovery to delight and intrigue visitors as they explore the city’s ancient past and exciting modern development. Having successfully hosted the 29th Olympic Games in 2008 and the 24th Winter Olympics in February 2022, Beijing is now the first city in the world hosting both Olympic summer and winter games.`,
		j2: 'Area: 16,800 sq km (6552 sq mi)',
		j3: 'Population: 21.7 million',
		j4: 'Country: People’s Republic of China',
		j5: 'People: 95% Han Chinese',
		j6: 'Main language: Mandarin (putonghua)',
		j7: 'Time zone: GMT/UTC plus 8 hours',
		j8: ' Telephone area code: 010',
		j10: 'As an ancient city, the history of Beijing can be traced back to 3,000 years ago. In the Spring-Autumn and warring Periods (770 BC – 221 BC), Yan Nation established capital in Beijing, called “Ji”. In Qin, Han and Three Kingdoms time Beijing area is the center of northern China. Wang Mang established in Beijing in the Yan Nation in end of the Western Han Dynasty, so that Beijing is also called “Yanjing”. During the South Song Dynasty Liao Nation established the Capital in Beijing called Pei, Jin Dynasty officially established Capital in Beijing. Ever since, the Yuan Dynasty, Ming Dynasty and Qing Dynasty were established Capital in Beijing, a total of 34 emperors reigned over the whole country in Beijing.',
		j11: 'The long history of Beijing has offered a large number of cultural relics and a rich and varied human landscape, which provided very rich tourism resources for Beijing. The wonderful Great Wall and the Forbidden City are the world-famous tourist attractions. The beauty of the Summer Palace, Beihai Park, Fragrant Hill, the Temple of Heaven cannot be missed to visit.',
		tianqi: 'Weather in November',
		q1: `In November, Beijing's weather starts to move into the winter condition gradually. The climate in November is quite dry and the temperature difference between day and night becomes bigger. Heating is normally provided from November 15th as the temperature may drop down below zero. There can also be occasional strong and cold winds in this month, but with rare rain or snow.`,
	},
	programme: 'Programme',
	participation: 'Participation',
	regist: 'Registration',
	logistics: 'Logistics',
	exhibition: 'Exhibition',
	registerTable: {
		a: 'Registration Kit',
		b: 'Parallel Sessions',
		c: 'Welcome Reception',
		d: 'Exhibition',
		e: 'Transportation to and from the designated hotel',
		f: 'Closing Dinner',
		v: '√'
	},
	registerTable2: {
		a: 'Cancellation submitted by 30 September 2022',
		aa: '100% Refund',
		b: 'Cancellation submitted after 30 September 2022',
		bb: 'No Refund'
	},
	abstractTable: {
		a: 'Abstracts Submission Deadline',
		b: '20 September 2022 ',
		c: 'Abstracts Acceptance Notification',
		d: 'Before 30 September 2022',
		e: 'Abstracts Published (E-version and printed version)',
		f: 'Before 28 October 2022'
	},
	exhibitionTable: {
		h1: 'Item',
		h2: 'Date',
		h3: 'Time',
		a1: 'Booth reservation',
		a2: '28 September - 20 October 2022',
		a3: '',
		b1: 'Exhibitor move-in',
		b2: '6 November 2022',
		b3: '12:00 - 22:00',
		c1: 'Exhibition ',
		c2: '7 November 2022 <br>8 November 2022 ',
		c3: '14:00 - 18:30<br>08:30 - 18:30',
		d1: 'Exhibitor move-out',
		d2: '8 November 2022',
		d3: '18:00 - 20:00',
	},
	conferenceAgendaTable: {
		h1: 'Date',
		h2: 'Time',
		h3: 'Venue',
		h4: 'Content',
		day1: {
			val: '6 November',
			time: 'Whole Day',
			address: 'Beijing',
			cont: 'Pre-congress visit in Beijing / On-site Registration '
		},
		day2: {
			val: '7 November',
			time: {
				t: 'Morning',
				tc: 'Opening Ceremony',
				tc1: 'Signing Ceremony',
				tc2: 'Ambassadors’ Dialogue',
				a: 'Afternoon',
				ac: 'Parallel Sessions',
				ac1: 'Keynote Plenary Session',
				b: 'Evening',
				bc: 'Welcome Reception'
			},
			address: 'Beijing',
		},
		day3: {
			val: '8 November',
			time: {
				t: 'Whole Day',
				tc: 'Parallel Sessions',
				a: 'Late Afternoon',
				ac: 'Closing Ceremony',
				b: 'Evening',
				bc: 'Closing Dinner'
			},
			address: 'Beijing',
		},
		day4: {
			val: '9-11 November',
			time: '',
			address: '',
			cont: 'Post-congress visit to bamboo areas',
			cont1: 'Post-congress visit to bamboo areas',
		}
	},
	preliminaryProgramme: 'Preliminary Congress Programme',
	parallelMeeting: {
		zcdw: 'Supporting Organisations',
		zzs: 'Sponsors',
		j1: 'Description',
		j2: 'Objectives',
		j3: 'Organizers',
		j4: 'Thematic Area',
		j5: 'Flyer File',
		j6: 'Co-organizers',
		details: 'Details',
		t1: 'Date',
		t2: 'Zoom ID',
		d1: '7 November',
		d2: '8 November',
		time1: '16:20 - 18:20',
		time2: '08:30 - 10:10',
		time3: '10:30 - 12:10',
		time4: '13:30 - 15:10',
		time5: '15:30 - 17:10',
		room: '',
		room1: '',
		r1: '933 6665 4754',
		r2: '989 2086 5684',
		r3: '928 1506 4455',
		r4: '940 4441 4598',
		r5: '950 0242 3197',
		r6: '986 4781 4002',
		r7: '939 0210 1729',
		r8: '961 7631 0060',
		r9: "945 9685 5046",
		row1_1: '2022 International Conference - Bamboo: A Very Sustainable Construction Material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures',
		row1_2: '2022 International Conference - Bamboo: A Very Sustainable Construction Material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures',
		row1_3: 'Equipment R&D and Intelligent Manufacture',
		row1_4: 'International Seminars on Bamboo and Rattan Clusters for Regional Development and Green Transition',
		row1_5: 'International Seminars on Bamboo and Rattan Clusters for Regional Development and Green Transition',
		row2_1: 'The Ecological and Socio-economic Contribution of Rattan to People within the Context of Global Deforestation and Climate Change',
		row2_2: 'Ecosystem Services and Value Accounting',
		row2_3: 'Replacing Plastics with Bamboo: Policy, Roadmap and Actions',
		row2_4: 'Advances  in Chemical Utilization and Application of Bamboo',
		row2_5: 'Development and Internationalization of Bamboo and Rattan Standards',
		row3_1: 'Bamboo Forests Management for Decreasing Carbon Emission and Increasing Carbon Sequestration',
		row3_2: 'Bamboo: A Resource for Generating Partnerships and Address Climate Change',
		row3_3: 'Bamboo for Carbon Neutrality in Rural Areas: New Pathways for Carbon Reduction and Low Carbon Development in World Heritage Sites',
		row3_4: 'Key Challenges and Solutions of Sustainable Bamboo Forest Management',
		row3_5: 'Ecological Culture Leads the Process of Replacing Plastic with Bamboo ',
		row4_1: 'Advances in Bamboo Shoot Based Functional Foods Development and its Health Benefits',
		row4_2: 'Promoting the Global Initiative and Action Plan to Replace Plastic with Bamboo',
		row4_3: 'Development of Bamboo Energy to Help Carbon Neutrality',
		row4_4: 'Promotion the Development of Trade Facilitation of Bamboo and Rattan Commodities',
		row4_5: 'Bamboo for Carbon Neutrality and Green Growth',
		row5_1: 'Assessment of Bamboo Resources and Ecosystems Services',
		row5_2: 'Strengthening Resource Mobilization for Sustainable Forest Management and Forest Restoration: Opportunities for Collaboration between the United Nations Forum on Forests Secretariat and the International Bamboo and Rattan Organization ',
		row5_3: 'International Seminar on Nature Based Solutions for Rural Revitalization',
		row5_4: 'Bamboo Developmental Biology and Its Sustainable Management Techniques for Bamboo Plantation ',
		row5_5: 'RE-Think Buildings',
		row6_1: 'China Bamboo Industry Innovation and High Quality Development Forum',
		row6_2: 'China Medium and Small Diameter Bamboo Shoots Industry Development Forum ',
		row6_3: 'China Bamboo Under-Forest Economy and Forest Recreation Industry Development Forum ',
		row6_4: 'Green and Sustainable Development of Bamboo Fiber Industry',
		row6_5: 'Bamboo Value Chains for Inclusive and Sustainable Industrial Development in Africa ',
		list: [
			[{},
				{
					"conveningOrganizations": "Chinese Society of Forestry (CSF)",
					"conveningOrganizations1": 'Bamboo Branch, Chinese Society of Forestry <br>Research Institute of Subtropical Forestry, Chinese Academy of Forestry (CAF-RISF)<br>International Centre for Bamboo and Rattan (ICBR)<br>Nanjing Forestry University (NJFU)<br>Zhejiang Bamboo Industry Association, China<br>Anji Bamboo Association, Zhejiang Province, China<br>Zhejiang International Science and Technology Cooperation Base for Bamboo Cultivation and Utilization<br>Bamboo Industry Research Institute of  Yongan City',
					"date": "8 November",
					"description": "To fully implement Xi Jinping's thought on ecological civilization and follow  the spirit of his speech during the visit to Sichuan and other bamboo producing areas, this forum will be held under the theme of \"the development and innovation of bamboo industry\" in the background of the normalized prevention and control of COVID-19 and?the national strategy of \"carbon neutrality and carbon peak\". Focusing on the development trend of \"cohesion, change and future\" in bamboo industry, the forum will carry out discussions and exchanges on promoting innovative research and development of bamboo industry, digging the potential of bamboo industry and  the transformation and upgrading of enterprises. We will also put forward the road of innovation and development of China's bamboo industry in the new era, making new contributions to consolidating our achievements in poverty alleviation, promoting rural revitalization and achieving common prosperity. ",
					"email": "13868141030@139.com",
					"language": "Chinese–English (with interpretation)",
					"objectives": "Under the background of \"carbon neutral, carbon peak\", we actively develop bamboo industry science and technology innovation project, with new technology, new material, new product development as the starting point. We also vigorously develops prefabricated bamboo building and other energy-saving, low-carbon and green industry advocating \"bamboo instead of plastic\" and \"bamboo instead of wood\". This will promote the high quality development of bamboo industry in China and provide strong support for the revitalization of bamboo township.",
					"preferredFormat": "Online ",
					"roomAllocation": "933 6665 4754\n",
					"sessionTitle": "S4.1 Forum on Innovation and High Quality Development of Bamboo Industry",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630729cd05efce09c305da05",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.1 Agenda.pdf',
						title: 'S4.1 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "Bamboo Industrial Equipment Branch, China Bamboo Industry Association (CBIA-BIEB) ",
					"conveningOrganizations1": 'International Centre for Bamboo and Rattan (ICBR)<br>Institute of Biomass New Materials, International  Center for Bamboo and Rattan (ICBR-IBNM)<br>Research Institute of Wood Industry, Chinese Academy of Forestry (CAF-RIWI)<br>National Innovation Park for Forestry and Grass Equipment <br>Zhejiang Academy of Forestry Science, China<br>Fujian Academy of Forestry Science, China<br>Dongguan Koham Company Ltd., China',
					"date": "8 November",
					"description": "Advanced machinery equipment is an important symbol of modern bamboo industry development, is the basic way to improve the labor productivity and emancipate the productive forces. Accelerate the development and promotion of bamboo products equipment in raw and deep processing, improve production continuity, automation and intelligence, it is of great significance to the modern bamboo industry innovation development.<br> This session will invite well - known experts in the field of bamboo industry equipment and intelligent manufacturing at home and aboard, such as International Centre for Bamboo and Rattan, China Academy of Forestry, Beijing Forestry University, Nanjing Forestry University, Northeast Forestry University, Zhejiang Agriculture and Forestry University, etc.Around three topics discussed in (1) the development of bamboo industry machinery equipment at home and abroad, (2) construction of bamboo Industry and National Innovation Park for Forest and Grass Equipment in Zhejiang Province, (3) innovation and development of bamboo industrial machinery equipment manufacturing enterprises.This session is expected to build a cooperation platform, building consensus on industrial development, clarify industrial development priorities and promote greater breakthroughs in equipment research and development.",
					"email": "zwei611@hotmail.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "1.The developing status and trend of bamboo industrial machinery equipment at home and abroad;<p></p>2.The deep integration of intelligent, information and other emerging technologies with the whole chain of modern bamboo industry;<p></p>3.Innovative development mode for different scale bamboo industrial machinery equipment manufacturing enterprises at home and abroad.",
					"preferredFormat": "Online ",
					"roomAllocation": "933 6665 4754\n",
					"sessionTitle": "S2.4  Equipment R&D and Intelligent Manufacture ",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>",
					"timeAllocation": "10:30-12:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307227bfde6f913b92cec3a",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.4 Agenda.pdf',
						title: 'S2.4 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "National Forestry and Grassland Innovation Alliance on Bamboo and Rattan Industry (CHINA)<br> China Bamboo and Rattan Brand Cluster",
					"conveningOrganizations1": ' International Centre for Bamboo and Rattan<br> International Bamboo and Rattan Organization',
					"date": "8 November",
					"description": "Coordinated actions and strategic partnerships of key actors on the whole supply chain of the bamboo and rattan sectors are called by experts and participants from multi-fields of INBAR former webinars. Clustering development concept is introduced for regional cooperation and development.<p></p>This conference will invite key stakeholders from bamboo and rattan producing and consuming countries, green financing, trade and carbon neutralization institutes, and representatives from related inter-governmental and non-governmental organizations, around (1) business, industry and trade innovation by strategic cooperation of key actors in bamboo and rattan supply chain; (2) bridging gaps of technology, personnel, market and trade; (3) capacity building – cultivate specialized personnel for bamboo and rattan industry planning and development.<p></p>",
					"email": "wjin@inbar.int wangheng@icbr.ac.cn xiayu@icbr.ac.cn zhangguopeng@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Bridging gaps of technology, personnel, market and trade, promoting dialogues among bamboo entrepreneurs and international green financing, trade and carbon neutralization institutes; sharing and explore best practical models for industry, market and trade cooperation.",
					"preferredFormat": "Online",
					"roomAllocation": "933 6665 4754\n",
					"sessionTitle": "S4.6 International Seminar on Bamboo and Rattan Clusters for Regional Development and Green Transition\n",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development ",
					"timeAllocation": "13:30-15:10  (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630721a8fde6f913b92ceb22",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.6 Agenda.pdf',
						title: 'S4.6 Agenda.pdf',
					}, ],
					"zcdw": "Southwestern University of Finance and Economics"
				},
				{
					"conveningOrganizations": "National Forestry and Grassland Innovation Alliance on Bamboo and Rattan Industry (CHINA)<br> China Bamboo and Rattan Brand Cluster",
					"conveningOrganizations1": ' International Centre for Bamboo and Rattan<br> International Bamboo and Rattan Organization',
					"date": "8 November",
					"description": "Coordinated actions and strategic partnerships of key actors on the whole supply chain of the bamboo and rattan sectors are called by experts and participants from multi-fields of INBAR former webinars. Clustering development concept is introduced for regional cooperation and development.<p></p>This conference will invite key stakeholders from bamboo and rattan producing and consuming countries, green financing, trade and carbon neutralization institutes, and representatives from related inter-governmental and non-governmental organizations, around (1) business, industry and trade innovation by strategic cooperation of key actors in bamboo and rattan supply chain; (2) bridging gaps of technology, personnel, market and trade; (3) capacity building – cultivate specialized personnel for bamboo and rattan industry planning and development.<p></p>",
					"email": "wjin@inbar.int wangheng@icbr.ac.cn xiayu@icbr.ac.cn zhangguopeng@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Bridging gaps of technology, personnel, market and trade, promoting dialogues among bamboo entrepreneurs and international green financing, trade and carbon neutralization institutes; sharing and explore best practical models for industry, market and trade cooperation.",
					"preferredFormat": "Online",
					"roomAllocation": "933 6665 4754\n",
					"sessionTitle": "S4.6  International Seminar on Bamboo and Rattan Clusters for Regional Development and Green Transition\n",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development ",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630721a8fde6f913b92ceb22",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.6Agenda.pdf',
						title: 'S4.6Agenda.pdf',
					}, ],
					"zcdw": "Southwestern University of Finance and Economics"
				}
			],
			[{
					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR) ",
					"conveningOrganizations1": 'University of British Columbia (UBC)<br>Forestry Research Institute of Ghana (CSIR) ',
					"date": "7 November",
					"description": "Rattans are climbing palms belonging to the Palm familiy (Palmae or Arecaceae). There are around 650 different species of rattan in 13 genera, concentrated solely in the Old World tropics of Africa and Asia.<p></p>The world trade of rattan raw materials and woven rattan alone amounted to USD 0.38 billion (INBAR, 2019). It is estimated that more than 700 million people worldwide trade in or use rattan for a variety of purposes, and the global trade (domestic and export) and subsistence value of rattan and its products exceeds USD 7,000 million per annum (Pabuayon, 2000; Sastry, 2000; Soedarto, 1999).<p></p>It is estimated that approximately 20% of all rattan species are used commercially in the furniture industry or for matting and basketry, and during the 1970s, Indonesia fulfilled about 90% of the world’s rattan requirements (Dransfield and Manokaran, 1994). Globally, the furniture and handicraft industry mainly depend on resources from the secondary and virgin forests for rattan supply. Deforestation, overexploitation and habitat modification have caused the supply of raw rattans for the furniture industry to dwindle, and most rattan industries are experiencing setbacks.",
					"email": "rkaam@inbar.int <p></p>terry.sunderland@ubc.ca<p></p>bdobiri@csir-forig.org.gh",
					"language": "English ",
					"objectives": "It is becoming increasingly clear that most of the world’s rattan resources are already under threat due to unsustainable harvesting practices and deforestation, leading to a loss of germplasm and even the threat of extinction of some species.<p></p>To achieve sustainability, it is essential that scientific management of the rattan resources that permits the regeneration of stocks and rational harvesting levels be implemented. The objective of this parallel session are threefold:<p></p>1. Improve the better understanding of natural rattan including taxonomy and regeneration, and rattan resource development, and provide suggestions on how to sustainably manage rattan resources in the world,<p></p>2. Assess the and quantify the potential and actual contribution of rattan to the livelihood of local populations and global trade of rattan,<p></p>3. Understand the technological advancement in the processing of high-end rattan manufacture products and innovative applications of rattan canes.<p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S2.1  The Ecological and Socio-economic Contribution of Rattan to People within the Context of Global Deforestation and Climate Change\n",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>",
					"timeAllocation": "16:20-18:20 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630736cfa9ea220a1d494c66",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.1Agenda.pdf',
						title: 'S2.1Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "Nanjing Forestry University (NJFU) ",
					"conveningOrganizations1": 'Ecological Society of Jiangsu Province<br>Systems Engineering Society of Jiangsu Province<br>Jiangsu Agricultural Green Development Research Association',
					"date": "8 November",
					"description": "In times of increasingly severe environmental crisis, green water and green mountains are golden mountains and silver mountains. Establishing an ecosystem value realization mechanism to transform invisible and intangible ecological benefits into economic and social benefits is not only an important measure to practice the concept of green water and green mountains are golden mountains and silver mountains, but also a useful exploration to improve the system of ecological civilization.<p></p>Experts and scholars from Nanjing Forestry University, Beijing Forestry University, Zhejiang A&F University, Zhejiang University, Chongqing University, the University of Alberta in Canada, the University of Puerto Rico in the United States and other institutions will be invited to the conference. The conference will focus on three topics: \"Current situation and development trend of forestry carbon sequestration in China\", \"Accounting of ecosystem service value\", \"Biodiversity protection and ecological protection in the context of carbon neutrality\". The meeting is expected to reach further agreement around The accounting method and index of ecosystem service value, use ecosystem value accounting to help realize the value of ecological products, clarify China's forestry economic management policies and development direction under the \"double carbon\" background, promote the application research of ecosystem service value accounting, and promote biodiversity protection and ecological protection under the carbon neutralization background.",
					"email": "nerrynor@163.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Clarify the current situation and development trend of China's forestry carbon sink, reveal the significance of ecosystem service value accounting, explore the accounting methods and Accounting Indicators of ecosystem service value, promote the application research of ecosystem service value accounting, and improve biodiversity protection and ecological protection under the background of carbon neutrality.",
					"preferredFormat": "Online ",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S1.3 Ecosystem Services and Value Accounting\n",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072f15659d73189c43fc13",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.3 Agenda.pdf',
						title: 'S1.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Bamboo and Rattan Organization (INBAR)",
					"conveningOrganizations1": '',
					"date": "8 November",
					"description": "Climate change has already been impacting every parts of the world, with extreme weather events: droughts, floods, heat waves, heavy rain and other weather induced disasters.<p></p>Over 110 countries have committed to achieve net zero emissions or carbon neutrality by middle of this century (2050). Few other important countries such as USA and China have pledged to achieve carbon neutrality by 2060 and India has announced that it will achieve carbon neutrality by 2070.<p></p>To achieve carbon neutrality, greenhouse gas emissions needs to be counterbalanced by carbon sequestration. Bamboo is one of the fastest growing plants in the world. A large range of bamboo products that can substitute for wood / timber, plastic, steel, PVC products and other energy intensive materials are available. Various researches indicate that carbon storage in bamboo forest ecosystems range from 94 – 392 tC/ ha and moreover bamboo forests have a high rate of carbon sequestration (up to 25 tC/ ha/year). LCA studies indicate that many bamboo products are low carbon intensity and even carbon negative if end life disposal is used for biomass energy production. <p></p>Overall, bamboo can significantly support in climate change mitigation and adaptation. Bamboo with its known resource coverage of over 35 million hectares across the tropical and sub-tropical regions can specifically help in achieving carbon neutrality through storing carbon in bamboo ecosystems,, in bamboo products, reducing pressure on forests and substituting for high carbon intensity products.",
					"email": "jdurai@inbar.int",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Showcase bamboo as a “nature based solution” for achieving carbon neutrality and achieve green growth. <p></p>Specifically, <p></p>Illustrate the potential of bamboo in carbon sequestration by restoration and sustainable management.<p></p>Illustrate the carbon storage in form of durable products including the LCA studies.",
					"preferredFormat": "Online ",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S4.5 Bamboo for Carbon Neutrality and Green Growth",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<br> Thematic area 4. Working Together for Inclusive and Green Development ",
					"timeAllocation": "10:30-12:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630737a97261210069491dcf",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.5 Agenda.pdf',
						title: 'S4.5 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR)  ",
					"conveningOrganizations1": 'Institute for Chemical Utilization of Bamboo and Rattan Resources, International Centre for Bamboo and Rattan (ICBR-ICUBRR)<br>Bamboo Food and Commodity Branch, China Bamboo Industry Association (CBIA-BFCB)<br>Shenzhen Gold Basin Ltd., China',
					"date": "8 November",
					"description": "Bamboo is a kind of important medicinal plant material, which has a long history of both medicinal and food use in China, East Asia and Southeast Asia. In recent years, phytochemical research methods and advanced analytical and detection instruments have been widely used in bamboo chemical research, and modern extraction and processing equipment and advanced technology have been widely used in production. The isolation, identification and distribution of secondary metabolites in bamboo, structural modification and biotransformation of important secondary metabolites, evaluation and utilization of bioactivity of bamboo extracts and other basic and applied researches have made important progress, which has strongly promoted the high-value utilization of bamboo resources and the transformation and upgrading of bamboo industry.<p></p>At present, bamboo-derived health food, bamboo beverage, food additives, daily chemical products and other products have formed a certain market scale and consumer groups in China. However, the development of such products is still relatively weak, the market share is limited, and there is still much room for improvement in standardization and standardized production.<p></p>Today, with the vigorous development of the big health industry, focusing on the goal of \"double carbon\" and \"replacing plastic with bamboo\", the chemical utilization of bamboo resources is becoming one of the fields with the greatest development potential in the bamboo industry. This meeting will focus on in-depth discussion on the chemical utilization of bamboo resources, exchange the latest progress in the research on the chemical utilization of bamboo resources, explore new development fields, clarify the direction of key research and development, and promote the construction of the whole bamboo utilization system.<p></p>",
					"email": "fengtang@icbr.ac.cn  <p></p>yaoxi@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Establish an exchange platform for experts and scholars in the field of chemical utilization of bamboo resources worldwide, as well as social enterprises, private sector and other units, jointly discuss the development path of chemical utilization of bamboo resources, and promote the development and utilization of bamboo resources in the field of general health.",
					"preferredFormat": "Online ",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S3.5 Advances  in Chemical Utilization and Application of Bamboo\n",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>",
					"timeAllocation": "13:30-15:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072a48a163db23c53a8a71",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.5Agenda.pdf',
						title: 'S3.5Agenda.pdf',
					}, ],
				},
				{
					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR)",
					"conveningOrganizations1": 'International Organization for Standardization Technical Committee 296 on Bamboo and Rattan (ISO/TC 296)<br>International Union of Forest Research Organizations (IUFRO) Division 5.11.05 - Bamboo and Rattan',
					"date": "8 November",
					"description": "In 2019, the total value of international trade in bamboo and rattan products exceeded $3.417 billion. However, corresponding to the increasing trend of international trade of bamboo and rattan products, the number and scope of existing international standards related to bamboo and rattan are very limited, far from covering each segment category of bamboo and rattan products, and cannot meet the market demand. This leads to the uneven quality of bamboo and rattan products, which is easy to cause trade disputes, greatly limiting the healthy development of the bamboo and rattan industry and the international trade of bamboo and rattan products. In order to better regulate the quality of bamboo and rattan products and promote the global trade of bamboo and rattan products, it is necessary to establish a globally recognized international standard for bamboo and rattan.<p></p>This meeting invites experts from International Organization for Standardization and related countries to discuss the development status, existing problems, development trend and internationalization of bamboo and rattan standards in different countries in Asia and Africa, in order to further promote the development of bamboo and rattan related international standards.",
					"email": "cfang@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "1. Promote the full communication and discussion of the development and internationalization status of bamboo and rattan standards among the makers, managers and international trade practitioners of different countries and regions.<p></p>2. Improve stakeholders' understanding of the development of international standards for bamboo and Rattan under the framework of the International Organization for Standardization.<p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "989 2086 5684",
					"sessionTitle": "S3.8 Development and Internationalization of Bamboo and Rattan Standards\n",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63071ff0ef35d85bee3fdebe",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.8 Agenda.pdf',
						title: 'S3.8 Agenda.pdf',
					}, ],
				},
			],
			[{
					"conveningOrganizations": "Zhejiang A&F University (ZAFU)",
					"conveningOrganizations1": 'Engineering Research Center of Bamboo Carbon Sequestration, National Forestry and Grassland Administration<br>State Key Laboratory of Subtropical Silviculture Co-founded by Zhejiang Provincial Government and Ministry of Education<br>Institute of Bamboo, Zhejiang A&F University ',
					"date": "7 November",
					"description": "Woody giant bamboo is a kind of the most productive plant in the world, making it a promising way to mitigate climate change. Such productive growth characteristics of woody bamboos may be attributed to the structure of connecting rhizomes which promote water and nutrients transferring among different ages of the culm. However, mechanisms undertaking the explosive growth of bamboos are still unclear. <p></p>Except for the fast growth of bamboo, high carbon sequestration of the bamboo forest mainly relies on the stability of soil carbon stock. However, forest management/climate change disturbances to soil carbon stock are largely unknown. <p></p>Carbon sequestration projects are essential to strengthen bamboo forests' role in climate change mitigation. They shall be popularized to the public, such as stakeholders, forest community managers, and foresters. <p></p>Therefore, we planned to hold a parallel session to enlarge our knowledge on bamboo carbon sequestration from both specialized and public aspects.",
					"email": "ttmei@zafu.edu.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "1. To share the latest knowledge on the explosive growth of bamboo from the aspects of ecophysiology, mechanism, and technologies on the stability of soil carbon stock of bamboo forests and their suggestions on bamboo forest management.<p></p>2. Summarize future research directions and possible collaborations between scientists, especially senior and young scientists. <p></p>3. Popularize the carbon sequestration projects from managed bamboo forests and their role in climate change mitigation to the public.<p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "928 1506 4455\n",
					"sessionTitle": "S1.1 Bamboo Forests Management for Decreasing Carbon Emission and Increasing Carbon Sequestration\n",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "16:20-18:20 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072be04d5bc90fac2bf279",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.1 Agenda.pdf',
						title: 'S1.1 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "Spanish Agency for International Development Cooperation (Agencia Española de Cooperación Internacional para el Desarrollo, AECID)",
					"conveningOrganizations1": 'Ministry of Urban Development and Housing, Ecuador<br>National Forest and Wildlife Service, Peru<br>Latin America and the Caribbean Office, International Bamboo and Rattan Organization (INBAR-LACO)<br>Bambusa ES (company), Spain<br>Ibero-American Bamboo Association, Spain / Portugal',
					"date": "8 November",
					"description": "Bamboo is a fast-growing giant grass with 1,642 species worldwide and 10,000 years of use in Latin America and the Caribbean region. This resource can play an important role as a nature-based solution by providing a range of ecosystem services to address pressing global challenges, such as climate change adaptation and mitigation.<p></p>Through the coordination among public, private, academic and civil society actors, this resource can be recognized as strategic in national policies and action plans and in international initiatives, contributing directly to reducing the negative effects of climate change patterns on millions of rural communities. Incorporating it into the livelihoods of these populations can strengthen their resilience, through the use of a resource that is often present in their farms, but is not used in its entirety, usually because of lack of knowledge.",
					"email": "rafael.garcia@aecid.es<p></p>pablo.ferreiro@aecid.es",
					"language": "Spanish with interpretation to English",
					"objectives": "Sharing the experiences and lessons learned developed by AECID in Latin America and the Caribbean region by using bamboo.",
					"preferredFormat": "Online ",
					"roomAllocation": "928 1506 4455\n",
					"sessionTitle": "S4.3 Bamboo: A Resource for Generating Partnerships and Addressing Climate Change",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development ",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630737a8701b8b21691aceac",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.3 Agenda.pdf',
						title: 'S4.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Bamboo and Rattan Organization (INBAR) <br>Xishuangbanna Tropical Botanical Garden, Chinese Academy of Sciences (CAS-XTBG)<br>Southeast Asia Biodiversity Research Institute, Chinese Academy of Sciences (CAS-SABR)<br>International Center for Integrated Mountain Development (ICIMOD)<br>Center for Mountain Futures (CMF)",
					"date": "8 November",
					"description": "In February 2022, the \"Regional Cooperation Seminar on Biodiversity Conservation and Sustainable Development\" co-organized by Bamboo and Rattan International and other organizations proposed three key words for nature-based solutions: \"systematization\", \"innovation\" and \"partnership\". In-depth biodiversity assessment, best practice research and capacity-building for young scholars are recommended as priorities for joint action.<p></p>The symposium will invite scientists and professionals, especially young researchers and students, in the fields related to biodiversity conservation, ecology, environmental protection, rural development, regional cooperation and development. Representatives of green finance, trade and carbon neutral institutions and projects, local authorities, land and resources planning agencies, investment institutions, international aid agencies, entrepreneurs and green technology conversion agencies, non-governmental organizations and concerned communities will also be invited to participate in the seminar. The conference focused on (1) in-depth biodiversity assessment; (2) best patterns of human and nature interaction, models and practices; (3) capacity building and public awareness raising for cross-border biodiversity conservation and sustainable development actions.<p></p>",
					"email": "wjin@inbar.int<p></p>xiaxue@xtbg.org.cn <p></p>zouhuang@xtbg.ac.cn <p></p>wangyue@mail.kib.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The purpose of this Seminar is to support systematic, comprehensive and multi-stakeholder approach to biodiversity conservation and sustainable development projects.This workshop will promote Scientists sharing with green finance, green trade and carbon neutral institutions the main indicators of biodiversity assessment that can be used for reference and project development; experts sharing best practices and models of Natural-based Solutions and analyze their transformation mechanisms; the formation of strategic partnership and joint action plans.",
					"preferredFormat": "Online ",
					"roomAllocation": "928 1506 4455\n",
					"sessionTitle": "S2.2 International Seminar on Nature Based Solutions for Rural Revitalization ",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>",
					"timeAllocation": "10:30-12:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630720e3009adc6b093a4661",
					"zcdw": "Southwestern University of Finance and Economics",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.2Agenda.pdf',
						title: 'S2.2Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Bamboo and Rattan organization (INBAR)",
					"conveningOrganizations1": 'UN Environment Programme World Conservation Monitoring Centre (UNEP-WCMC)',
					"date": "8 November",
					"description": "According to UNEP, more than 9 billion metric tons of plastics are produced since the early of 1950’s. Less than 10 percent of plastics are recycled, and about 80 percent of plastics ends up in landfills, natural environment and the oceans creating plastic pollution in terrestrial and aquatic ecosystem. Moreover, majority of plastics are produced from chemicals derived from fossil fuels, which are unsustainable and contribute to climate change. To mitigate the negative impacts of plastics and plastic pollution, more than 140 countries have relevant plastic ban and/or restriction including many international conventions, such as the Basel Convention, the Rotterdam Convention and the Stockholm Convention focusing on plastic pollution control.<p></p>Bamboo can be a proven plastic substitute. With technological innovation, a number of modern industrial products were innovated, that include disposable bamboo fiber tableware products (plate, bowl, cup), single use plastics substitutes (straws), other daily use products, packaging materials, bamboo packing in cooling towers, bamboo winding pipes, etc. The advantage of bamboo is that it is biodegradable, fast growing and a renewable biomass material, bamboo fit the development of circular economy, has low carbon foot print, and a resource that is found in most topical and sub-tropical regions of the world. In addition to the livelihood development benefits, it also provides a number of intangible ecosystem services and benefits.",
					"email": "jdurai@inbar.int",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Discuss on how the bamboo plastic alternative products can be mainstreamed to facilitate bamboo as a viable alternative to plastics. <p></p><p></p>How bamboo can fit into the global agenda?",
					"preferredFormat": "Online ",
					"roomAllocation": "928 1506 4455",
					"sessionTitle": "S3.6 Replacing Plastics with Bamboo: Policy, Roadmap and Actions",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>Thematic area 4. Working Together for Inclusive and Green Development ",
					"timeAllocation": "13:30-15:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63073742f386dc2231260112",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.6 Agenda.pdf',
						title: 'S3.6 Agenda.pdf',
					}, ]

				},
				{
					"conveningOrganizations": "China Eco-Culture Association (CECA) ",
					"conveningOrganizations1": 'China Flower Association (CFA)<br>China Bamboo Industry Association (CBIA)<br>International Centre for Bamboo and Rattan (ICBR)',
					"date": "8 November",
					"description": "Bamboo has many ecological functions such as economy, ecology and culture. Cultivating bamboo culture and developing bamboo industry has become an important way to implement the Xi Jinping's thought on ecological civilization, the rural revitalization strategy and green and low-carbon development policy. Bamboo related ecological culture is bound to play an important role in replacing plastic with bamboo.<p></p>This conference will focus on exchanging the important academic progress in the field of ecological culture and replacing plastic with bamboo, discussing the problems, opportunities and challenges, and making positive contributions to the construction of ecological civilization and a beautiful China.",
					"email": "ceca_beijing@126.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "In order to exchange the important academic progress in the field of ecological culture and replacing plastic with bamboo, and discuss the new achievements, new theories and new experiences in the field of ecological culture helping replace plastic with bamboo, China Ecological Culture Association, China Flower Association and China Bamboo Industry Association plan to jointly hold the side meeting of \" ecological culture leads the process of replacing plastic with bamboo \".",
					"preferredFormat": "Online ",
					"roomAllocation": "928 1506 4455\n",
					"sessionTitle": "S2.7 Ecological Culture Leads to the Substitution of Bamboo for Plastic\n",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307291f50139a37584547a1",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.7 Agenda.pdf',
						title: 'S2.7 Agenda.pdf',
					}, ]
				}
			],
			[{
					"conveningOrganizations": "China National Bamboo Research Center<br>Bamboo Food and Commodity Branch, China Bamboo Industry Association (CBIA-BFCB)<br>Bamboo  Industry (Jian'ou) Branch, Fujian Provincial Collaborative Innovation Institute",
					"conveningOrganizations1": 'Fujian Mingliang Food Co., Ltd., China<br>Chongqing Tezhen Food Co., Ltd.<br>Zhejiang Shengshi Biological Technology Co., Ltd., China',
					"date": "7 November",
					"description": "Bamboo shoots are edible, tender buds developed from the lateral bud of bamboo rhizome or base. Owning to the richness in nutrients of dietary fiber, protein, polysaccharide, minerals, ect., and low in fat, bamboo shoots are excellent natural green vegetable and food materials that used traditionally across Asia, Africa and Latin America, and attract increasing attention and efforts in functional foods development with modern technologies. With global annual yields of fresh shoots over 55 million tons, bamboo shoot based food industry is booming across globe for human heathier nutrient supplementary and uptake.<p></p>Meeting will focus on 4 points: (1) edible history of bamboo shoots; (2) nutrition, bioactive compounds and pharmacology basis of bamboo shoots; (3) bamboo shoot based food R&D; (4) bamboo shoot food and human health. The session aims to share the latest research results on the nutrients and bioactive compounds in bamboo shoot and their associated health benefits, exchange innovations and progress on shoot-based products and novel utilizations in last decade, and address challenges of bamboo shoot as future food.<p></p>",
					"email": "boteatree@163.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The conference aims to fully tap the potential of bamboo shoots as future food by sharing the latest research results and exchanging product innovations and advances. In addition, the conference will raise global public awareness of bamboo shoot consumption, especially in developed countries, and promote global cooperative research and technology development in bamboo shoot processing and utilization.",
					"preferredFormat": "Online ",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S3.2 Advances in Bamboo Shoot Based Functional Foods Development and Its Health Benefits\n",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>",
					"timeAllocation": "16:20-18:20 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307288bd1b679258114287a",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.2 Agenda.pdf',
						title: 'S3.2 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Bamboo and Rattan Organization (INBAR)",
					"conveningOrganizations1": 'International Centre for Bamboo and Rattan (ICBR)<br>China Bamboo Industry Association (CBIA)',
					"date": "8 November",
					"description": "Under the general trend of global control of plastic pollution, bamboo is used as a fast-growing, green, renewable, low-carbon and degradable biomass material. From disposable tableware, to durable household products, packaging, building materials and auto parts, bamboo products can replace plastic products in many fields, providing a nature-based sustainable development solution for reducing plastic and carbon. To this end, the International Bamboo and Rattan Organization actively advocates \"replacing plastic with bamboo\" to meet the development needs of global plastic restriction, plastic ban, low carbon and green development.<p></p>The meeting plans to invite government officials, representatives of international organizations, research institutions and enterprises related to banning plastic and replacing plastic with bamboo. The conference will focus on three topics: (1) global initiative of bamboo replacing plastic; (2) R&D of bamboo replacing plastic; (3) production and market analysis of one single use bamboo tableware.<p></p>",
					"email": "jfu@inbar.int",
					"language": "Chinese–English (with interpretation)",
					"objectives": "Promoting the \" Initiative of Bamboo Replacing Plastic\", and the action plan of replacing bamboo with plastic.",
					"preferredFormat": "Online",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S3.3 Promoting the Global Initiative and Action Plan to Replace Plastic with Bamboo ",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072999702fa623ef5d31bd",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.3 Agenda.pdf',
						title: 'S3.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "Bamboo and Rattan Resources and Utilization Branch, Chinese Society of Forestry (CSF-BRRUB)<br>Bamboo Charcoal Branch, China Bamboo Industry Association (CBIA-BCB)<br>Institute of Green Economy, International Centre for Bamboo and Rattan (ICBR-IGE)",
					"conveningOrganizations1": 'International Centre for Bamboo and Rattan (ICBR)<br>China Bamboo Industry Association (CBIA)<br>Chinese Society of Forestry (CSF)',
					"date": "8 November",
					"description": "Bamboo is characterized by fast growth, high lignification and large amount of processing leftovers. It is a potential resource for developing biomass energy and a powerful starting point for China to implement national development programs such as the Belt and Road Initiative, targeted poverty alleviation, and Rural revitalization. The development of bamboo energy is of great significance to the Chinese government's goal of reaching a carbon peak by 2030 and achieving carbon neutrality by 2060.<p></p>This side meeting will focus on four topics, including research status and development prospect of bamboo solid fuel, clean production of bamboo charcoal industry, \"heat- electricity-gas-carbon and fertilizer \"polygeneration\" technology of bamboo, and bamboo activation carbon, in order to promote the research, development and industrialization of bamboo energy utilization.",
					"email": "472491002@qq.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The purpose of this conference is to exchange and discuss the innovative technology, standard system, application market and development potential of bamboo energy, promote the research, development and industrialization of bamboo energy utilization, and promote the contribution, value potential and market prospect of bamboo energy in the process of \"double carbon\" realization.",
					"preferredFormat": "Online ",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S1.5 Development of Bamboo Energy to Help Carbon Neutrality ",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "10:30-12:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630720531863e8675f73361f",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.5 Agenda.pdf',
						title: 'S1.5 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Bamboo and Rattan Organization (INBAR)<br>Research Institute of Green Economy, ICBR",
					"conveningOrganizations1": `Department of Duty Collection, General Administration of Customs of China<br>Permanent Mission of the People's Republic of China to the World Trade Organization<br>Long Bamboo Technology Group Co., Ltd<br>Bamboo and Rattan Resources and Utilization Branch of Chinese Society of Forestry<br>China Bamboo Industry Association (CBIA)`,
					"date": "8 November",
					"description": "As a strategic natural resource, bamboo and rattan have been concerned and valued by the international community under the promotion of the International Organisation for Bamboo and Rattan. Green, low-carbon, abundant bamboo and rattan commodities are gradually recognized and favored by global consumers. It is estimated that the total output value of the global bamboo and rattan industry exceeds $70 billion, and the total international trade of bamboo and rattan commodities exceeds $3 billion. More than 100 countries and regions participate in the international trade of bamboo and rattan commodities. The development of bamboo and rattan industry and commodities trade play an important role in promoting regional economic cooperation and promoting the process of sustainable development in developing countries. There are still some problems in coding, tariff policy, procedure and technology, which need to be improved in the process of bamboo and rattan commodities trade facilitation.<p></p>Representatives of relevant international organizations, scientific research institutes and import and export enterprises of bamboo products will be invited to attend this conference. The conference will exchange the current situation and problems of international trade of bamboo and rattan products, discuss the opportunities and challenges of the global international market of bamboo and rattan, interpret relevant trade policies, and put forward feasible policy suggestions and measures to promote the development of international trade facilitation of bamboo and rattan products.<p></p>",
					"email": "cjj@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "This session aims to promote the exchange and discussion of the policy development about bamboo and rattan commodities trade facilitation. This session will focus on current situation, potential and problems of the global trade of bamboo and rattan commodities, discuss the opportunities, challenges, policies and measures of the development of international trade of bamboo and rattan at different levels, so as to reach consensus, and put forward policy suggestions to explore the international market of bamboo and rattan commodities and promote the development of international trade facilitation.",
					"preferredFormat": "Online",
					"roomAllocation": "940 4441 4598",
					"sessionTitle": "S4.7 Facilitating International Trade of Bamboo and Rattan Commodities",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "13:30-15:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630727b9519d4f3deb4d0bc7",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.7 Agenda.pdf',
						title: 'S4.7 Agenda.pdf',
					}, ],
				},
				{
					"conveningOrganizations": "World Wildlife Fund for Nature (WWF) Beijing Office ",
					"conveningOrganizations1": 'International Bamboo and Rattan Organization (INBAR)',
					"date": "8 November",
					"description": "Bamboo forest management directly relates to the economic development, supply chain stability, and ecological stableness of rural areas in China. With the demands of economic development, urbanization, as well as continued impact of the global pandemic, traditional way of bamboo forest management is facing a recession vortex due to factors including aging labor population, outdated regulating policy, decreasing profit, contributing to a declining trend of the industry. Improvements in bamboo forest management posts to be a key challenge for the sustainable development of the industry.<p></p>This conference intends to invite representatives of enterprises, forest management, technological departments, research institutions, universities and other stakeholder representatives, to discuss the key issues in sustainable bamboo forest management, summarize the current situation, and find innovative solutions.",
					"email": "yjli@wwfchina.org",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Setting up communication platform to promote the sustainable development of bamboo management and bamboo industry in China.",
					"preferredFormat": "Online ",
					"roomAllocation": "940 4441 4598\n",
					"sessionTitle": "S2.8 Key Challenges and Solutions of Sustainable Bamboo Forest Management\n",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072c0e9934a0152e2b59f1",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.8Agenda.pdf',
						title: 'S2.8Agenda.pdf',
					}, ]
				},
			],
			[{},
				{
					"conveningOrganizations": "Zhejiang A&F University (ZAFU)",
					"conveningOrganizations1": 'Engineering Research Center of Bamboo Carbon Sequestration, National Forestry and Grassland Administration<br>State Key Laboratory of Subtropical Silviculture Co-founded by Zhejiang Provincial Government and Ministry of Education<br>Institute of Bamboo, Zhejiang A&F University ',
					"date": "8 November",
					"description": "Woody giant bamboo is a kind of the most productive plant in the world, making it a promising way to mitigate climate change. Such productive growth characteristics of woody bamboos may be attributed to the structure of connecting rhizomes which promote water and nutrients transferring among different ages of the culm. However, mechanisms undertaking the explosive growth of bamboos are still unclear. <p></p>Except for the fast growth of bamboo, high carbon sequestration of the bamboo forest mainly relies on the stability of soil carbon stock. However, forest management/climate change disturbances to soil carbon stock are largely unknown. <p></p>Carbon sequestration projects are essential to strengthen bamboo forests' role in climate change mitigation. They shall be popularized to the public, such as stakeholders, forest community managers, and foresters. <p></p>Therefore, we planned to hold a parallel session to enlarge our knowledge on bamboo carbon sequestration from both specialized and public aspects.",
					"email": "ttmei@zafu.edu.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "1. To share the latest knowledge on the explosive growth of bamboo from the aspects of ecophysiology, mechanism, and technologies on the stability of soil carbon stock of bamboo forests and their suggestions on bamboo forest management.<p></p>2. Summarize future research directions and possible collaborations between scientists, especially senior and young scientists. <p></p>3. Popularize the carbon sequestration projects from managed bamboo forests and their role in climate change mitigation to the public.<p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "950 0242 3197\n",
					"sessionTitle": "S1.1 Bamboo Forests Management for Decreasing Carbon Emission and Increasing Carbon Sequestration\n",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072be04d5bc90fac2bf279",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.1 Agenda.pdf',
						title: 'S1.1 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "Zhejiang A&F University (ZAFU)",
					"conveningOrganizations1": 'Engineering Research Center of Bamboo Carbon Sequestration, National Forestry and Grassland Administration<br>State Key Laboratory of Subtropical Silviculture Co-founded by Zhejiang Provincial Government and Ministry of Education<br>Institute of Bamboo, Zhejiang A&F University ',
					"date": "8 November",
					"description": "Woody giant bamboo is a kind of the most productive plant in the world, making it a promising way to mitigate climate change. Such productive growth characteristics of woody bamboos may be attributed to the structure of connecting rhizomes which promote water and nutrients transferring among different ages of the culm. However, mechanisms undertaking the explosive growth of bamboos are still unclear. <p></p>Except for the fast growth of bamboo, high carbon sequestration of the bamboo forest mainly relies on the stability of soil carbon stock. However, forest management/climate change disturbances to soil carbon stock are largely unknown. <p></p>Carbon sequestration projects are essential to strengthen bamboo forests' role in climate change mitigation. They shall be popularized to the public, such as stakeholders, forest community managers, and foresters. <p></p>Therefore, we planned to hold a parallel session to enlarge our knowledge on bamboo carbon sequestration from both specialized and public aspects.",
					"email": "ttmei@zafu.edu.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "1. To share the latest knowledge on the explosive growth of bamboo from the aspects of ecophysiology, mechanism, and technologies on the stability of soil carbon stock of bamboo forests and their suggestions on bamboo forest management.<p></p>2. Summarize future research directions and possible collaborations between scientists, especially senior and young scientists. <p></p>3. Popularize the carbon sequestration projects from managed bamboo forests and their role in climate change mitigation to the public.<p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "950 0242 3197\n",
					"sessionTitle": "S1.1 Bamboo Forests Management for Decreasing Carbon Emission and Increasing Carbon Sequestration\n",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "10:30-12:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072be04d5bc90fac2bf279",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.1 Agenda.pdf',
						title: 'S1.1 Agenda.pdf',
					}, ]
				},

				{
					"conveningOrganizations": "Nanjing Forestry University (NJFU)",
					"conveningOrganizations1": 'International Centre for Bamboo and Rattan (ICBR)<br>Southwest Forestry University (SWFU)<br>Jiangxi Agricultural University (JXAU)',
					"date": "8 November",
					"description": "Bamboo is one of the fastest growing plants in the world, and bamboo can be utilized sustainably through scientific management. The meeting will invite the researchers and professors studing the growth and development of bamboo plants and bamboo forest cultivation to discuss the core topic of \"molecular mechanism and theoretical basis of bamboo plant growth and development\".",
					"email": "ghliu@njfu.edu.cn",
					"language": "English ",
					"objectives": "The purpose of this conference is to promote the exchange of bamboo researchers from around the world, discuss the molecular mechanisms of bamboo plant development and technical bottlenecks related to sustainable management of bamboo forests, and help promote the development of the global bamboo industry.",
					"preferredFormat": "Online",
					"roomAllocation": "950 0242 3197\n",
					"sessionTitle": "S1.7 Bamboo Developmental Biology and Its Sustainable Management Techniques for Bamboo Plantation",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "13:30-15:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072b18a3a6654f964f3500",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.7 Agenda.pdf',
						title: 'S1.7 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "United Nations Human Settlements Programme (UN-HABITAT) ",
					"conveningOrganizations1": '',
					"date": "8 November",
					"description": "This side event will present the latest projects and breakthroughs from the organisers and partners in the field of circularity and resource efficiency within the built environment. Likewise, it will have a sub-focus on socio-economic sustainability within the housing sector. The event will include achievements and new tools aimed at strengthening the capacity of national stakeholders in building the path towards a more sustainable buildings sector and housing. Themes that this side event will address include circularity, resource and energy efficiency, innovation, and affordability. The side event is being proposed by UN-Habitat and brings as partners; the One Planet Network Sustainable Buildings and Constructions Programme; UNEP and UNOPS, HILTI Foundation.The agencies mentioned above have developed a set of tools to raise awareness of national stakeholders in sustainable consumption and production within the construction sector, have built a roadmap towards the decarbonization, resiliency and sustainability of two countries (Burkina Faso and Sri Lanka) with a focus on materials and housing programmes, and have created a housing Value Assessment Methodology to strengthen the capacity of national stakeholders in evaluating and improving their housing programmes towards resource efficiency, circularity and sustainability. The set of tools have been piloted in Sri Lanka and Burkina Faso, the tools and lessons learnt from the countries will be presented during the event.",
					"email": "jesus.salcedo@un.org",
					"language": "English ",
					"objectives": "The main objectives of the event are:<p></p>1. To strengthen the programmes network of partners with bamboo and ratan related industries, think-tanks, and overall stakeholders. <p></p>2. To raise awareness regarding sustainable buildings and the construction sector, with an emphasis on material efficiency, identify gaps and provide tools to strengthen the capacity of stakeholders.<p></p>3. To support governments in transforming their buildings and construction sector, particularly the housing sector, in a resource-efficient, low carbon and climate-resilient manner, through coherent policy support and results-focused programming.<p></p>4. Contribute through the UN country presence to develop and strengthen the productive links among construction sector stakeholders for the efficient use of resources from a circular economy perspective. <p></p>5. Build a case on the importance of the material value chain within the buildings and construction sector, and how housing can be a catalyzer of change.<p></p>6. Present best practices, lessons learnt and opportunities of applying the set of tools from SDG12 Resource-efficient housing in Burkina Faso and Sri Lanka.",
					"preferredFormat": "Online ",
					"roomAllocation": "950 0242 3197\n",
					"sessionTitle": "S1.8 RE-Think Buildings",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630738bc012176606a497a50",
					"noe": true
				},


			],
			[{},
				{

					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR) ",
					"conveningOrganizations1": 'Institute of Bamboo and Rattan Biomass and New Materials, ICBR<br>China Tanboocel Union (CTBU)<br>Institute of Chemical Industry of Forest Products, CAF<br>Engineering Research Center of Bamboo Advanced Materials and Conversion of Jiangxi Province, Gannan Normal University',
					"date": "8 November",
					"description": "With the attention and improvement of people's awareness of environmental protection, the R & D and industrialization of bamboo fiber products have been developed rapidly. According to the preparation technology and chemical composition, bamboo fiber can be divided into natural bamboo fiber and bamboo viscose fiber. According to the fiber length, bamboo fiber can be divided into short fiber, long fiber and continuous fiber. According to the purpose, bamboo fiber can be divided into textile bamboo fiber and non-textile bamboo fiber. At present, bamboo fiber has been developed and applied in the textile industry, pulping and papermaking, household products, automobile transportation, building panels and other composite materials. Through this meeting, we hope to make a detailed interpretation of the characteristics of bamboo fiber, such as rich resources, excellent performance, health and sustainability.",
					"email": "htcheng@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": `The characteristics of bamboo fiber are brought into full play, and the processing technology is constantly innovated. The scientific and technological content and added value of bamboo fiber and its products have been improved, forming the development road of circular economy, and developing healthily and rapidly in the direction of "internationalization, greening and high - grade ".`,
					"preferredFormat": "Online ",
					"roomAllocation": "986 4781 4002\n",
					"sessionTitle": "S3.7  Green and Sustainable Development of Bamboo Fiber Industry\n",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<br>Thematic area 3.Innovative Materials and Market Development",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072c8d6e825d59973d272b",
					"zcdw": `Bamboo fiber industry network<br>Chongqing Ruizhu Plant Fiber Products Co., Ltd.<br>Guangdong Yufeng Bamboo Fiber Co., Ltd<br>Guangdong Zhukang Material Technology Co., Ltd.<br>Hebei Jigao Chemical Fiber Co., Ltd<br>Qizhu(Beijing) Technology Development Co.,Ltd`,
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.7 Aganda.pdf',
						title: 'S3.7 Aganda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "Bamboo Branch, Chinese Society of Forestry ",
					"conveningOrganizations1": 'Sichuan Agricultural University (SAU)<br>Research Institute of Subtropical Forestry, Chinese Academy of Forestry (CAF-RISF)<br>International Centre for Bamboo and Rattan (ICBR)<br>Nanjing Forestry University (NJFU)<br>Southwest Forestry University (SWFU)<br>Zhejiang A&F University (ZAFU)<br>Zhejiang International Science and Technology Cooperation Base for Bamboo Cultivation and Utilization',
					"date": "8 November",
					"description": "In order to implement Xi Jinping's speech spirit of \"developing bamboo industry in accordance with local conditions\" during his visit to Sichuan and other bamboo producing areas, this forum will be held under the theme of \"the innovative development of under-bamboo forest economy and health care industry with bamboo\" in the   background of the normalized prevention and control of COVID-19 and the national strategy of \"carbon neutrality and carbon peak\".  Focusing on the development trend of under-bamboo forest economy and bamboo health care industry, the conference will discuss and exchange on the high-quality development of bamboo economy and its high-efficient and innovative cultivation mode, the high-quality and diversified utilization of economic bamboo forest products and its innovative development,  evaluation on bamboo health care base and construction technology of multifunctional health care base and under-bamboo forest economy product market development. The conference will propose a path for the high-quality and healthy development of China's under-bamboo forest economy and health care industry with bamboo forest, providing technical support for rural revitalization and \"common prosperity\".",
					"email": "310023939@qq.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The conference will focus on the development trend of under-bamboo forest economyand health care industry with bamboo forest. The conference will discuss and exchange onthe high-quality development of bamboo economy and its high-efficient and innovative cultivation mode, the high-quality and diversified utilization of economic bamboo forest products and its innovative development,  evaluation on bamboo health care base and construction technology of multifunctional health care base and under-bamboo forest economy product market development. The conference will put forward China experience and model of high-quality and healthy development of bamboo economy and bamboo health industry, providing strong industrial support for rural revitalization  in an all-round way.",
					"preferredFormat": "Online ",
					"roomAllocation": "986 4781 4002\n",
					"sessionTitle": " S2.5 Bamboo Under-Forest Economy and Forest Recreation Industry Development Forum\n",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>",
					"timeAllocation": "10:30-12:10  (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072bfca3a6654f964f3535",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.5 Agenda.pdf',
						title: 'S2.5 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "Bamboo Branch, Chinese Society of Forestry",
					"conveningOrganizations1": 'Bamboo Industry Association of Yuhang District, Hangzhou, China (National Small Path Bamboo Shoot Industry Service Station)<br>Research Institute of Subtropical Forestry, Chinese Academy of Forestry (CAF-RISF)<br>Nanjing Forestry University (NJFU)<br>International Centre for Bamboo and Rattan (ICBR)<br>Zhejiang Academy of Forestry Science (National Innovation Alliance for Bamboo Shoot and Bamboo Industry)<br>China National Bamboo Research Center <br>Zhejiang International Science and Technology Cooperation Base for Bamboo Cultivation and Utilization',
					"date": "8 November",
					"description": "In order to implement Xi Jinping's speech spirit of \"developing bamboo industry in accordance with local conditions\" during his visit to Sichuan and other bamboo producing areas, this meeting will be held under the theme of \"Innovation and Development of bamboo shoot industry\" in the background of the normalized prevention and control of COVID-19 and the national strategy of \"carbon neutrality and carbon peak\". Focusing on the development trend of the world bamboo shoot industry, the conference will conduct discussions and exchanges on the exploration and efficient cultivation of high-quality bamboo shoot resources, technological innovation of diversified utilization of bamboo shoot with high value, as well as the construction of industrial chain and supply chain. The meeting will propose a path for the high-quality and healthy development of China's medium-small sized bamboo shoot industry, and provide technical support for rural revitalization and \"common prosperity\".",
					"email": "2602519333@qq.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Around the problems and tendency in the development of the medium-small sized bamboo shoot industry in China, the meeting will focus on the local bamboo shoot resources development, management technology of  bamboo shoot , high value diversified utilization of bamboo resources and the construction of industrial chain and supply chain. The conference will put forward China's experience of high-quality and healthy development of medium-small bamboo shoot industry, and provide a development model for rural revitalization and \"common prosperity\" in bamboo producing areas.",
					"preferredFormat": "Online ",
					"roomAllocation": "986 4781 4002\n",
					"sessionTitle": "S2.3  Forum on the Development of Medium and Small Sized Bamboo Shoots Industry  ",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery",
					"timeAllocation": "13:30-15:10  (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072abc92ee48582b4c8f95",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.3 Agenda.pdf',
						title: 'S2.3 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "United Nations Industrial Development Organization(UNIDO) ",
					"conveningOrganizations1": 'African Union Commission / African Union Development Agency – New Partnerships for African Development (AUC / AUDA - NEPAD)',
					"date": "8 November",
					"description": "Bio-based industries can bring considerable industrial development opportunities to Africa, since they utilize highly regenerative resources. Bamboo provides particular opportunities in this regard thanks to the fast growth rate, resilience and versatility, which enables its applications ranging from construction material and furniture, to food-, fiber-, and energy sector use.This session will highlight examples of bamboo value chain development and their contribution to inclusive and sustainable industrial development. Challenges and critical success factors will be discussed to synthesize how lessons learned can be transferred across geographies and to different regulatory settings. Further references will be made to strategies for a transformation towards a circular bio-economy in the global context.",
					"email": "n.schulz@unido.org",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "1. Showcase successful examples of bamboo value chain development<p></p>2. Illustrate resource supply side, value addition, market development, specific c0ontextual challenges and lessons learned.<p></p>3. Identify to what extent the case is replicable in other contexts.<p></p>4. Highlight contributions to the inclusive and sustainable industrial development trajectory, and potential opportunities for collaboration.<p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "986 4781 4002\n",
					"sessionTitle": "S4.8  Bamboo Value Chains for Inclusive and Sustainable Industrial Development in Africa ",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>Thematic area 4. Working Together for Inclusive and Green Development ",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630737e78ed6e8390b50733d",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.8 Agenda.pdf',
						title: 'S4.8 Agenda.pdf',
					}, ]
				}
			],
			[{},
				{
					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR)<br>University of British Columbia (UBC)",
					"conveningOrganizations1": 'Sanya Research Base, International Centre for Bamboo and Rattan (ICBR-SRB)<br>Institute of Resources and Environment, International Centre for Bamboo and Rattan (ICBR-IRE)<br>Institute of Garden Flower and Landscape, International Centre for Bamboo and Rattan (ICBR-IGFL)<br>National Ecological Research station of Sanya Bamboo and Rattan Associated Forest Ecosystem<br>China-Canada Joint Laboratory for Bamboo & Rattan Science and Technology',
					"date": "8 November",
					"description": "Bamboo has been developed and utilized to a large extent as a substitute for wood, plastics, steel bars and other materials, and thus to have huge carbon sink and emission reduction potential.t plays a positive role in promoting global green and sustainable developmentl. Therefore, the bamboo industry is a green, low-carbon and sustainable industry with great potential.",
					"email": "junweiluan@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The meeting is intended to jointly invite international experts in bamboo forest ecology, biodiversity conservation, climate change sustainable development,ecological science and art of bamboo and rattan flowers and other related fields to discuss the current international research status, shortcomings and development trends on how the bamboo forest ecosystem plays a role in poverty alleviation and sustainable development, and how to achieve carbon neutrality in the face of future climate change and explore the practice mode of sustainable innovation.",
					"preferredFormat": "Online  ",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S1.4  Bamboo Forest Biodiversity Conservation, Ecosystem Adaptive Management and Sustainable Development under the Vision of Carbon Neutrality",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fc7c132209125335af2a",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.4 Agenda.pdf',
						title: 'S1.4 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Centre for Bamboo and Rattan",
					"conveningOrganizations1": 'Institute of Bamboo and Rattan Biomass and New Materials<br>China Bamboo Industry Association<br>Zhejiang Academy of Forestry',
					"date": "8 November",
					"description": "Bamboo material is widely used in furniture, architecture and so on. To push on the application of bamboo products, the material innovation and high-quality development should be used as an effective way. We will focus on the innovation of bamboo engineering materials in this conference, to discuss the latest development, and improve the engineering bamboo system. ",
					"email": "maxx@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The objective is to discuss the technical and application problem during the producing of engineering bamboo. The new engineering bamboo products and application prospect will be share together. It is an important opportunity to push on the modern engineering bamboo process system. ",
					"preferredFormat": "Online ",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S3.4 Replacing Plastics with Bamboo: Innovation in Science and Technology of Engineering Bamboo",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "10:30-12:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fcf8bb066c67a9058641",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.4 Agenda.pdf',
						title: 'S3.4 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR)",
					"conveningOrganizations1": 'Institute of Resources and Environment, International Centre for Bamboo and Rattan (ICBR-IRE)<br>National Innovation Alliance for the Protection and Utilization of Bamboo Species Resources<br>National Center for Forestry Genetic Resources of Bamboo and Flowers, International Centre for Bamboo and Rattan<br>National Positioning and Monitoring Station for Ecosystem of Bamboo Forest of Yong‘an, Fujian',
					"date": "8 November",
					"description": "Bamboo and rattan resource cultivation is facing a series of challenges such as few high-quality germplasm and the cultivation technology does not meet the needs of social and economic development. How to further promote the sustainable development of bamboo and rattan cultivation through technological innovation will become a key issue.",
					"email": "fansh@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The session is aimed to build a worldwide exchange platform for experts and scholars in the development of bamboo and rattan resources exploration and cultivation. This conference will discuss innovative strategies for efficient cultivation and sustainable development of bamboo and rattan in the future from various perspectives such as germplasm exploration, seedling breeding and efficient management.",
					"preferredFormat": "Online ",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S2.6 Efficient Cultivation and Sustainable Development of Bamboo and Rattan Resources",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "13:30-15:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fd5c1b908026ea3b362f",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.6Agenda.pdf',
						title: 'S2.6Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR) ",
					"conveningOrganizations1": 'Institute of Gene Science and Gene Industrialization, International Bamboo and Rattan Center (ICBR-IGSGI)<br>Nanjing Forestry University (NJFU)<br>Zhejiang Agriculture and Forestry University (ZAFU)<br>Research Institute of Subtropical Forestry, Chinese Academy of Forestry (CAF-RISF)',
					"date": "8 November",
					"description": "Due to the long-term vegetative growth of bamboo and rattan and its special reproductive biological characteristics, the conventional breeding process is seriously hindered. Using modern biotechnology to break through the bottleneck of bamboo and rattan genetic transformation technology and establish a suitable genetic breeding system for bamboo and rattan has important practical significance for accelerating the process of bamboo and rattan breeding.",
					"email": "liying@icbr.ac.cn",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "Exchange the latest research progress on the genetics and breeding of bamboo and rattan, improve the genetics and breeding ability of bamboo and rattan, and accelerate the breeding process.",
					"preferredFormat": "Online ",
					"roomAllocation": "939 0210 1729",
					"sessionTitle": "S1.9 Basic Research on Bamboo and Rattan Genetics and Breeding",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6317fe8b6d94cc1b3456d0a7",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.9 Agenda.pdf',
						title: 'S1.9 Agenda.pdf',
					}, ]
				},
			],
			[{},
				{
					"filt": [{
							url: 'https://www.barc2022.inbar.int/barcfile/11.jpg',
							title: 'S3.1_Flyer_En-01-Organisers',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/22.jpg',
							title: 'S3.1_Flyer_En-02-Introduction',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/33.jpg',
							title: 'S3.1_Flyer_En-03-Committee',
						},
						{
							url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Opening Ceremony.pdf',
							title: 'S3.1 Agenda_Opening Ceremony.pdf',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
							title: 'S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
						}, {
							url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
							title: 'S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
						},
					],
					"conveningOrganizations": "International Bamboo and Rattan Organization (INBAR)<br>INBAR Bamboo Construction Task Force",
					"conveningOrganizations1": 'School of Civil Engineering, Tsinghua University<br>School of Architecture, Tsinghua University<br>Architectural Design and Research Institute, Tsinghua University<br>Nanjing Forestry University(NJFU)<br>National Provincial Joint Engineering Research Center of Biomaterials for Machinery Package, Nanjing Forestry University <br>School of Landscape Architecture, Beijing Forestry University <br>International Centre for Bamboo and Rattan (ICBR)',
					"date": "8 November",
					"description": "Bamboo has been traditionally used as a construction material, and with technology development, innovation in past decades, bamboo and other bio-based materials can be a nature-based solution to global housing shortages, mitigate climate change, and contribute to achieving carbon neutrality. Moreover, bamboo have proven itself to contribute to livelihood, economy and environmental development. <p></p>There have been considerable efforts from different stakeholders particularly engineers, architects, landscape architecture designers, researchers, standard agencies, government, non-government and private sector to advance bamboo as a conventional material. The proposed conference (2022 International Conference - Bamboo: A Very Sustainable Construction material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures) encompasses seven sessions, and particularly two sessions will be organised during BARC 2022 to deliberate on the potential uses and suitability of bamboo and other biomaterials as conventional construction materials. ",
					"email": "kwliu@inbar.int",
					"language": "Chinese, English and Spanish (simultaneous interpretation provided)",
					"objectives": "1.Deliberate and showcase the recent research, innovation and application of bamboo and other bio-based material as suitable construction material. <p></p>2.Provide a platform for researchers and practitioners to share the development of global bio-composite construction sector and its future perspectives.<p></p>3.Strengthen the communication between different stakeholders in the sector. <p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S3.1  2022 International Conference - Bamboo: A Very Sustainable Construction material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures \n",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>",
					"timeAllocation": "08:30-10:10  (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307282701366064107ba40a",
					"zcdw": `Akenten Appiah-Menka University<br>
Addis Ababa University<br>
College of Civil Engineering and Architecture, Zhejiang University<br>
College of Civil Engineering, Nanjing Tech University<br>
China Southwest Architecture Design and Research Institute Corp. Ltd<br>
Coventry University<br>
Deakin University<br>
ETH Zürich<br>
Faculty of Architecture, the University of Hong Kong<br>
Institut Teknologi Bandung <br>
Journal of Building Structure<br>
Journal of Landscape Architecture<br>
Journal of Sustainable Structures<br>
Journal of World Bamboo and Rattan<br>
Research Institute of Wood Industry, Chinese Academy of Forestry<br>
School of Architecture and Urban Planning, Chongqing University<br>
School of Civil Engineering, Southeast University<br>
School of Design, Virginia Tech<br>
Shanghai Research Institute of Building Sciences<br>
The Hong Kong Institute of Architects<br>
Tongji University<br>
University of Pittsburgh<br>
University of the West of England<br>
Zhejiang University - University of Illinois at Urbana-Champaign Institute, Bio-based Zero-CO2 R&D Center, Ninghai`,
					"zzs": `Platinum Sponsor:<br>
Hongyazhuyuan Science and Technology Company<br>
<br>
Sponsor (Alphabetical order):<br>
Anji Zhujing Bamboo Industry Technology Co., Ltd.<br>
Ganzhou Sentai Bamboo & Wood Co., Ltd.<br>
Hangzhou Bamboo Technology Company<br>
Hunan Taohuajiang Bamboo Science and Technology Co., Ltd.<br>
Jiangsu Jianzhu Green Bamboo Construction Technology Co., Ltd.`
				},
				{
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/11.jpg',
						title: 'S3.1_Flyer_En-01-Organisers',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/22.jpg',
						title: 'S3.1_Flyer_En-02-Introduction',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/33.jpg',
						title: 'S3.1_Flyer_En-03-Committee',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Opening Ceremony.pdf',
						title: 'S3.1 Agenda_Opening Ceremony.pdf',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
						title: 'S3.1 Agenda_Session 1_Exemplary architectural design of bio-based materials.pdf',
					}, {
						url: 'https://www.barc2022.inbar.int/barcfile/S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
						title: 'S3.1 Agenda_Session2_Bio-Composite Materials for Contemporary Construction Sector.pdf',
					}, ],
					"conveningOrganizations": "International Bamboo and Rattan Organization (INBAR)<br>INBAR Bamboo Construction Task Force",
					"conveningOrganizations1": 'School of Civil Engineering, Tsinghua University<br>School of Architecture, Tsinghua University<br>Architectural Design and Research Institute, Tsinghua University<br>Nanjing Forestry University(NJFU)<br>National Provincial Joint Engineering Research Center of Biomaterials for Machinery Package, Nanjing Forestry University <br>School of Landscape Architecture, Beijing Forestry University <br>International Centre for Bamboo and Rattan (ICBR)',
					"date": "8 November",
					"description": "Bamboo has been traditionally used as a construction material, and with technology development, innovation in past decades, bamboo and other bio-based materials can be a nature-based solution to global housing shortages, mitigate climate change, and contribute to achieving carbon neutrality. Moreover, bamboo have proven itself to contribute to livelihood, economy and environmental development. <p></p>There have been considerable efforts from different stakeholders particularly engineers, architects, landscape architecture designers, researchers, standard agencies, government, non-government and private sector to advance bamboo as a conventional material. The proposed conference (2022 International Conference - Bamboo: A Very Sustainable Construction material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures) encompasses seven sessions, and particularly two sessions will be organised during BARC 2022 to deliberate on the potential uses and suitability of bamboo and other biomaterials as conventional construction materials. ",
					"email": "kwliu@inbar.int",
					"language": "Chinese, English and Spanish (simultaneous interpretation provided)",
					"objectives": "1.Deliberate and showcase the recent research, innovation and application of bamboo and other bio-based material as suitable construction material. <p></p>2.Provide a platform for researchers and practitioners to share the development of global bio-composite construction sector and its future perspectives.<p></p>3.Strengthen the communication between different stakeholders in the sector. <p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S3.1  2022 International Conference - Bamboo: A Very Sustainable Construction material & the 3rd World Symposium on Sustainable Bio-Composite Materials and Structures \n",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>",
					"timeAllocation": "10:30-12:10  (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307282701366064107ba40a",
					"zcdw": `Akenten Appiah-Menka University<br>
Addis Ababa University<br>
College of Civil Engineering and Architecture, Zhejiang University<br>
College of Civil Engineering, Nanjing Tech University<br>
China Southwest Architecture Design and Research Institute Corp. Ltd<br>
Coventry University<br>
Deakin University<br>
ETH Zürich<br>
Faculty of Architecture, the University of Hong Kong<br>
Institut Teknologi Bandung <br>
Journal of Building Structure<br>
Journal of Landscape Architecture<br>
Journal of Sustainable Structures<br>
Journal of World Bamboo and Rattan<br>
Research Institute of Wood Industry, Chinese Academy of Forestry<br>
School of Architecture and Urban Planning, Chongqing University<br>
School of Civil Engineering, Southeast University<br>
School of Design, Virginia Tech<br>
Shanghai Research Institute of Building Sciences<br>
The Hong Kong Institute of Architects<br>
Tongji University<br>
University of Pittsburgh<br>
University of the West of England<br>
Zhejiang University - University of Illinois at Urbana-Champaign Institute, Bio-based Zero-CO2 R&D Center, Ninghai`,
					"zzs": `Platinum Sponsor:<br>
Hongyazhuyuan Science and Technology Company<br>
<br>
Sponsor (Alphabetical order):<br>
Anji Zhujing Bamboo Industry Technology Co., Ltd.<br>
Ganzhou Sentai Bamboo & Wood Co., Ltd.<br>
Hangzhou Bamboo Technology Company<br>
Hunan Taohuajiang Bamboo Science and Technology Co., Ltd.<br>
Jiangsu Jianzhu Green Bamboo Construction Technology Co., Ltd.`
				},
				{
					"conveningOrganizations": "International Bamboo and Rattan Organization (INBAR)",
					"conveningOrganizations1": `International Centre for Bamboo and Rattan<br>International Finance Corporation<br>Meishan Municipal People's Government, Sichuan <br>Qionglai Municipal People's Government, Sichuan province<br>Taojiang County People's Government, Hunan province<br>Ningbo Shilin Handicrafts Co. Ltd., Zhejiang province`,
					"date": "8 November",
					"description": "Bamboo industry has become an important pillar industry for farmers in bamboo-producing areas to increase their income and become rich, and for economic and social development to help implement the rural revitalization strategy. Leading bamboo enterprises have a deep influence, appeal, and a certain demonstration and guiding role in the development of the bamboo industry on other enterprises in the same industry, and make outstanding contributions to the local and bamboo industry. The session will be a gather of local government officials in bamboo-producing areas, leading bamboo enterprises and experts in green finance, etc. to discuss and share their experiences on the role of bamboo in rural development, leading bamboo enterprises lead innovation and development, and green finance serves the development of bamboo industry.",
					"email": "Jfu@inbar.int",
					"language": "Chinese–English (with interpretation)",
					"objectives": "Leading the development of the bamboo industry through leading enterprises, implementing the integrated development of primary, secondary, and tertiary bamboo industries, innovating green finance to serve the bamboo industry, and implementing bamboo rural revitalization.",
					"preferredFormat": "Online ",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S4.2  Bamboo Industry Development Forum of Local Governments and Leading Enterprises",
					"thematicArea": "Thematic Area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "13:30-15:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=631988328070fa3dfa7a2659",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.2 Agenda.pdf',
						title: 'S4.2 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "China National Bamboo Research Center<br>Bamboo Food and Commodity Branch, China Bamboo Industry Association (CBIA-BFCB)<br>Bamboo  Industry (Jian'ou) Branch, Fujian Provincial Collaborative Innovation Institute",
					"conveningOrganizations1": 'Fujian Mingliang Food Co., Ltd., China<br>Chongqing Tezhen Food Co., Ltd.<br>Zhejiang Shengshi Biological Technology Co., Ltd., China',
					"date": "8 November",
					"description": "Bamboo shoots are edible, tender buds developed from the lateral bud of bamboo rhizome or base. Owning to the richness in nutrients of dietary fiber, protein, polysaccharide, minerals, ect., and low in fat, bamboo shoots are excellent natural green vegetable and food materials that used traditionally across Asia, Africa and Latin America, and attract increasing attention and efforts in functional foods development with modern technologies. With global annual yields of fresh shoots over 55 million tons, bamboo shoot based food industry is booming across globe for human heathier nutrient supplementary and uptake.<p></p>Meeting will focus on 4 points: (1) edible history of bamboo shoots; (2) nutrition, bioactive compounds and pharmacology basis of bamboo shoots; (3) bamboo shoot based food R&D; (4) bamboo shoot food and human health. The session aims to share the latest research results on the nutrients and bioactive compounds in bamboo shoot and their associated health benefits, exchange innovations and progress on shoot-based products and novel utilizations in last decade, and address challenges of bamboo shoot as future food.<p></p>",
					"email": "boteatree@163.com",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "The conference aims to fully tap the potential of bamboo shoots as future food by sharing the latest research results and exchanging product innovations and advances. In addition, the conference will raise global public awareness of bamboo shoot consumption, especially in developed countries, and promote global cooperative research and technology development in bamboo shoot processing and utilization.",
					"preferredFormat": "Online ",
					"roomAllocation": "961 7631 0060",
					"sessionTitle": "S3.2 Advances in Bamboo Shoot Based Functional Foods Development and Its Health Benefits\n",
					"thematicArea": "Thematic area 3. Innovative Materials and Market Development<p></p>",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=6307288bd1b679258114287a",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S3.2 Agenda.pdf',
						title: 'S3.2 Agenda.pdf',
					}, ]
				},
			],
			[{},
				{
					"conveningOrganizations": "United Nations Forum on Forests (UNFF) Secretariat ",
					"conveningOrganizations1": '',
					"date": "8 November",
					"description": `The United Nations Strategic Plan for Forests 2017-2030 (UNSPF) states that, among the objectives of the International Arrangement on Forests is the fostering of international cooperation, including North-South, South-South, North-North, and triangular cooperation, as well as public-private partnerships and cross-sectoral cooperation, at all levels and that “(…) The individual and collective actions and commitments of members of the United Nations Forum on Forests (UNFF) are decisive for the successful implementation of the strategic plan and achievement of its global forest goals and targets”. The same is true for the Forum’s partners. The International Bamboo and Rattan Organization – INBAR is a valuable partner of the United Nations Forum on Forests - UNFF and its Secretariat. This partnership between entities can also be seen in the complementarity that exists between forests and the cultivation of bamboo and rattan, which are mutually beneficial and can contribute to a green economy that conserves nature and promotes the health of animals, the planet, and human beings. The theme of the Second Global Bamboo and Rattan Congress – BARC 2022 is “Bamboo and Rattan-Nature-based Solutions for Sustainable Development”. The Congress’ Thematic Area 4 is “Working Together for Inclusive and Green Development” and focuses on the significance of international cooperation and exchanges to support sustainable development.
					<br> One of the main objectives of the International Arrangement on Forests is to promote sustainable management of all types of forests and trees outside forests.As the world tries to move on from a pandemic that started over two years ago, lessons are learned that should be immediately applied.Our planet needs healing and contributing to its health assures mankind’ s future.In this respect, the sustainable management of forests and the cultivation of bamboo and rattan will
					continue to help improve our planet’ s health, which is directly related to the health of animals and human beings.Contributing to the achievement of the Sustainable Development Goals and the Global Forest Goals is, therefore, very important and timely.
					<br>
					The United Nations Forest Instrument(UNFI) and the United Nations Strategic Plan
					for Forests(UNSPF) have been contributing to raising awareness of the importance of sustainable forest management, which improves the quality of life and health of the people who depend on it.To promote concrete actions on the ground and mobilize financing to that end, the United Nations Forum on Forests established the Global Forest Financing Facilitation Network(GFFFN) in 2015 to assist countries in mobilizing financing
					for sustainable forest management and to contribute to advancing the implementation of the UNSPF.Since its establishment, the GFFFN has assisted 34 countries and two sub - regions to design national / regional forest financing strategies or mobilizing financing
					for UNSPF.More than 1000 forestry professionals around the world have benefited from the training on mobilizing forest financing provided by the GFFFN.The GFFFN’ s Clearing House also provides up - to - date information on forest financing opportunities, learning materials, and good practices.Yet the gap in financing
					for forests remains significant.To that end, more actions must be taken, and more forces should be rallied.
					`,
					"email": "Tavora-Jainchill@un.org",
					"language": "Chinese–English (with interpretation)",
					"objectives": `(1) Explore concrete measures to support countries in implementing the UNSPF through resource mobilization. <br>(2) 	Share the work experience of the GFFFN in assisting member states in mobilizing resources for sustainable forest management. 
					<br>(3) Explore synergies and opportunities for collaboration with INBAR and other international partners for resource mobilization for sustainable forest management. `,
					"preferredFormat": "Online ",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S4.4  Strengthening Resource Mobilization for Sustainable Forest Management and Forest Restoration",
					"thematicArea": "Thematic area 4. Working Together for Inclusive and Green Development",
					"timeAllocation": "08:30-10:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63073728a9ea220a1d494ef7",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S4.4 Agenda.pdf',
						title: 'S4.4 Agenda.pdf',
					}, ]
				},
				{

					"conveningOrganizations": "United Nations Educational, Scientific and Cultural Organization (UNESCO) Beijing Office",
					"conveningOrganizations1": 'International Bamboo and Rattan Organization (INBAR)',
					"date": "8 November",
					"description": "On the occasion of the 50th anniversary of the Convention Concerning the World Cultural and Natural Heritage, UNESCO is leading “The Next 50” global initiative to discuss World Heritage as a source of resilience, humanity and innovation in the next 50 years. Climate change is becoming one of the most significant threats to World Heritage; World Heritage also harbor options for society to mitigate and adapt to climate change. As part of UNESCO’s “New Horizons of Heritage Conservation” webinar series, this session fosters dialogue on efforts and good practices on Bamboo for Carbon Neutrality in Rural Areas which showcase Carbon Reduction and Low Carbon Development in World Heritage Sites.",
					"email": "y.lu@unesco.org<p></p>yxli@inbar.int",
					"language": "Chinese–English (with interpretation) ",
					"objectives": "1.To celebrate the 50th anniversary of the UNESCO Convention Concerning the Protection of the World Cultural and Natural World Heritage, and to raise public awareness on the role of culture and heritage for rural revitalization, climate change and sustainable development.<p></p>2.To create a platform for stakeholders to exchange and share cutting-edge knowledge and ground experiences on carbon reduction and low-carbon development at World Heritage site based on the bamboo resources. <p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S1.6 Bamboo for Carbon Neutrality in Rural Areas: New Pathways for Carbon Reduction and Low Carbon Development in World Heritage Sites\n\n",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality ",
					"timeAllocation": "10:30-12:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072b8064e9620155454011",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.6Agenda.pdf',
						title: 'S1.6Agenda.pdf',
					}, ]

				},
				{
					"conveningOrganizations": "Food and Agriculture Organization of the United Nations (FAO) ",
					"conveningOrganizations1": 'International Bamboo and Rattan Organization (INBAR)',
					"date": "8 November",
					"description": "Despite the recognized range of benefits and potential, in many countries bamboo has often not been managed sustainably or used effectively. The main reasons are related to the lack information about countries’ bamboo resources, insufficient knowledge about bamboo uses, as well as about its ecosystem service values, particularly regarding climate change.<p></p>Bamboo has an estimated area of 35 million ha (FAO’s FRA 2020) and more than 1 600 species recorded (INBAR 2017) however FAO’s Forest Resource Assessment in 2020 showed that only 23 countries reported information on bamboo resources, despite methodologies and technical tools are available for the assessment of bamboo forest cover, stock and carbon storage.<p></p>Knowledge of the status and extent of bamboo resource is crucial for sustainable management and harvesting of bamboo forests and to properly capitalize on their potential.<p></p>",
					"email": "Marco.piazza@fao.org",
					"language": "English ",
					"objectives": "This session will explore current status and opportunity for the assessment of bamboo resources and related ecosystem services at global and national (local) level.",
					"preferredFormat": "Online ",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S1.2  Assessment of Bamboo Resources and Ecosystems Services\n",
					"thematicArea": "Thematic area 1. The Road to Carbon Neutrality <p></p>",
					"timeAllocation": "13:30-15:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63072218fde6f913b92cebc6",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S1.2 Agenda.pdf',
						title: 'S1.2 Agenda.pdf',
					}, ]
				},
				{
					"conveningOrganizations": "International Centre for Bamboo and Rattan (ICBR) ",
					"conveningOrganizations1": 'University of British Columbia (UBC)<br>Forestry Research Institute of Ghana (CSIR) ',
					"date": "8 November",
					"description": "Rattans are climbing palms belonging to the Palm familiy (Palmae or Arecaceae). There are around 650 different species of rattan in 13 genera, concentrated solely in the Old World tropics of Africa and Asia.<p></p>The world trade of rattan raw materials and woven rattan alone amounted to USD 0.38 billion (INBAR, 2019). It is estimated that more than 700 million people worldwide trade in or use rattan for a variety of purposes, and the global trade (domestic and export) and subsistence value of rattan and its products exceeds USD 7,000 million per annum (Pabuayon, 2000; Sastry, 2000; Soedarto, 1999).<p></p>It is estimated that approximately 20% of all rattan species are used commercially in the furniture industry or for matting and basketry, and during the 1970s, Indonesia fulfilled about 90% of the world’s rattan requirements (Dransfield and Manokaran, 1994). Globally, the furniture and handicraft industry mainly depend on resources from the secondary and virgin forests for rattan supply. Deforestation, overexploitation and habitat modification have caused the supply of raw rattans for the furniture industry to dwindle, and most rattan industries are experiencing setbacks.",
					"email": "rkaam@inbar.int <p></p>terry.sunderland@ubc.ca<p></p>bdobiri@csir-forig.org.gh",
					"language": "English ",
					"objectives": "It is becoming increasingly clear that most of the world’s rattan resources are already under threat due to unsustainable harvesting practices and deforestation, leading to a loss of germplasm and even the threat of extinction of some species.<p></p>To achieve sustainability, it is essential that scientific management of the rattan resources that permits the regeneration of stocks and rational harvesting levels be implemented. The objective of this parallel session are threefold:<p></p>1. Improve the better understanding of natural rattan including taxonomy and regeneration, and rattan resource development, and provide suggestions on how to sustainably manage rattan resources in the world,<p></p>2. Assess the and quantify the potential and actual contribution of rattan to the livelihood of local populations and global trade of rattan,<p></p>3. Understand the technological advancement in the processing of high-end rattan manufacture products and innovative applications of rattan canes.<p></p>",
					"preferredFormat": "Online ",
					"roomAllocation": "945 9685 5046",
					"sessionTitle": "S2.1  The Ecological and Socio-economic Contribution of Rattan to People within the Context of Global Deforestation and Climate Change\n",
					"thematicArea": "Thematic area 2. Green Industry and Economic Recovery<p></p>",
					"timeAllocation": "15:30-17:10 (GMT+8)",
					"liveBroadcast": "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=630736cfa9ea220a1d494c66",
					"filt": [{
						url: 'https://www.barc2022.inbar.int/barcfile/S2.1Agenda.pdf',
						title: 'S2.1Agenda.pdf',
					}, ]
				},
			]
		]
	},
	// cA: [{
	// 	date: '7 November 2022',
	// 	time: ['14:30-15:30', '15:30-16:00', '16:00-17:30', '17:30-18:30', '18:30-18:40', '19:00-20:30'],
	// 	title: ['Opening Ceremony of INBAR’s 25th Anniversary and BARC 2022 (Convention Hall No.1, 2nd Floor)', 'Break', 'Keynote Plenary Session (Convention Hall No.5, 1st Floor)', 'Ambassadors Dialogue (Convention Hall No.5, 1st Floor)', 'Signing Ceremony (Convention Hall No.5, 1st Floor)', 'Reception (Convention Hall No.1, 2nd Floor)']
	// }, {
	// 	date: '8 November 2022',
	// 	time: ['08:30-10:10', '10:10-10:30', '10:30-12:10', '12:10-13:30', '13:30-15:10', '15:10-15:30', '15:30-17:10', '17:30-18:15', '18:30-19:30'],
	// 	title: ['Parallel Sessions (9)', 'Tea Break', 'Parallel Sessions (9)', 'Buffet Lunch', 'Parallel Sessions (9)', 'Tea Break', 'Parallel Sessions (9)', 'Closing Ceremony (Convention Hall No.3, 1st Floor)', 'Buffet Dinner']
	// }],
	cA: [{
		date: '7 November 2022',
		time: ['15:00-16:00', '16:15-17:15', '17:15-18:45', ],
		title: ['Opening Ceremony', 'Ambassadors Dialogue', 'Keynote Plenary Session']
	}, {
		date: '8 November 2022',
		time: ['08:30-17:10', '17:30-18:00'],
		title: ['Parallel Sessions', 'Closing Ceremony']
	}],
	click: `You can click the event name below to watch the live broadcast`,
	exh: {
		zuzi: {
			title: "INBAR",
			img: [
				require("../../assets/images/exhibition/国际竹藤组织/1-5.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-1.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-2.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-3.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-4.png"),
				require("../../assets/images/exhibition/国际竹藤组织/1-6.png"),
			],
		},
		list: [{
				title: "INBAR",
				title2: "International Bamboo and Rattan Organization (INBAR)",
				gs: [{
						title: "<span style='opacity: 0;' class='yidong'>Science an</span>Bamboo Exhibition Hall<span style='opacity: 0;' class='yidong'>Science a</span>",
						img: [
							require("../../assets/images/exhibition/0/1-5.png"),
							require("../../assets/images/exhibition/0/1-1.png"),
							require("../../assets/images/exhibition/0/1-2.png"),
							require("../../assets/images/exhibition/0/1-3.png"),
							require("../../assets/images/exhibition/0/1-4.png"),
							require("../../assets/images/exhibition/0/1-6.png"),
						],
					},
					{
						title: "<span style='opacity: 0;' class='yidong'>Science an</span>Introduction to INBAR<span style='opacity: 0;' class='yidong'>Science an</span>",
						img: [
							require("../../assets/images/exhibition/0/2-1.jpg"),
							require("../../assets/images/exhibition/0/2-2.jpg"),
						],
					},
					{
						title: "Bamboo as a Substitute for Plastic Initiative",
						img: [
							require("../../assets/images/exhibition/0/3-1.jpg"),
							require("../../assets/images/exhibition/0/3-2.jpg"),
						],
					},
				],
			}, {
				title: "Science and Technology of Bamboo and Rattan",
				gs: [{
						title: 'Science and Technology of Bamboo and Rattan',
						img: [
							require("../../assets/images/exhibition/1/0-1.jpg"),
							require("../../assets/images/exhibition/1/0-2.jpg"),
							require("../../assets/images/exhibition/1/0-3.jpg"),
							require("../../assets/images/exhibition/1/0-4.jpg"),
							require("../../assets/images/exhibition/1/0-5.jpg"),
							// require("../../assets/images/exhibition/1/0-6.jpg"),
							// require("../../assets/images/exhibition/1/0-7.jpg"),
							// require("../../assets/images/exhibition/1/0-8.jpg"),
							// require("../../assets/images/exhibition/1/0-9.jpg"),
							// require("../../assets/images/exhibition/1/0-10.jpg"),
							// require("../../assets/images/exhibition/1/0-11.jpg"),
							// require("../../assets/images/exhibition/1/0-12.jpg"),
							// require("../../assets/images/exhibition/1/0-13.jpg"),
							// require("../../assets/images/exhibition/1/0-14.jpg"),
							// require("../../assets/images/exhibition/1/0-15.jpg"),
							// require("../../assets/images/exhibition/1/0-16.jpg"),
							// require("../../assets/images/exhibition/1/0-17.jpg"),
							// require("../../assets/images/exhibition/1/0-18.jpg"),
							// require("../../assets/images/exhibition/1/0-19.jpg"),
							// require("../../assets/images/exhibition/1/0-20.jpg"),
							// require("../../assets/images/exhibition/1/0-21.jpg"),
						]
					}, {
						title: `<span style='opacity: 0;' class='yidong'>Science and and</span> Exhibitors <span style='opacity: 0;' class='yidong'>Science and and</span>`,
						img: [
							require("../../assets/images/exhibition/1/2.jpg"),
							require("../../assets/images/exhibition/1/1.jpg"),
						]
					},
					// {
					// 	title: "International Centre for Bamboo and Rattan",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/1-1.png"),
					// 	],
					// },
					{
						title: "Promotion Video of Bamboo and Rattan ",
						video: 1,
						img: [

						],
					}, {
						title: "Exhibition of Bamboo and Rattan Products",
						video: 2,
						img: [

						],
					}, {
						title: "Scientific and Technological Achievement",
						img: [
							require("../../assets/images/exhibition/1/c1.jpg"),
							require("../../assets/images/exhibition/1/c2.jpg"),
						],
					},
					// {
					// 	title: "China Bamboo Industry Association",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/2-1.png"),
					// 	],
					// },
					// {
					// 	title: "China Eco-Culture Association",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/3-1.png"),
					// 	],
					// },
					// {
					// 	title: "China Flower Association",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/4-1.png"),
					// 	],
					// },
					// {
					// 	title: "BRRU Branch of CSF",
					// 	img: [
					// 		require("../../assets/images/exhibition/1/5-1.png"),
					// 	],
					// },
				],
			}, {
				title: "Yong'an, Fujian",
				title1: "Yong'an Municipal People's Government, Fujian Province",
				gs: [{
						title: "Yong'an Municipal People's Government, Fujian Province",
						img: [
							require("../../assets/images/exhibition/2/1-1.jpg"),
							require("../../assets/images/exhibition/2/1-2.jpg"),
						],
					},
					{
						title: "Fujian HeQiChang Bamboo Industrial Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/2/2-1.jpg"),
						],
					},
					{
						title: "Fujian Bayi Yongqing Bamboo Development Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/2/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "Meishan, Sichuan",
				title1: "Meishan Municipal People's Government, Sichuan Province",
				gs: [{
					title: "Meishan Municipal People's Government, Sichuan Province",
					img: [
						require("../../assets/images/exhibition/3/1-1.jpg"),
					],
				}, {
					title: "Qingshen International Bamboo Art City Development and Investment Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/3/2-1.jpg"),
					],
				}, ],
			},
			{
				title: "Yibin, Sichuan",
				title1: "Yibin Municipal People's Government, Sichuan Province",
				gs: [{
						title: "Yibin Municipal People's Government, Sichuan Province",
						img: [
							require("../../assets/images/exhibition/4/1-1.jpg"),
						],
					},
					{
						title: "Yibin Xiangtai Environmental Protection Technology Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/4/2-1.jpg"),
						],
					},
					{
						title: "Sichuan Shunan Culture & Tourism Group Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/4/3-1.jpg"),
						],
					},
					{
						title: "Yibin Paper Industry Co., Ltd.  ",
						img: [
							require("../../assets/images/exhibition/4/4-1.jpg"),
						],
					},
				],
			},
			{
				title: "Zixi, Jiangxi",
				title1: "Zixi County People's Government, Jiangxi Province",
				gs: [{
						title: "Zixi County People's Government, Jiangxi Province",
						img: [
							require("../../assets/images/exhibition/5/1-1.jpg"),
							require("../../assets/images/exhibition/5/1-2.jpg"),
						],
					},
					{
						title: "Jiangxi Zhuangchi Household Technology Co., Ltd.  ",
						img: [
							require("../../assets/images/exhibition/5/2-1.jpg"),
						],
					},
					{
						title: "Suncha Technology Co., Ltd.  ",
						img: [
							require("../../assets/images/exhibition/5/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "Qingyuan, Zhejiang",
				title1: "Qingyuan County People's Government, Zhejiang Province",
				gs: [{
						title: "Qingyuan County People's Government, Zhejiang Province",
						img: [
							require("../../assets/images/exhibition/6/1-1.jpg"),
						],
					},
					{
						title: "Zhejiang Jiuchuan Bamboo & Wood Co., Ltd.  ",
						img: [
							require("../../assets/images/exhibition/6/2-1.jpg"),
							require("../../assets/images/exhibition/6/2-2.jpg"),
						],
					},
					{
						title: "Bamboo Industry Association of Qingyuan County ",
						img: [
							require("../../assets/images/exhibition/6/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "Qionglai, Sichuan",
				title1: "Qionglai Municipal People's Government, Sichuan Province",
				gs: [{
						title: "Qionglai Municipal People's Government, Sichuan Province",
						img: [
							require("../../assets/images/exhibition/7/1-1.jpg"),
							// require("../../assets/images/exhibition/7/2-1.jpg"),
						],
					}, {
						title: "Qionglai Pingle Shuangxi River Bamboo Weaving Crafts Factory",
						img: [
							require("../../assets/images/exhibition/7/2-1.jpg"),
						],
					},
					{
						title: "Sichuan Wangderun Agricultural Technology Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/7/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "Anji, Zhejiang",
				title1: "Anji County People's Government, Zhejiang Province",
				gs: [{
						title: "Anji County People's Government, Zhejiang Province",
						img: [
							require("../../assets/images/exhibition/8/1-2.jpg"),
							require("../../assets/images/exhibition/8/1-1.jpg"),
						],
					},
					{
						title: "Zhejiang Fenghui Bamboo and Wood Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/8/2-1.jpg"),
						],
					},
					{
						title: "Zhejiang Senlin Bio-tech Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/8/3-1.jpg"),
						],
					},
				],
			},

			{
				title: "Taojiang, Hunan",
				title1: "Taojiang County People's Government, Hunan Province",
				gs: [{
						title: "Taojiang County People's Government, Hunan Province",
						img: [
							require("../../assets/images/exhibition/9/1-1.jpg"),
							require("../../assets/images/exhibition/9/1-2.jpg"),
						],
					},
					{
						title: "Taojiang Zhuyuan Linke Development Co., Ltd. ",
						img: [
							require("../../assets/images/exhibition/9/2-1.jpg"),
						],
					},
					{
						title: "Taojiang Yiwan Amusement Equipment Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/9/3-1.jpg"),
						],
					},
				],
			},
			{
				title: "BIA of Hunan",
				title1: "Bamboo Industry Association of Hunan Province",
				gs: [{
						title: "Bamboo Industry Association of Hunan Province",
						img: [
							require("../../assets/images/exhibition/10/1-1.jpg"),
							require("../../assets/images/exhibition/10/1-2.jpg"),
						],
					},
					{
						title: "Hunan Dawei Bamboo Industry Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/10/2-1.jpg"),
						],
					},
					// {
					// 	title: "Hunan Yinsun Bamboo Industry Co., Ltd.",
					// 	row: true,
					// 	img: [
					// 		require("../../assets/images/exhibition/10/3-1.jpg"),
					// 	],
					// },
					{
						title: "Hunan Tanboo Technology Co., Ltd.<br>Hunan Jiutong Bamboo-based Composites Manufacturing Co., Ltd.<br>Hunan Yinsun Bamboo Industry Co., Ltd.",
						padding: 1,
						img: [
							require("../../assets/images/exhibition/10/4-1.jpg"),
						],
					},
					// {
					// 	title: "Hunan Tanboo Technology Co., Ltd.",
					// 	row: true,
					// 	img: [
					// 		require("../../assets/images/exhibition/10/4-1.jpg"),
					// 	],
					// },
				],
			},
			{
				title: "Guilin, Guangxi",
				title1: "Guilin Municipal People's Government, Guangxi Zhuang Autonomous Region",
				gs: [{
						title: "Guilin Municipal People's Government, Guangxi Zhuang Autonomous Region",
						img: [
							require("../../assets/images/exhibition/11/1-1.jpg"),
						],
					}, {
						title: "Guilin Lanhome Trading Co., Ltd.<br>Guilin Jishanzhai Cultural Creativity Co., Ltd.",
						padding: 1,
						img: [
							require("../../assets/images/exhibition/11/2-1.jpg"),
						],
					},
					//  {
					// 	title: "Guilin Jishanzhai Cultural Creativity Co., Ltd.",
					// 	img: [
					// 		require("../../assets/images/exhibition/11/2-1.jpg"),
					// 	],
					// }, 
					{
						title: "Guangxi Yifu Agroforestry Environmental Protection Technology Co., Ltd. <br>Ziyuan Guizu Bamboo Industry Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/11/4-1.jpg"),
						],
					},
					// {
					// 	title: "Ziyuan Guizu Bamboo Industry Co., Ltd.",
					// 	img: [
					// 		require("../../assets/images/exhibition/11/4-1.jpg"),
					// 	],
					// }, 
				],
			},
			{
				title: "Sichuan Vanov",
				gs: [{
					title: "Sichuan Vanov New Materials Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/12/1-3.jpg"),
						require("../../assets/images/exhibition/12/1-1.jpg"),
						require("../../assets/images/exhibition/12/1-2.jpg"),
					],
				}, ],
			},
			{
				title: "CTU",
				gs: [{
					title: "China Tanboocel Union",
					img: [
						require("../../assets/images/exhibition/13/1-3.jpg"),
						require("../../assets/images/exhibition/13/1-1.jpg"),
						require("../../assets/images/exhibition/13/1-2.jpg"),
					],
				}, ],
			},
			{
				title: "Ningbo Shilin ",
				gs: [{
					title: "Ningbo Shilin Arts & Crafts Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/14/1-1.jpg"),
						require("../../assets/images/exhibition/14/1-3.jpg"),
						require("../../assets/images/exhibition/14/1-2.jpg"),
					],
				}, ],
			},
			{
				title: "Changsha Zhengzhong ",
				gs: [{
					title: "Changsha Zhengzhong Science and Technology Development Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/15/1-1.jpg"),
						require("../../assets/images/exhibition/15/1-2.jpg"),
						require("../../assets/images/exhibition/15/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "Fujian Zhichen",
				gs: [{
					title: "Fujian Zhichen Intelligent Agricultural Equipment Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/17/1-1.jpg"),
						// require("../../assets/images/exhibition/17/1-2.jpg"),
						// require("../../assets/images/exhibition/17/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "Long Bamboo Technology ",
				gs: [{
					title: "Long Bamboo Technology Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/18/1-1.jpg"),
						require("../../assets/images/exhibition/18/1-2.jpg"),
						require("../../assets/images/exhibition/18/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "Hunan Taohuajiang ",
				gs: [{
					title: "Hunan Taohuajiang Bamboo Science & Technology Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/19/1-3.jpg"),
						require("../../assets/images/exhibition/19/1-2.jpg"),
						require("../../assets/images/exhibition/19/1-1.jpg"),
					],
				}, ],
			},
			{
				title: "Anhui Changsi",
				gs: [{
					title: "Anhui Changsi Machinery Equipment Technology Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/20/1-1.jpg"),
						require("../../assets/images/exhibition/20/1-2.jpg"),
						require("../../assets/images/exhibition/20/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "Yunnan Rare Bamoo",
				gs: [{
					title: "Yunnan Rare Bamboo Agricultural Technology Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/21/1-1.jpg"),
						require("../../assets/images/exhibition/21/1-2.jpg"),
						require("../../assets/images/exhibition/21/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "Hubei Yaogu Mountain",
				gs: [{
					title: "Hubei Yaogu Mountain Ecological Products Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/22/1-1.jpg"),
						require("../../assets/images/exhibition/22/1-2.jpg"),
						require("../../assets/images/exhibition/22/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "Zhejiang Wanglin ",
				gs: [{
					title: "Zhejiang Wanglin Bio-tech Co., Ltd.",
					img: [
						require("../../assets/images/exhibition/23/1-1.jpg"),
						require("../../assets/images/exhibition/23/1-2.jpg"),
						require("../../assets/images/exhibition/23/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "Bamboo Branch of CSF ",
				gs: [{
					title: "Bamboo Branch of Chinese Society of Forestry",
					img: [
						require("../../assets/images/exhibition/24/1-2.jpg"),
						require("../../assets/images/exhibition/24/1-3.jpg"),
						require("../../assets/images/exhibition/24/1-1.jpg"),
					],
				}, ],
			},
			{
				title: "WWF ",
				gs: [{
					title: "World Wide Fund for Nature (WWF)",
					img: [
						require("../../assets/images/exhibition/25/1-1.jpg"),
						require("../../assets/images/exhibition/25/1-2.jpg"),
						require("../../assets/images/exhibition/25/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "WBR ",
				gs: [{
					title: "World Bamboo and Rattan",
					img: [
						require("../../assets/images/exhibition/27/1-2.jpg"),
						require("../../assets/images/exhibition/27/1-3.jpg"),
						require("../../assets/images/exhibition/27/1-1.jpg"),
					],
				}, ],
			},
			{
				title: "Shenzhen Shanfeng    ",
				gs: [{
					title: "Shenzhen Shanfeng Intelligent & Technology Co., Ltd. ",
					img: [
						require("../../assets/images/exhibition/28/1-1.jpg"),
						require("../../assets/images/exhibition/28/1-2.jpg"),
						require("../../assets/images/exhibition/28/1-3.jpg"),
					],
				}, ],
			},
			{
				title: 'Joint Exhibition for Bamboo Construction Companies in China',
				gs: [{
						title: "Hongya Zhuyuan Technology Co., Ltd.",
						row: true,
						img: [
							require("../../assets/images/exhibition/29/1-1.jpg"),
							require("../../assets/images/exhibition/29/1-2.jpg"),
						],
					},
					{
						title: "<span style='opacity: 0;' class='yidong'>Co.</span>Anji Zhujing Bamboo Industry Technology Co., Ltd.<span style='opacity: 0;' class='yidong'>Co.</span>",
						img: [
							require("../../assets/images/exhibition/30/1-1.jpg"),
						],
					}, {
						title: "Hangzhou Bamboo Technology Co., Ltd.",
						img: [
							require("../../assets/images/exhibition/32/1-1.jpg"),
						],
					},
					// {
					// 	title: "Ganzhou Sentai Bamboo & Wood Co., Ltd.",
					// 	row: true,
					// 	img: [

					// 	],
					// }, 
					{
						title: "Jiangsu See Bamboo Green Material Technology Co., Ltd.<br>Ganzhou Sentai Bamboo & Wood Co., Ltd.",
						padding: 1,
						img: [
							require("../../assets/images/exhibition/33/1-1.jpg"),
							require("../../assets/images/exhibition/33/1-2.jpg"),
						],
					},

				],
			},
			{
				title: "BFU",
				gs: [{
					title: "Beijing Forestry University",
					img: [
						require("../../assets/images/exhibition/16/1-1.jpg"),
						require("../../assets/images/exhibition/16/1-2.jpg"),
						require("../../assets/images/exhibition/16/1-3.jpg"),
					],
				}, ],
			},
			{
				title: "UNEP-WCMC ",
				gs: [{
					title: "UN Environment Programme<br>World Conservation Monitoring Centre (UNEP-WCMC)",
					padding: 1,
					img: [
						require("../../assets/images/exhibition/26/1-1.jpg"),
						require("../../assets/images/exhibition/26/1-2.jpg"),
						require("../../assets/images/exhibition/26/1-3.jpg"),
					],
				}, ],
			},
			// {
			// 	title: "Hongya Zhuyuan    ",
			// 	gs: [{
			// 		title: "Hongya Zhuyuan Technology Co., Ltd.",
			// 		img: [
			// 			require("../../assets/images/exhibition/29/1-1.jpg"),
			// 			require("../../assets/images/exhibition/29/1-2.jpg"),
			// 		],
			// 	}, ],
			// },
			// {
			// 	title: "Anji Zhujing  ",
			// 	gs: [{
			// 		title: "Anji Zhujing Bamboo Industry Technology Co., Ltd.",
			// 		img: [
			// 			require("../../assets/images/exhibition/30/1-1.jpg"),
			// 		],
			// 	}, ],
			// },
			// {
			// 	title: "Ganzhou Sentai ",
			// 	gs: [{
			// 		title: "Ganzhou Sentai Bamboo & Wood Co., Ltd.",
			// 		img: [

			// 		],
			// 	}, ],
			// },
			// {
			// 	title: "Hangzhou Bamboo  ",
			// 	gs: [{
			// 		title: "Hangzhou Bamboo Technology Co., Ltd.",
			// 		img: [
			// 			require("../../assets/images/exhibition/32/1-1.jpg"),
			// 		],
			// 	}, ],
			// },
			// {
			// 	title: "Jiangsu See Bamboo  ",
			// 	gs: [{
			// 		title: "Jiangsu See Bamboo Green Material Technology Co., Ltd.",
			// 		img: [
			// 			require("../../assets/images/exhibition/33/1-1.jpg"),
			// 			require("../../assets/images/exhibition/33/1-2.jpg"),
			// 		],
			// 	}, ],
			// },
		],
	},
	hzhb: {
		t1: `Partners and Participating Institues`,
		t2: `Partners from the Host Country`,
		t3: `International Partners`,
		list: [
			`
		National Forestry and Grassland Administration, P.R.China`,
			`International Centre for Bamboo and Rattan`,
			`Department of Duty Collection, General Administration of Customs of China`,
			`Permanent Mission of the People 's Republic of China to the World Trade Organization `,
			`Engineering Research Centre of Bamboo Carbon Sequestration, National Forestry and Grassland Administration`,
			`China National Bamboo Research Center`,
			`Research Institute of Green Economy, International Centre for Bamboo and Rattan`,
			`Research Institute of Garden Flower and Landscape, International Centre for Bamboo and Rattan`,
			`National Centre for Forestry Genetic Resources of Bamboo and Flowers, International Centre for Bamboo and Rattan`,
			`Research Institute of Bamboo and Rattan Biomass and New Materials, International Centre for Bamboo and Rattan`,
			`Research Institute of Chemical Utilization of Bamboo and Rattan Resources, International Centre for Bamboo and Rattan`,
			`Research Institute of Genetic Sciences of Bamboo and Rattan Resources, International Centre for Bamboo and Rattan`,
			`Research Institute of Bamboo and Rattan Resources and Environment, International Centre for Bamboo and Rattan`,
			`Sanya Research Base, International Centre for Bamboo and Rattan`,
			`National Ecological Research Station of Sanya Bamboo and Rattan Associated Forest Ecosystem`,
			`China - Canada Joint Laboratory for Bamboo & Rattan Science and Technology`,
			`Southeast Asia Biodiversity Research Institute, Chinese Academy of Sciences`,
			`Xishuangbanna Tropical Botanical Garden, Chinese Academy of Sciences`,
			`Institute of Chemical Industry of Forest Products, Chinese Academy of Forestry`,
			`Research Institute of Wood Industry, Chinese Academy of Forestry`,
			`Research Institute of Subtropical Forestry, Chinese Academy of Forestry`,
			`Centre for Mountain Futures`,
			`Fujian Academy of Forestry`,
			`Innovation Institute Bamboo Industry(Jian 'ou) Branch`,
			`Hebei Activated Carbon Industrial Technology Institute`,
			`Shanghai Research Institute of Building Sciences`,
			`State Key Laboratory of Subtropical Forest Cultivation Co - founded by Zhejiang Provincial Government and Ministry of Education`,
			`Zhejiang Academy of Forestry(Bamboo Industry National Innovation Alliance)`,
			`Zhejiang International Science and Technology Cooperation Base for Bamboo Cultivation and Utilization`,
			`Yong 'an Institute of Bamboo Industry`,
			`Yong 'an National Positioning and Monitoring Station for Bamboo Forest Ecosystem`,
			`China Bamboo Industry Association`,
			`Bamboo Industrial Equipment Branch, China Bamboo Industry Association`,
			`Bamboo Food and Commodity Branch, China Bamboo Industry Association`,
			`Bamboo Charcoal Branch, China Bamboo Industry Association`,
			`China Eco - Culture Association`,
			`China Flower Association`,
			`Chinese Society of Forestry`,
			`Bamboo and Rattan Resources and Utilization Branch of Chinese Society of Forestry`,
			`Bamboo Branch of Chinese Society of Forestry`,
			`China Bamboo and Rattan Brand Cluster`,
			`National Innovation Alliance for the Protection and Utilization of Bamboo Species Resources`,
			`National Innovation Alliance for Bamboo and Rattan Industry`,
			`Hong Kong Institute of Architects`,
			`Agricultural Green Development Research Association of Jiangsu Province`,
			`Ecological Society of Jiangsu Province`,
			`Systems Engineering Society of Jiangsu Province`,
			`Zhejiang Bamboo Industry Association`,
			`Bamboo Industry Association of Anji County`,
			`Bamboo Industry Association of Yuhang District, Hangzhou(National Small Path Bamboo Shoot Industry Service Station)`,
			`Beijing Forestry University`,
			`School of Landscape Architecture, Beijing Forestry University`,
			`Nanjing Forestry University`,
			`Engineering Research Centre of Biomaterials for Machinery Package, Nanjing Forestry University`,
			`Southwest Forestry University`,
			`Zhejiang Agriculture and Forestry University`,
			`Institute of Bamboo, Zhejiang Agriculture and Forestry University`,
			`Tongji University`,
			`Jiangxi Agricultural University`,
			`Sichuan Agricultural University`,
			`Southwestern University of Finance and Economics`,
			`School of Architecture, Tsinghua University`,
			`School of Civil Engineering, Tsinghua University`,
			`Faculty of Architecture, the University of Hong Kong`,
			`College of Civil Engineering and Architecture, Zhejiang University`,
			`Zhejiang University - University of Illinois at Urbana - Champaign Institute, Joint Research Center for Biomass Materials and Carbon Neutrality`,
			`School of Architecture and Urban Planning, Chongqing University`,
			`School of Civil Engineering, Southeast University`,
			`Engineering Research Centre of Bamboo - based Advanced Materials of Jiangxi Province, Gannan Normal University`,
			`College of Civil Engineering, Nanjing Tech University`,
			`
		Yong 'an Municipal People's Government, Fujian Province`,
			`Meishan Municipal People's Government, Sichuan Province`,
			`Qionglai Municipal People's Government, Sichuan Province`,
			`Wulong District People's Government, Chongqing Municipality`,
			`Taojiang County People's Government, Hunan Province`,
			`Zixi County People's Government, Jiangxi Province`,
			`Anji County People's Government, Zhejiang Province`,
			`Qingyuan County People's Government, Zhejiang Province`,
			`China Center for International Communication Development`,
			`Journal of World Bamboo and Rattan`,
			`Journal of Building Structure`,
			`Journal of Sustainable Structures`,
			`Journal of Landscape Architecture`,
			`Suichang County Bamboo Charcoal Museum`,
			`Architectural Design and Research Institute of Tsinghua University Co., Ltd`,
			`National Innovation Park for Forestry and Grass Equipment`,
			`Anhui Juneng Coal Industry Co., Ltd.`,
			`Anhui Changsi Machinery Equipment Technology Co., Ltd.`,
			`Anji Zhujing Bamboo Industry Technology Co., Ltd.`,
			`Chengde Green World of Activated Carbon Co., Ltd.`,
			`Changchun Charcoal Professional Cooperative, Chuzhou City`,
			`Dongguan Koham Co., Ltd., China`,
			`Fujian Mingliang Food Co., Ltd., China`,
			`Ganzhou Sentai Bamboo & Wood Co., Ltd.`,
			`Guangdong Yufeng Bamboo Fiber Co., Ltd`,
			`Guangdong Zhukang Material Technology Co., Ltd.`,
			`Hangzhou Bamboo Technology Co., Ltd.`,
			`Hebei Jigao Chemical Fiber Co., Ltd.`,
			`Hongya Zhuyuan Technology Co., Ltd.`,
			`Hunan Taohuajiang Bamboo Science & Technology Co., Ltd.`,
			`Jiangsu See Bamboo Green Material Technology Co., Ltd.`,
			`Jiangsu King Wood Machinery Co., Ltd.`,
			`Qizhu(Beijing) Technology Development Co., Ltd`,
			`Shenzhen Gold Basin Ltd., China`,
			`China Tanboocel Union`,
			`Panda Carbon Technology Co., Ltd.`,
			`Zhejiang Fulaisen Bamboo Charcoal Co., Ltd.`,
			`Zhejiang Jizhu Bio - Tech Co., Ltd.`,
			`Zhejiang Shengshi Biological Technology Co., Ltd`,
			`China Southwest Architecture Design and Research Institute Co., Ltd`,
			`Chongqing Ruizhu Plant Fiber Products Co., Ltd.`,
			`Chongqing Tezhen Food Co., Ltd`,
			`Bamboo Fiber Industry Network

		`,
		],
		list1: [

			`United Nations Industrial Development Organization(UNIDO)`,
			`United Nations Educational, Scientific and Cultural Organization(UNESCO) Beijing Office`,
			`Food and Agriculture Organization of the United Nations(FAO)`,
			`United Nations Human Settlements Programme(UN - HABITAT)`,
			`United Nations Conference on Trade and Development(UNCTAD)`,
			`United Nations Forum on Forests(UNFF) Secretariat`,
			`UN Environment Programme World Conservation Monitoring Centre(UNEP - WCMC)`,
			`World Wildlife Fund for Nature(WWF) Beijing Office`,
			`International Organization for Standardization Technical Committee 296 on Bamboo and Rattan(ISO / TC 296)`,
			`International Union of Forest Research Organizations(IUFRO) Division 5.11 .05 - Bamboo and Rattan`,
			`International Centre for Integrated Mountain Development(ICIMOD)`,
			`INBAR Bamboo Construction Task Force`,
			`African Union Commission / African Union Development Agency– New Partnerships for African Development(AUC / AUDA - NEPAD)`,
			`Spanish Agency for International Development Cooperation`,
			`Ministry of Urban Development and Housing, Ecuador`,
			`Forestry Research Institute of Ghana(CSIR)`,
			`National Forest and Wildlife Service, Peru`,
			`Ibero - American Bamboo Association, Spain / Portugal`,
			`Akenten Appiah - Menka University`,
			`Deakin University`,
			`Coventry University`,
			`University of Pittsburgh`,
			`Institut Teknologi Bandung`,
			`University of the West of England`,
			`Addis Ababa University`,
			`University of British Columbia(UBC)`,
			`ETH Zürich`,
			`School of Design, Virginia Tech`,
			`International Finance Corporation`,
			`Bambusa ES(company), Spain`,
		],
	},
	img1: 'INBAR Photo Competition',
	img2: 'Bamboo and Rattan for the SDGs',
	img3: 'Lancang-Mekong Design Award',
	img4: ' INBAR Member States',
};