<template>
  <div class="logoHeader">
    <div class="leftBox">
      <img :src="require('../../assets/images/header/logo.png')" alt="">
    </div>
    <div class="rightBox">
      <div class="center" @click="goCenterPage">{{$t('personal_center')}}</div>
      <div class="OperatingArea">
        <div class="switchLanguage">
          <div v-for="(lang,index) in langs" :key="lang.value">
            <span :class="['name',lang.active ? 'active' : '']" @click="switchLan(lang.value)">{{lang.text[0]}}</span>
            <span v-if="index == 0">|</span>
          </div>
        </div>
      </div>
      <div class="years">
        <img :src="require('../../assets/images/header/25years.png')" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    langs() {
      const langs = [];
      const messages = this.$i18n.messages;
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      for (const langName in messages) {
        const curmsg = messages[langName];
        if (this.locale == langName) continue;
        langs.push({
          text: [messages[localeLang].langs[langName], curmsg.langName],
          value: langName,
          code: curmsg.code,
          encode: curmsg.encode,
          active:localeLang == langName,
        });
      }
      return langs.reverse();
    },
  },
  methods: {
    goCenterPage() {
      window.open('https://barc2022.chinafuturelink.com/barc')
    },
    switchLan(val) {
      window.localStorage.setItem("locale", val);
      this.$nextTick(() => {
        location.reload();
      });
    }
  },

}
</script>
<style lang="stylus" scoped>
@media screen and (max-width: 728px)
  .logoHeader
    width 100%;
    height 50px;
    display flex;
    background #8DC32B
    align-items center;
    justify-content space-between;
    .leftBox
      height: 100%;
      width: 40px;
      overflow hidden;
      padding-left: 15px;
      display flex;
      align-items center;
      img
        width: 100%;
        height: auto
    .rightBox
      height: 100%;
      overflow hidden;
      display flex;
      align-items center;
      .years
        width: 40px;
        height: 100%;
        display flex;
        align-items center;
        padding-right: 15px;
        img
          width: 100%;
          height: auto
      .center
        display: none;
      .OperatingArea
        display: none;
@media screen and (min-width: 728px)
  .logoHeader
    height 96px;
    display flex;
    background #8DC32B
    width 100%;
    align-items center;
    justify-content space-between;
    padding 0 60px;
    box-sizing: border-box;
    .leftBox
      height: 100%;
      width: 76px;
      overflow hidden;
      display flex;
      align-items center;
      img
        width: 100%;
        height: auto
    .rightBox
      height: 100%;
      overflow hidden;
      box-sizing: border-box;
      display flex;
      align-items center;
      .center
        height: 100%;
        color: white;
        height 40px;
        display flex;
        align-items center;
        font-size: 18px;
        margin-right: 30px;
        cursor pointer;
      .OperatingArea
        flex-shrink 0;
        height 40px;
        display flex;
        align-items center;
        justify-content space-between;
        box-sizing: border-box;
        padding-right: 10px;
        .switchLanguage
          display: flex;
          font-size: 18px;
          color: white;
          line-height: 2.08vw;
          user-select none;
          .name
            cursor: pointer;
            margin: 0 20px;
            &.active
              color: #405E3C;
      .years
        width: 76px;
        display flex;
        align-items center;
        img
          width: 100%;
          height: auto;

</style>