import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 当前展示的路由名称
    routerName: 'home',
    // 首页二维码遮罩层
    coverType: 0,
  },
  mutations: {
    // 同步更新用户基本信息
    setRouterName(state, data) {
      state.routerName = data;
    },
    // 同步更新遮罩
    setCoverType(state, data) {
      state.coverType = data;
    },
  },
  actions: {},
});
