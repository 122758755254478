import Vue from 'vue'
import App from './App.vue'
import i18n from "./i18n";
import router from './router';
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import "@/assets/styles/common.css"
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'


Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 999
  }
})

Vue.config.productionTip = false

Vue.use(ElementUI);
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
