import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}

const router = new Router({
	routes: [
		// 重定向到首页
		{
			path: '/',
			meta: {
				keepAlive: true
			},
			redirect: '/home',
		},
		// 首页
		{
			path: '/home',
			name: 'home',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/home'], resolve)
		},
		// 大会议程
		{
			path: '/conferenceAgenda',
			name: 'conferenceAgenda',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/conferenceAgenda'], resolve)
		},
		// 平行会议
		{
			path: '/parallelMeeting',
			name: 'parallelMeeting',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/parallelMeeting'], resolve)
		},
		{
			path: '/poster',
			name: 'poster',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/poster'], resolve)
		},
		{
			path: '/parallelMeetingDetails',
			name: 'parallelMeetingDetails',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/parallelMeeting/details'], resolve)
		},
		{
			path: "/registerMeeting",
			name: "registerMeeting",
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/registerMeeting'], resolve)
		},
		{
			path: "/transportationAndAccommodation",
			name: "transportationAndAccommodation",
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/transportationAndAccommodation'], resolve)
		},
		{
			path: "/parallelMeetings",
			name: "parallelMeetings",
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/parallelMeetings'], resolve)
		},

		//论文摘要
		{
			path: "/abstract",
			name: "abstract",
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/abstract'], resolve)
		},
		//竹藤展览
		{
			path: "/exhibition",
			name: "exhibition",
			component: resolve => require(['@/pages/exhibition'], resolve)
		},
		{
			path: "/exhibitionDetails",
			name: "exhibitionDetails",
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/exhibition/details'], resolve)
		},
		{
			path: "/imgPages",
			name: "imgPages",
			component: resolve => require(['@/pages/exhibition/imgPages'], resolve)
		},
		{
			path: "/contactUs",
			name: "contactUs",
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/contactUs'], resolve)
		},

		//常见问题
		{
			path: "/commonProblem",
			name: "commonProblem",
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/commonProblem'], resolve)
		},
		// 新闻
		{
			path: '/news',
			name: 'news',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/news'], resolve)
		},
		// 大会简介
		{
			path: '/account',
			name: 'account',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/account/index'], resolve)
		},
		// 嘉宾介绍
		{
			path: '/introduce',
			name: 'introduce',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/account/introduce'], resolve)
		},
		// 会场信息
		{
			path: '/conference',
			name: 'conference',
			meta: {
				keepAlive: true
			},
			component: resolve => require(['@/pages/account/conference'], resolve)
		},
	]
})

export default router