<template>
  <div class="footerBox">
    <div class="logoContainer">
      <div class="logo">
        <img :src="require('../../assets/images/footer/logo.png')">
      </div>
      <div class="logo">
        <img :src="require('../../assets/images/footer/25years.png')">
      </div>
    </div>
    <div class="logoBox">
      <img :src="require('../../assets/images/footer/logo.png')">
    </div>
    <div class="footerContent">
      <div class="firstTitle" :class="{ KaiTi: lang }">
        {{$t('footer.a')}}
      </div>
      <div class="secondTitle" :class="{ KaiTi: lang }">
        {{$t('footer.b')}}
      </div>
      <div class="iconsBox">
        <div 
          class="icon" 
          v-for="index in 8" 
          :key="index"
          @click="clickIcon(index)"
        >
          <img :src="require(`../../assets/images/footer/${index}.png`)" alt="">
        </div>
      </div>
      <div class="tipsBox" style="margin-top:20px;" :class="{ KaiTi: lang }">
        {{$t('footer.c')}}
      </div>
      <div class="tipsOuter">
        <div class="tipsBox" :class="{ KaiTi: lang }" v-html="$t('footer.d')"></div>
        <div class="tipsBox" :class="{ KaiTi: lang }" v-html="$t('footer.e')"></div>
      </div>
      <div class="tipsBox calibri" style="margin-top:16px;">
        Copyright @ 2022 BARC. All Rights Reserved.
      </div>
    </div>
    <div class="logoBox">
      <img :src="require('../../assets/images/footer/25years.png')">
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    CosImages() {
      let CosImages = [];
      return CosImages;
    },
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  data() {
    return {
      firstList: {
        listTitle: this.$t("hosts"),
        datas: [
          {
            title: this.$t("Organizer")
          },
          {
          title: this.$t("OrganizerEurope")
          },
        ]
      },
      secondList: {
        listTitle: this.$t("organizers"),
        datas: [
          {
            title: this.$t("undertake")
          },
          {
            title: this.$t("undertakeHigh")
          },
          {
            title: this.$t("ChenduOrganizer")
          },
        ]
      },
      thirdList: {
        listTitle: this.$t("suppporters"),
        datas: [
          {
            title: this.$t("EuropeChina")
          },
          {
            title: this.$t("EuropeanInstitute")
          },
          {
            title: this.$t("EUSMECenter")
          }
        ]
      },
      fiveList: {
        listTitle: this.$t("Cooperative"),
        datas: [
          {
            title: this.$t("CommercialandLegal")
          },
          {
            title: this.$t("ChinaCouncilfor")
          },
          {
            title: this.$t("ChengduInternational")
          },
          {
            title: this.$t("EUProject")
          },
          {
            title: this.$t("SichuanEnergy")
          }
        ]
      },
      fourthList: {
        listTitle: this.$t("contacts"),
        datas: [
          {
            title: 'eucnfair@163.com'
          },

        ]
      }
    }  
  },
  methods:{
    goTO(){
       window.open('https://www.miit.gov.cn/')
    },
    clickIcon(index) {
      switch (index) {
        case 1:
          window.open('https://twitter.com/INBARofficial')
          break;
        case 2:
          window.open('https://www.facebook.com/INBARofficial')
          break;
        case 3:
          window.open('https://www.linkedin.com/company/inbar-official/')
          break;
        case 4:
          window.open('http://weibo.com/p/1006062044486527/home')
          break;
        case 5:
          this.$store.commit("setCoverType", index);
          break;
        case 6:
          window.open('https://www.youtube.com/user/INBARChannel')
          break;
        case 7:
          window.open('https://instagram.com/inbarofficial')
          break;
        case 8:
          this.$store.commit("setCoverType", index);
          break;
        default:
          break;
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.KaiTi {
  font-family: KaiTi;
}
@media screen and (max-width: 728px)
  .footerBox
    width 100%;
    min-height: 450px;
    box-sizing border-box;
    background: #8DC32B;
    overflow hidden;
    .logoBox
      display: none;
    .logoContainer
      display: block
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      .logo
        width: 60px;
        height: auto;
        &:nth-child(1)
          margin-right: 45px;
        img
          width: 100%;
          height: auto;
    .footerContent
      width: 100%;
      overflow hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 700;
      .firstTitle
        font-size: 14px;
        color: #FFFFFF;
        margin-top: 40px;
      .secondTitle
        font-size: 14px;
        color: #FFFFFF;
        margin-top: 10px;
      .iconsBox
        width: 274px;
        height: auto;
        margin-top: 22px;
        .icon
          width 46px;
          height: 46px;
          border-radius: 50%;
          background: #405E3C;
          cursor pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          user-select: none;
          transition: background 0.2s;
          margin-right: 10px;
          &:nth-child(5),&:nth-child(9)
             margin-right: 0px;
          &:nth-child(6),&:nth-child(7),&:nth-child(8),&:nth-child(9)
             margin-top: 10px;
          &:hover
            background: #141D12;
      .tipsBox
        font-size: 14px;
        color white;
        margin-top: 10px;
      .tipsOuter
        width: 100%;

@media screen and (min-width: 728px)
  .footerBox
    width 100%;
    height: 311px;
    background: #8DC32B;
    box-sizing border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding 0 60px;
    .footerContent
      flex 1;
      height: 100%;
      overflow hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .firstTitle
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 40px;
      .secondTitle
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 16px;
      .iconsBox
        width: 502px;
        height: 46px;
        display: flex;
        margin-top: 25px;
        justify-content: space-between;
        .icon
          width 46px;
          height: 46px;
          border-radius: 50%;
          background: #405E3C;
          cursor pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
          transition: background 0.2s;
          &:hover
            background: #141D12;
      .tipsBox
        font-size: 16px;
        color white;
      .tipsOuter
        width: 502px;
        height: 20px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    .logoContainer
      display: none;
    .logoBox
      width: 126px;
      height: 100%;
      display: flex;
      align-items: center;
      img
        width: 100%;
        height: auto;


</style>
<style scoped>
.QRcode{
position: relative;
width: 100%;
margin-top:20px;
padding:0px;
height: 100px;
}
.QRcodeSize{
  color: #666;
  font-size: 10px;
  max-width: 15vw;
  text-align: justify;
}
.QRcodeBottom{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
}
.footerbootom{
   text-align: center;
   font-size: 14px;
   cursor: pointer;
}
</style>