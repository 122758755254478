<template>
  <div id="app">
    <Header></Header>
    <div class="body">
      <router-view></router-view>
      <!-- <keep-alive>
      </keep-alive> -->
    </div>
    <Footer></Footer>
    <div v-if="coverFlag" class="cover" @click.stop="() => {}">
      <div class="innerBox">
        <img
          v-if="$store.state.coverType === 5"
          :src="require('./assets/images/footer/weixin.png')"
        />
        <img
          v-if="$store.state.coverType === 8"
          :src="require('./assets/images/footer/douyin.png')"
        />
        <img
          @click.stop.self="closeCover"
          class="closeImage"
          :src="require('./assets/images/footer/close.png')"
        />
      </div>
    </div>
    <div class="goTop" @click="toTop">
      <i class="el-icon-arrow-up"></i>
    </div>
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  watch: {
    "$route.name"() {
      this.$nextTick(() => {
        document.scrollingElement.scrollTop = 0;
      });
    },
    // 这个监听主要是监听首页菜单里的页面，如果是其它子页面不要动这个值
    "$store.state.routerName": {
      handler(val, old) {
        switch (val) {
          case "home":
            this.$router.push({ path: "/home" });
            break;
          case "INBAR":
            window.open(this.$t("INBAR_new_url"));
            break;
          case "News":
            this.$router.push({ path: "/news" });
            break;
          case "account":
            this.$router.push({ path: "/account" });
            break;
          case "introduce":
            this.$router.push({ path: "/introduce" });
            break;
          case "conference":
            this.$router.push({ path: "/conference" });
            break;
          case "contactUs":
            this.$router.push({ path: "/contactUs" });
            break;
          case "abstract":
            this.$router.push({ path: "/abstract" });
            break;
          case "registerMeeting":
            this.$router.push({ path: "/registerMeeting" });
            break;
          case "transportationAndAccommodation":
            this.$router.push({ path: "/transportationAndAccommodation" });
            break;
          case "exhibition":
            this.$router.push({ path: "/exhibition" });
            break;
          case "conferenceAgenda":
            this.$router.push({ path: "/conferenceAgenda" });
            break;
          case "parallelMeeting":
            this.$router.push({ path: "/parallelMeeting" });
            break;
          case "poster":
            this.$router.push({ path: "/poster" });
            break;
          case "imgPages1":
            this.$router.push({ path: "/imgPages", query: { type: 1 } });
            break;
          case "imgPages2":
            this.$router.push({ path: "/imgPages", query: { type: 2 } });
            break;
          case "imgPages3":
            this.$router.push({ path: "/imgPages", query: { type: 3 } });
            break;
          case "imgPages4":
            this.$router.push({ path: "/imgPages", query: { type: 4 } });
            break;
          case "Social":
            // this.$router.push({ path: "/imgPages", query: { type: 4 } });
            window.open('https://trello.com/b/0sFVk3rP/inbar-25th-anniversary-barc-2022')
            break;
        }
      },
      deep: true,
    },
    //监听弹窗遮罩
    "$store.state.coverType": {
      handler(newVal) {
        if (newVal !== 0) {
          this.coverFlag = true;
        } else {
          this.coverFlag = false;
        }
      },
      deep: true,
    },
  },
  created() {
    // var ua = navigator.userAgent.toLowerCase();
    // console.log(ua)
    // var isChrome = ua.indexOf("chrome") > -1;
    // if (!isChrome) {
    //   document.head.innerHTML =
    //     '<title>抱歉，出错了</title><meta charset="utf-8">';
    //   document.body.innerHTML =
    //     '<div id="header"><h4>请使用谷歌浏览器访问该系统</h4>';
    // }
  },
  data() {
    return {
      coverFlag: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    closeCover() {
      this.$store.commit("setCoverType", 0);
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: calibri;
}

@font-face {
  font-family: "calibri";
  src: url("./assets/font-family/calibri.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "weibei";
  src: url("./assets/font-family/weibei.ttf");
  font-weight: 400;
}

.KaiTi {
  font-family: KaiTi;
}

.weibei {
  font-family: weibei;
}

.weiruan {
  font-family: "Microsoft YaHei";
}

.calibri {
  font-family: calibri;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<style lang="stylus">
* {
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
    background: #8dc32b;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: #ffffff;
  }
}
</style>

<style lang="stylus" scoped>
@media screen and (max-width: 728px) {
  .body {
    width: 100%;
    margin-top: 90px;
    min-height: calc(100vh - 540px);
  }

  .cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1501;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);

    .innerBox {
      width: 338px;
      height: 510px;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      .closeImage {
        top: 14px;
        right: 14px;
        position: absolute;
        z-index: 10;
        cursor: pointer;
      }
    }
  }

  .goTop {
    position: fixed;
    bottom: 65px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #8dc63f;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-arrow-up {
      font-size: 20px;
      color: #ffffff;
    }
  }
}

@media screen and (min-width: 728px) {
  .body {
    width: 100%;
    margin-top: 146px;
    min-height: calc(100vh - 457px);
  }

  .cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1501;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);

    .innerBox {
      width: 338px;
      height: 510px;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      .closeImage {
        top: 0;
        right: -36px;
        position: absolute;
        z-index: 10;
        cursor: pointer;
      }
    }
  }

  .goTop {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #8dc63f;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-arrow-up {
      font-size: 28px;
      color: #ffffff;
    }
  }
}
</style>
