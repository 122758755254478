<template>
  <div class="headerContainer">
    <LogoHeader></LogoHeader>
    <HeaderTab></HeaderTab>
  </div>
</template>
<script>
import LogoHeader from "./logoHeader.vue";
import HeaderTab from "./headerTab.vue";
export default {
  name: "pageHeader",
  components: {
    LogoHeader,
    HeaderTab,
  },
  data() {
    return {
      // 显示头部背景图
      headerBg: null,
      //点击需要发布地址
      negotiationUrl: "",
      scrollTop: 0, //实时的滚动位置
      stopscroll: 0, //停止后的滚动位置
      style: "",
      scroll: 0,
      timer: "",
    };
  },
  watch: {
    // 根据不同的页面展示不同的header多语言背景图
    "$route.name": {
      handler(val, old) {
        if (val && val !== old) {
          let localeLang = window.localStorage.getItem("locale") || "zh-CN";
        }
      },
      deep: true,
      immediate: true,
    },
    headerBg(newVal, oldVal) {
      this.negotiationUrl = newVal;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); //监听滚动条
  },
  methods: {
    handleScroll() {
      //滚动触发
      clearTimeout(this.timer);
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.scrollTop = scrollTop;
      if (this.stopscroll < this.scrollTop - 20) {
        //向下移动20以后
        this.style = "position: fixed;left: 0;top:-138px;";
        this.stopscroll = scrollTop;
      }
      if (this.stopscroll > this.scrollTop + 20) {
        //向上移动20以后
        this.style = "position: fixed;left: 0;top:0px;";
        // if (this.scrollTop < 70) {
        //   this.style += "background-color: transparent;";
        //   this.color = "";
        // } else {
        //   this.style +=
        //     "background-color: #ffffff;box-shadow: 10px 3px 8px #e3e3e3;";
        //   this.color = "color";
        // }
        this.stopscroll = scrollTop;
      }

      if(scrollTop){
        this.style1=''
      }else{
        this.style1='padding-top:138px'
      }
      this.timer = setTimeout(this.ScrollEnd, 200);
    },
    ScrollEnd() {
      //滚动停止
      let stopscroll =
        document.documentElement.scrollTop || document.body.scrollTop; //獲取滾動停止后的scrolltop
      if (stopscroll == this.scrollTop) {
        //滚动结束保留位置
        this.stopscroll = stopscroll;
      }
    },
    goClick() {
      //需求发布
      console.log(this.negotiationUrl, "需求发布");
      if (
        this.negotiationUrl ===
          "/assets/img/negotiation_English.c602a9f5.jpg" ||
        this.negotiationUrl === "/assets/img/negotiation_Chinese.4ab23cd7.jpg"
      ) {
        window.open(
          "https://m.chinafuturelink.org/#/activity/single_form?activity_id=60a374ae419a8a2f0704b039&meeting_id=609de78866062e1d082b0226"
        );
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@media screen and (max-width: 728px)
  .headerContainer
    position: fixed;
    top: 0px;
    left: 0px;
    z-index 1500;
    width: 100vw;
@media screen and (min-width: 728px)
  .headerContainer
    position: fixed;
    height: 146px;
    top: 0px;
    left: 0px;
    z-index 1500;
    width: 100vw;


</style>