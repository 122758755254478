import en from "./en";
import zhCN from "./zh-CN";
const lang = {
  "zh-CN": zhCN,
  en,
};
const langNames = {
  "zh-CN": "中文",
  en: "English",
};
const encodeMap = {
  "zh-CN": "zh",
};
let name = window.localStorage.getItem("locale")
if (!name) {
  let lan = navigator.systemLanguage || navigator.language;
  if (lan.toLowerCase().indexOf("zh") !== -1) {
    console.log("当前浏览器默认语言为中文");
    window.localStorage.setItem("locale", "zh-CN");
  } else {
    window.localStorage.setItem("locale", "en");
  }
}
//合并 element-ui 语言包
Object.keys(lang).forEach((name, key) => {
  lang[name].langName = langNames[name];
  lang[name].encode = encodeMap[name] || name;
  lang[name].code = (key + 1).toString();
  !lang[name].el &&
    (lang[name].el = {
      ...require(`element-ui/lib/locale/lang/${name}`).default.el
    });
});

export default lang;