<template>
  <div class="headerTabContainer">
    <div class="headerTab">
      <div 
        class="tabItem weiruan"
        v-for="(item,index) in navigations"
        :key="index"
        @click.stop="selectThisItem(item)"
      >
        <span :class="{active:item.isSelected,spacing:item.title.length === 2}">
          {{item.title}}
        </span>
        <span style="margin-left:8px" v-if="item.child&&item.child.length!==0">
          <img :src="require('../../assets/images/header/down.png')">
        </span>
        <template v-if="item.child && item.child.length">
          <div class="childBox">
            <div 
              class="child" 
              :style="index==6?'font-size: 12px;':''"
              v-for="(child,childIndex) in item.child" 
              :key="childIndex"
              @click.stop="selectThisItem(child)"
              :class="{active:child.isSelected}"
            >
              <div class="lineBox"></div>
              <div class="contentBox">
                <span>{{child.title}}</span>
                <span>
                  <img :src="require('../../assets/images/header/right.png')">
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="headerTab_2">
      <div class="more" @click="changeSelectPanel">
        <img v-if="!selectPanelFlag" :src="require('../../assets/images/home/more.png')">
        <img v-if="selectPanelFlag" :src="require('../../assets/images/home/close.png')">
      </div>
      <div class="rightBox">
        <div class="center" @click="goCenterPage">
          {{$t('personal_center')}}
        </div>
        <div class="line"></div>
        <div class="popover" @click.stop.self.prevent="openPopover($event)">
          {{lang}} 
          <img :src="require('../../assets/images/header/down.png')" alt="">
        </div>
        <div class="popoverBox" v-if="popoverFlag">
          <template v-for="(lang,index) in langs">
            <div class="text" :class="{active:lang.active}" @click="switchLan(lang.value)">{{lang.text[0]}}</div>
            <div class="line" v-if="index === 0"></div>
          </template>
        </div>
      </div>
      <div class="navigationBox" v-if="selectPanelFlag">
        <el-collapse accordion @change="handleChange">
          <template v-for="(el,index) in navigations">
            <el-collapse-item :class="{noChilds: el.child && el.child.length === 0}" :name="index">
              <template slot="title">
                {{el.title}}
              </template>
              <template v-if="el.child && el.child.length !== 0">
                <div class="childBox" v-for="child in el.child" @click="selectThisItem(child)">
                  {{child.title}}
                </div>
              </template>
            </el-collapse-item>
          </template>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // H5界面展示的下拉选择面板
      selectPanelFlag: false,
      popoverFlag: false,
    }
  },
  computed: {
    lang() {
      return this.langs.filter(e=>e.active)[0].text[0]
    },
    langs() {
      const langs = [];
      const messages = this.$i18n.messages;
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      for (const langName in messages) {
        const curmsg = messages[langName];
        if (this.locale == langName) continue;
        langs.push({
          text: [messages[localeLang].langs[langName], curmsg.langName],
          value: langName,
          code: curmsg.code,
          encode: curmsg.encode,
          active:localeLang == langName,
        });
      }
      return langs;
    },
    // 当前展示路由
    currentRouter() {
      return this.$route.name
    },
    // 当前展示的路由名称
    currentRouterName() {
      let routerName = "";
      this.navigation.forEach(item => {
        if(item.child.length === 0 && item.value === this.currentRouter) {
          routerName = item.title;
        } else if (item.child.length > 0) {
          item.child.forEach(element => {
            if(element.value === this.currentRouter) {
              routerName = element.title;
            }
          })
        }
      });
      return routerName;
    },
    // 展示的路由菜单列表
    navigations() {
      let navigation = [];
      const messages = this.$i18n.messages;

      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
    
      for (const langName in messages) {
        if (this.locale == langName) continue;
        navigation = messages[localeLang].navigation
      }
      navigation = navigation.map(item => {
       let subscript = localStorage.getItem("subscript")
        item.isSelected = false;
        if(item.child.length === 0 && item.value === this.currentRouter ) {
          item.isSelected = true;
        }
        else if (item.child.length > 0) {
          item.child = item.child.map(element => {
            element.isSelected = false;
            if(element.value === this.currentRouter) {
              element.isSelected = true;
            }
            return element;
          })
        }
        return item;
    
      })
      return navigation;
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      let that = this
      if (!this.$el.contains(e.target)) {
        that.popoverFlag = false;
        that.selectPanelFlag = false;
      }
    });
  },
  methods: {
    goCenterPage() {
      window.open('https://barc2022.chinafuturelink.com/barc')
    },
    switchLan(val) {
      window.localStorage.setItem("locale", val);
      this.$nextTick(() => {
        location.reload();
      });
    },
    openPopover() {
      this.popoverFlag = !this.popoverFlag;
    },
    handleChange(index) {
      if(index !== '') {
        this.selectThisItem(this.navigations[Number(index)])
      }
    },
    // 选择这个菜单
    selectThisItem(item) {
      if(item.child && item.child.length !== 0) {
        return;
      } 
      localStorage.setItem("subscript",item.value)
      // 这里这样写是为了触发首页的监听
      this.$store.commit("setRouterName", "");
      this.$nextTick(()=>{
        console.log(item.value)
        this.$store.commit("setRouterName", item.value);
        this.selectPanelFlag = false;
      })
    },
    // 打开选择面板
    changeSelectPanel() {
      this.selectPanelFlag = !this.selectPanelFlag;
    }
  }
}
</script>
<style lang="stylus">
@media screen and (max-width: 728px)
  .navigationBox
    .el-collapse
      border-top none !important;
      .el-collapse-item
        .el-collapse-item__header
          background: #8DC32B !important;
          height: 54px !important;
          font-size: 16px;
          font-weight: 700;
          color: #FFFFFF;
          border-bottom 1px solid rgba(255,255,255,0.3) !important;
        .el-collapse-item__wrap
          background: #8DC32B !important;
          border-bottom: none !important;
          .el-collapse-item__content
            padding-bottom: 0px;
            .childBox
              cursor pointer;
              width: 100%;
              height: 60px;
              border-bottom: 1px solid rgba(255,255,255,0.3);
              box-sizing: border-box;
              padding 0 10px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 16px;
              font-weight: 700;
              color #405E3C;
      .noChilds
        .el-collapse-item__header
          cursor pointer;
          .el-icon-arrow-right
            display: none;




</style>
<style lang="stylus" scoped>
@media screen and (max-width: 728px)
  .headerTabContainer
    background #405E3C;
    box-sizing border-box;
    display flex;
    align-items center;
    height: 40px;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .headerTab
      display none;
    .headerTab_2
      height: 100%;
      width: 100%;
      padding 0 15px;
      display: flex;
      position relative;
      align-items: center;
      justify-content: space-between;
      .navigationBox
        width: 100%;
        position: absolute;
        max-height: calc(100vh - 90px);
        top 40px;
        left: 0px;
        background: #8DC32B;
        box-sizing: border-box;
        padding 0 26px;
        overflow-y: auto;
        overflow-x: hidden;
        .navigation
          height: 54px;
          width: 100%;
          color: #ffffff;
          font-size: 16px;
          font-weight: 700;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(255,255,255,0.3);
          display: flex;
          align-items: center;
          justify-content: flex-start;
        .flexStyle
          display: flex;
          align-items: center;
          justify-content: space-between;
      .rightBox
        height: 28px;
        flex 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 13px;
        color #FFFFFF;
        .center
          height: 100%;
          line-height: 28px;
          margin-right: 20px;
          cursor pointer;
        .line
          height: 12px;
          width: 1px;
          background: #FFFFFF;
        .popover
          height: 100%;
          padding-left: 20px;
          line-height: 28px;
        .popoverBox
          background: #FFFFFF;
          border-radius: 5px;
          width: 88px;
          height: 86px;
          position absolute;
          top: 40px;
          right 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .text
            line-height: 20px;
            font-size: 16px;
            color #333333;
            &:nth-child(3)
              margin-top: 15px;
          .active
            color #8DC63F;
            font-size: 14px;
          .line
            width 62px;
            height: 1px;
            margin-top: 5px;
            background: rgba(0,0,0,0.2);
      .more
        width: 28px;
        height: 28px;
        cursor pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img
          width: 100%;
          height: auto;



@media screen and (min-width: 728px)
  .headerTabContainer
    background #405E3C;
    box-sizing border-box;
    display flex;
    align-items center;
    height: 50px;
    width: 100%;
    padding 0 60px;
    .headerTab_2
      display none;
    .headerTab
      height 50px;
      width 100%;
      display flex;
      .tabItem
        height: 100%;
        color: #FFFFFF;
        font-size: 16px;
        cursor pointer;
        position relative;
        width: 181px;
        box-sizing: border-box;
        text-align: left;
        display: flex;
        align-items: center;
        .active
          color #8DC63F;
        &:hover
          .childBox
            display block;
        .spacing
          letter-spacing: 12px;
        .childBox
          display none;
          height: auto;
          position absolute;
          z-index 10;
          top 50px;
          transform translateX(0);
          width 100%;
          font-size 16px;
          .active
            background: #405E3C !important;
          .child
            height: 56px;
            width: 100%;
            display: flex;
            color #FFFFFF;
            background-color #8DC32B;
            margin-top: 1px;
            .lineBox
              height: 100%;
              width: 4px;
              background: #405E3C;
            .contentBox
              height: 100%;
              flex: 1;
              padding: 0px 16px 0px 11px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            &:hover
              color: #8DC32B;
              background-color #405E3C;
    .headerTab_2
      min-height 58px;
      width 100%;
      box-sizing border-box;
      overflow hidden;
      transition height 5s;
      .selectedToshow
        width 100%;
        height 58px;
        line-height 58px;
        text-align left;
        cursor pointer;
        font-size 14px;
        color #FFFFFF;
        padding 0 15px;
        box-sizing: border-box;
      .selectPanel
        width 100vw;
        overflow hidden;
        .selectItem
          line-height 40px;
          font-size 14px;
          color #FFFFFF;
          .selectItemChild
            width 100%;
            box-sizing border-box;
            padding-left 20px;
            cursor pointer;

.getFlexd
    position flxed !import
    top 0px
    background: #30318b;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    margin-bottom: -1px;

</style>